import React from "react";
import PropTypes, { InferProps } from "prop-types";
import "./Styles.css";
import { Page } from "./ReportingPage";

export function ReportingCoverPage(props: InferProps<typeof ReportingCoverPage.propTypes>) {
    return <Page>
        <div className="lp21-reporting-coverpage">
            <div className="lp21-title">{props.title}</div>
            <div className="lp21-subtitle">{props.subtitle}
                {props.subtitle2 && <span><br />{props.subtitle2}</span>}
            </div>
            <div className="lp21-date">{props.date}</div>
            <div className="lp21-author">{props.author}</div>
            <div className="lp21-footer"><>{props.children}</></div>
        </div>
    </Page>
}

ReportingCoverPage.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    subtitle2: PropTypes.string,
    date: PropTypes.string,
    author: PropTypes.string,
    children: PropTypes.node,
};

export default ReportingCoverPage;