/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useGlobalState } from "../../../app/GlobalStateProvider";
import { useTheme } from "../core";

export function Footer() {

  const { classes } = useTheme();

  // Global State
  const { globalState, } = useGlobalState();

  return (
    <div className={`footer py-4 d-flex flex-lg-column`} id="kt_footer">
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-2">
            {new Date().getFullYear()} &copy;
          </span>
          <a href="#" className="text-gray-800 text-hover-primary">
            {globalState.copyrightPart}
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          {globalState.environment && globalState.environment !== "PROD" && <li className="menu-item">
            <span className="text-muted fw-bold me-2">{globalState.environment}</span>
          </li>}
          <li className="menu-item">
            <span className="text-muted fw-bold me-2">{globalState.version}</span>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  );
}
