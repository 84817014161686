import { request } from "../app/modules/client";
import React, { useEffect, useState } from "react";
import "./LoadingIndicator.css";
import PropTypes, { InferProps } from "prop-types";
import AssessmentSummary from "./AssessmentSummary";
import { useGlobalState } from "../app/GlobalStateProvider";

export function AssessmentSummaryLoader(props: InferProps<typeof AssessmentSummaryLoader.propTypes>) {

    // Global State
    const { globalState } = useGlobalState();

    // State
    const [series, setSeries] = useState<any>([]);
    const [labels, setLabels] = useState<any>([]);

    // Mount
    useEffect(() => {

        if (!props.assessmentId) {
            // Do not load assessment summary, if unknown assessment id
            return;
        }

        fetchSummaryApi(globalState.apiBaseUrl)
            .then((result: any) => {
                var summary = result[0];

                if (summary) {

                    setSeries([
                        summary.percentage_Zyklus1,
                        summary.percentage_Zyklus2,
                        summary.percentage_Zyklus3
                    ]);
                } else {
                    setSeries([]);
                }

                setLabels(['1. Zyklus', '2. Zyklus', '3. Zyklus']);

            })
            .catch((ex: any) => {
                alert(`Error: ${ex})`);
            });
    }, [props.reloadSummary]);


    const fetchSummaryApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/assessment/${props.assessmentId}/summary?exact=true&fachbereichId=${props.fachbereichId ?? ""}&fachId=${props.fachId ?? ""}&kompetenzbereichId=${props.kompetenzbereichId ?? ""}&handlungsaspektId=${props.handlungsaspektId ?? ""}&kompetenzId=${props.kompetenzId ?? ""}`, globalState.authToken);
    }

    return <AssessmentSummary series={series} labels={labels} height={props.height} renderLegend={props.renderLegend ?? false} />;
}



AssessmentSummaryLoader.propTypes = {
    assessmentId: PropTypes.string.isRequired,
    fachbereichId: PropTypes.string,
    fachId: PropTypes.string,
    kompetenzbereichId: PropTypes.string,
    handlungsaspektId: PropTypes.string,
    kompetenzId: PropTypes.string,
    reloadSummary: PropTypes.any,
    height: PropTypes.number,
    renderLegend: PropTypes.bool
};

export default AssessmentSummaryLoader;