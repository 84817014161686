import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import AddLinkButton from "../../../../components/AddLinkButton";
import EditAndDeleteLinkButtons from "../../../../components/EditAndDeleteLinkButtons";
import PropTypes, { InferProps } from "prop-types";

export function ChildrenPage(props: InferProps<typeof ChildrenPage.propTypes>) {

  // Hooks
  const navigate = useNavigate();

  // Global State
  const { globalState, } = useGlobalState();

  // State
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);

  // Mount
  useEffect(() => {
    fetchItems(showDeleted);
  }, []);

  // Event handlers
  const onAddClickHandler = function () {
    navigate("/app/settings/children/new");
  }

  const onEditClickHandler = function (id: string) {
    navigate(`/app/settings/children/${id}`);
  }

  const onDeleteClickHandler = function (id: string, name: string) {

    if (!window.confirm(`Möchten Sie den Eintrag '${name}' wirklich löschen?`)) {
      return;
    }

    deleteItemApi(globalState.apiBaseUrl, id)
      .then(() => {
        fetchItems(showDeleted);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
      })
  };

  // Functions
  const fetchItems = function (includeDeleted: boolean) {
    fetchItemsApi(globalState.apiBaseUrl, includeDeleted)
      .then((result: any) => {
        setItems(result);
        applyFilter(result, filterText);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
      });
  };

  const applyFilter = function (loadedItems: any, text: any) {
    if (!text || text.length === 0) {
      setFilteredItems(loadedItems);
      return;
    }

    const searchText = text.toLowerCase();

    const res = loadedItems.filter((i: any) => {
      if (i.lastname && i.lastname.toLowerCase().indexOf(searchText) !== -1) {
        return true;
      } else { return false; }
    });

    setFilteredItems(res);
  };

  // Web api calls
  const fetchItemsApi = async function (apiBaseUrl: string | undefined, includeDeleted: boolean) {
    return request(`${apiBaseUrl}/child?familyId=${globalState.familyId}&includeDeleted=${includeDeleted}`, globalState.authToken);
  }
  const deleteItemApi = async function (apiBaseUrl: string | undefined, id: string) {
    const options = { method: "DELETE" };
    return request(`${apiBaseUrl}/child/${id}`, globalState.authToken, options);
  }

  return (
    <div className="card">
      <table className="table table-rounded table-striped border gy-7 gs-7">
        <thead>
          <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
            <th className="lp21-command"><AddLinkButton isReadOnly={props.isReadOnly} onAddClick={onAddClickHandler} /></th>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>Geburtstag</th>
            <th>Schuleintritt</th>
            <th>Betreuung als externe Lehrperson</th>
          </tr>
        </thead>
        <tbody>
          {items && items.map((item: { id: string, firstName: string, lastName: string, birthday: Date, schoolEntryOn: Date, isExternal: boolean, deleted: boolean }) => <tr key={item.id}>
            <td className="lp21-command"><EditAndDeleteLinkButtons id={item.id} name={item.lastName} isDeleted={item.deleted} isReadOnly={props.isReadOnly} onEditClick={onEditClickHandler} onDeleteClick={onDeleteClickHandler} /></td>
            <td>{item.firstName}</td>
            <td>{item.lastName}</td>
            <td>{new Date(item.birthday).toLocaleDateString("de-CH")}</td>
            <td>{new Date(item.schoolEntryOn).toLocaleDateString("de-CH", { year: 'numeric', month: 'long' })}</td>
            <td>{item.isExternal ? "X" : ""}</td>
          </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

ChildrenPage.propTypes = {
    isReadOnly: PropTypes.bool.isRequired
};

export default ChildrenPage;