import React, { useEffect, useRef, useState } from "react";
import "./LoadingIndicator.css";
import PropTypes, { InferProps } from "prop-types";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSS } from "../_start/assets/ts/_utils";

import "./AssessmentSummary.css";

export function AssessmentSummary(props: InferProps<typeof AssessmentSummary.propTypes>) {


    // State
    const [dataUri, setDataUri] = useState("");

    // Elements
    const chartRef = useRef<HTMLDivElement | null>(null);

    var colors: string[] = [];

    // 1. Zyklus
    if (props.series && props.series.length > 0 && props.series[0] > 0) {
        colors.push('#E39B2D');
    } else {
        colors.push('#EEEEEE');
    }

    // 2. Zyklus
    if (props.series && props.series.length > 1 && props.series[1] > 0) {
        colors.push('#2F8FCE');
    } else {
        colors.push('#EEEEEE');
    }

    // 3. Zyklus
    if (props.series && props.series.length > 2 && props.series[2] > 0) {
        colors.push('#97C941');
    } else {
        colors.push('#EEEEEE');
    }

    const chartOptions = (height: string | number | undefined): ApexOptions => {
        return {
            chart: {
                height: props.height ?? 200,
                type: "radialBar",
                animations: {
                    enabled: (!props.renderAsImage ? false : false)
                }
            },
            colors: colors,
            series: props.series,
            plotOptions: {
                radialBar: {
                    inverseOrder: true,
                    startAngle: -180,
                    endAngle: 180,
                    track:
                    {
                        show: true,
                        background: '#EEEEEE',
                    },

                    dataLabels: {
                        total: {
                            show: false,
                            label: 'TOTAL'
                        }
                    }
                }
            },
            labels: props.labels
        };
    };

    useEffect(() => {
        if (!chartRef.current) {
            return;
        }

        if (props.renderAsImage && dataUri && dataUri !== "") {
            // Do not re-render
            return;
        }

        const height1 = parseInt(getCSS(chartRef.current, "height"));
        const chart1 = new ApexCharts(chartRef.current, chartOptions(height1));
        if (chart1) {
            chart1.render();
            if (props.renderAsImage) {
                chart1.dataURI().then((value: any) => {
                    setDataUri(value.imgURI);
                });
            }
        }

        return () => {
            if (chart1) {
                chart1.destroy();
            }
        };
    }, [chartRef, props.series, props.labels]);

    // Hide chart?
    var className = "";
    if (props.renderAsImage && dataUri && dataUri !== "") {
        className = "d-none";
    }

    return <div className="lp21-assessmentsummary">
        <div ref={chartRef}
            id="kt_mixed_widget_1_chart"
            className={`lp21-chart ${className}`}
            style={{ height: "150px" }}
        ></div>

        {props.renderAsImage &&
            <img className="lp21-chart-image" src={dataUri} alt="Lernstand" />
        }

        {props.renderLegend &&
            <>
                <div className="lp21-legend">
                    {props.series && props.series.length > 0 && <div className="lp21-label">Grundanspruch</div>}
                    {props.series && props.series.length > 0 && <div className="lp21-percentage" style={{ color: colors[0] }}>{`${props.series[0]}%`}</div>}
                    {props.series && props.series.length > 1 && <div className="lp21-percentage" style={{ color: colors[1] }}>{`${props.series[1]}%`}</div>}
                    {props.series && props.series.length > 2 && <div className="lp21-percentage" style={{ color: colors[2] }}>{`${props.series[2]}%`}</div>}
                </div>
                <div className="lp21-legend">
                    {props.series2 && props.series2.length > 0 && <div className="lp21-label">Alle Kompetenzstufen</div>}
                    {props.series2 && props.series2.length > 0 && <div className="lp21-percentage" style={{ color: colors[0] }}>{`${props.series2[0]}%`}</div>}
                    {props.series2 && props.series2.length > 1 && <div className="lp21-percentage" style={{ color: colors[1] }}>{`${props.series2[1]}%`}</div>}
                    {props.series2 && props.series2.length > 2 && <div className="lp21-percentage" style={{ color: colors[2] }}>{`${props.series2[2]}%`}</div>}
                </div>
            </>
        }
    </div>;
}

AssessmentSummary.propTypes = {
    series: PropTypes.array.isRequired,
    series2: PropTypes.array,
    labels: PropTypes.array.isRequired,
    height: PropTypes.number,
    renderAsImage: PropTypes.bool,
    renderLegend: PropTypes.bool,
};

export default AssessmentSummary;