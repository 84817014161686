import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { login } from "../redux/AuthCRUD";
import { useGlobalState } from "../../../GlobalStateProvider";
import { GlobalStateInterface } from "../../../GlobalStateInterface";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Ungültige E-Mail-Adresse")
    .min(3, "Mindestens 3 Zeichen")
    .max(50, "Maximal 50 Zeichen")
    .required("E-Mail-Adresse ist erforderlich"),
  password: Yup.string()
    .min(3, "Mindestens 3 Zeichen")
    .max(50, "Maximal 50 Zeichen")
    .required("Passwort ist erforderlich"),
});

const initialValues = {
  email: "",
  password: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {

  // Hooks
  const navigate = useNavigate();

  // Global State
  const { globalState, setGlobalState } = useGlobalState();

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        login(globalState.apiBaseUrl ?? "", values.email, values.password)
          .then((result: any) => {
            setLoading(false);

            // Apply config
            const config: Partial<GlobalStateInterface> = {
              authToken: result.token,
              familyId: result.familyId,
              userId: result.userId,
              defaultLehrplanId: result.defaultLehrplanId,
              isCoach: result.isCoach,
              isAdmin: result.isAdmin,
              username: result.username,
              version: result.version,
              environment: result.environment,
              copyrightPart: result.copyrightPart,
            };
            setGlobalState((prev) => ({ ...prev, ...config }));
            navigate(`/app/dashboard`);
          })
          .catch((ex: any) => {
            setLoading(false);
            setSubmitting(false);
            if (ex && ex.response && ex.response.data && ex.response.data.message) {
              setStatus(ex.response.data.message);
            }
            else {
              setStatus("Das Login ist fehlgeschlagen:" + ex.name);
            }
          });
      }, 1000);
    },
  });

  return (
    <div className="mw-450px">
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        {/* begin::Title */}
        <div className="pb-lg-15">
          <h3 className="fw-bolder text-dark display-6 text-center">Willkommen am Start</h3>
          <div className="text-muted text-center fw-bold fs-3">
            Neu hier?{" "} <span data-tooltip-id="tooltip-global-light" data-tooltip-html="<div style='max-width: 200px;'>Mit der Registration meldest du dich kostenlos für eine 14-tägige Testlizenz an. In den kommenden Tagen stellen wir dir per E-Mail eine Rechnung für eine Jahreslizenz zum Preis von Fr. 120.-/Jahr zu. Mit der Zahlung der Rechnung wird deine Testlizenz automatisch in eine Jahreslizenz umgewandelt.</div>">
              <Link to="/auth/registration">
                <span
                  className="text-primary fw-bolder"
                  id="kt_login_signin_form_singup_button"
                >
                  Registrieren und 14 Tage testen
                </span>
                <i className="bi bi-info-circle-fill ps-2 fs-3 text-primary"></i></Link>
            </span>
          </div>
        </div>
        {/* begin::Title */}

        {formik.status &&
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        }

        {/* begin::Form group */}
        <div className="v-row mb-10 fv-plugins-icon-container">
          <label className="form-label fs-6 fw-bolder text-dark">E-Mail</label>
          <input placeholder="E-Mail" {...formik.getFieldProps("email")} className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
            type="email"
            name="email"
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-10 fv-plugins-icon-container">
          <div className="d-flex justify-content-between mt-n5">
            <label className="form-label fs-6 fw-bolder text-dark pt-5">
              Passwort
            </label>

            <Link
              to="/auth/forgot-password"
              className="text-primary fs-6 fw-bolder text-hover-primary pt-5"
              id="kt_login_signin_form_password_reset_button"
            >
              Passwort vergessen?
            </Link>
          </div>
          <input
            type="password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className="pb-lg-0 pb-5">
          <button
            type="submit"
            id="kt_login_signin_form_submit_button"
            className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className="indicator-label">Anmelden</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Bitte warten...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
    </div>
  );
}
