/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function JobsPage() {
  const location = useLocation();
  // We need this class for corrent printed version of invoice
  document.body.classList.add("print-content-only");
  useEffect(() => {
    return () => {
      // After component unmount we should remove this class
      document.body.classList.remove("print-content-only");
    };
  }, [location]);

  return (
    <div className="card">
      <div className="card-body p-0">
        <a href="/jobs" target="_blank">Hangfire öffnen</a>
      </div>
    </div>
  );
}
