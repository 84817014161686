import React, { useEffect } from "react";
import PropTypes, { InferProps } from "prop-types";
import UnboundFormControlWithLabel from "../../../../components/UnboundControlWithLabel";
import AssessmentSummaryLoader from "../../../../components/AssessmentSummaryLoader";
import AssessmentSchemaLevelSelect from "./AssessmentSchemaLevelSelect";
import AssessmentImageManager from "../../../../components/AssessmentImageManager";
import AssessmentItemPlanning from "./AssessmentItemPlanning";

export function AssessmentItemCustomColumn(props: InferProps<typeof AssessmentItemCustomColumn.propTypes>) {

  // Mount
  useEffect(() => {

  }, []);

  var className = `lp21-customColumn-${props.level}`;

  if (props.level === "fachbereich") {
    return <td className={className}>
      <div className="row">
        <div className="col-4"><AssessmentSummaryLoader assessmentId={props.assessmentId} fachbereichId={props.item.fachbereichId} reloadSummary={props.reloadSummary} /></div>
        <UnboundFormControlWithLabel className="col-8" controlId="txtRemarks" controlType="html" htmlEditorInit={{ height: 150, block_formats: "Absatz=p", hideFormatSelect: true }} label={`Bemerkungen zum Fachbereich ${props.item.fachbereichName}`} rows={5} value={props.remark?.assessmentRemarks} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem(props.level, props.rowId, props.item, null, value)} />
        <div className="col-4"></div><div className="col-8"><AssessmentImageManager imageBaseUrl={props.imageBaseUrl} imagePostUrl={props.imagePostUrl} rowId={props.rowId} images={props.images} isReadOnly={props.isReadOnly} /></div>
        {props.remark && <AssessmentItemPlanning className={className} planSort={props.remark.planSort} planRemarks={props.remark.planRemarks} />}
      </div></td>;
  }

  else if (props.level === "fach") {
    return <td className={className}>
      <div className="row">
        <div className="col-4"><AssessmentSummaryLoader assessmentId={props.assessmentId} fachbereichId={props.item.fachbereichId} fachId={props.item.fachId} reloadSummary={props.reloadSummary} /></div>
        <UnboundFormControlWithLabel className="col-8" controlId="txtRemarks" controlType="html" htmlEditorInit={{ height: 150, block_formats: "Absatz=p", hideFormatSelect: true }} label={`Bemerkungen zum Fach ${props.item.fachName}`} rows={5} value={props.remark?.assessmentRemarks} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem(props.level, props.rowId, props.item, null, value)} />
        <div className="col-4"></div><div className="col-8"><AssessmentImageManager imageBaseUrl={props.imageBaseUrl} imagePostUrl={props.imagePostUrl} rowId={props.rowId} images={props.images} isReadOnly={props.isReadOnly} /></div>
        {props.remark && <AssessmentItemPlanning className={className} planSort={props.remark.planSort} planRemarks={props.remark.planRemarks} />}
      </div></td>;
  }

  else if (props.level === "kompetenzbereich") {
    return <td className={className}>
      <div className="row">
        <div className="col-4"><AssessmentSummaryLoader assessmentId={props.assessmentId} fachbereichId={props.item.fachbereichId} fachId={props.item.fachId} kompetenzbereichId={props.item.kompetenzbereichId} reloadSummary={props.reloadSummary} /></div>
        <UnboundFormControlWithLabel className="col-8" controlId="txtRemarks" controlType="html" htmlEditorInit={{ height: 150, block_formats: "Absatz=p", hideFormatSelect: true }} label={`Bemerkungen zur Kompetenzbereich ${props.item.kompetenzbereichCode}`} rows={5} value={props.remark?.assessmentRemarks} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem(props.level, props.rowId, props.item, null, value)} />
        <div className="col-4"></div><div className="col-8"><AssessmentImageManager imageBaseUrl={props.imageBaseUrl} imagePostUrl={props.imagePostUrl} rowId={props.rowId} images={props.images} isReadOnly={props.isReadOnly} /></div>
        {props.remark && <AssessmentItemPlanning className={className} planSort={props.remark.planSort} planRemarks={props.remark.planRemarks} />}
      </div></td>;
  }

  else if (props.level === "handlungsaspekt") {
    return <td className={className}>
      {props.item.handlungsaspektCode && <div className="row">
        <div className="col-4">{/* <AssessmentSummaryLoader assessmentId={props.assessmentId} fachbereichId={props.item.fachbereichId} fachId={props.item.fachId} kompetenzbereichId={props.item.kompetenzbereichId} handlungsaspektId={props.item.handlungsaspektId} reloadSummary={props.reloadSummary} /> */}</div>
        <UnboundFormControlWithLabel className="col-8" controlId="txtRemarks" controlType="textarea" label={`Bemerkungen zum Handlungsaspekt ${props.item.handlungsaspektCode}`} value={props.remark?.assessmentRemarks} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem(props.level, props.rowId, props.item, null, value)} />
        {props.remark && <AssessmentItemPlanning className={className} planSort={props.remark.planSort} planRemarks={props.remark.planRemarks} />}
      </div>}</td>;
  }

  else if (props.level === "kompetenz") {
    return <td className={className}>
      <div className="row">
        <div className="col-4">{/* <AssessmentSummaryLoader assessmentId={props.assessmentId} fachbereichId={props.item.fachbereichId} fachId={props.item.fachId} kompetenzbereichId={props.item.kompetenzbereichId} handlungsaspektId={props.item.handlungsaspektId} kompetenzId={props.item.kompetenzId} reloadSummary={props.reloadSummary} /> */}</div>
        <UnboundFormControlWithLabel className="col-8" controlId="txtRemarks" controlType="textarea" label={`Bemerkungen zur Kompetenz ${props.item.kompetenzCode}`} value={props.remark?.assessmentRemarks} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem(props.level, props.rowId, props.item, null, value)} />
        <div className="col-4"></div><div className="col-8"><AssessmentImageManager imageBaseUrl={props.imageBaseUrl} imagePostUrl={props.imagePostUrl} rowId={props.rowId} images={props.images} isReadOnly={props.isReadOnly} isSmall={true} /></div>
        {props.remark && <AssessmentItemPlanning className={className} planSort={props.remark.planSort} planRemarks={props.remark.planRemarks} />}
      </div>

      {/* Header für Kompetenzstufe 
        <div className="row">
          <div className="col-4"><label className="form-label">Lernstand</label></div>
          <div className="col-8"><label className="form-label">Bemerkungen zur Kompetenzstufe</label></div>
        </div>*/}
    </td>;
  }

  else if (props.level === "kompetenzstufe") {
    return <td className={className}>
      <div className="row">
        <div className="col-4">
          <AssessmentSchemaLevelSelect levels={props.assessmentSchemaLevels} value={props.item.assessmentSchemaLevelId} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem("aufzaehlungspunkt", props.item.id, props.item, value, null)} />
        </div>
        <UnboundFormControlWithLabel className="col-8" controlId="txtRemarks" controlType="textarea" label="" value={props.item.assessmentRemarks} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem("aufzaehlungspunkt", props.item.id, props.item, null, value)} />
        {props.item && <AssessmentItemPlanning className={className} planSort={props.item.planSort} planRemarks={props.item.planRemarks} />}
      </div>
    </td>;
  }
  else {
    return <td className={className}>?</td>;
  }
};

AssessmentItemCustomColumn.propTypes = {
  assessmentSchemaLevels: PropTypes.any,
  rowId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  item: PropTypes.any,
  remark: PropTypes.any,
  images: PropTypes.any,
  reloadSummary: PropTypes.any,

  isReadOnly: PropTypes.bool.isRequired,
  onSaveItem: PropTypes.func.isRequired,

  imageBaseUrl: PropTypes.string.isRequired,
  imagePostUrl: PropTypes.string.isRequired,

};

export default AssessmentItemCustomColumn;

