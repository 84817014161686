import React, { useEffect } from "react";
import PropTypes, { InferProps } from "prop-types";
import AssessmentSummaryLoader from "../../../../components/AssessmentSummaryLoader";
import UnboundFormControlWithLabel from "../../../../components/UnboundControlWithLabel";

export function PlanItemCustomRow(props: InferProps<typeof PlanItemCustomRow.propTypes>) {

  // Mount
  useEffect(() => {

  }, []);

  var className = `lp21-customRow-${props.level}`;

  const renderRow = function () {

    if (props.level === "fachbereich") {
      return <div className="row">
        <UnboundFormControlWithLabel className="col-8" controlId="txtRemarks" controlType="html" htmlEditorInit={{ height: 150, block_formats: "Absatz=p", hideFormatSelect: true }} label={`Bemerkungen zum Fachbereich ${props.item.fachbereichName}`} value={props.remark?.planRemarks} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem(props.level, props.rowId, props.item, null, null, value)} />
        <div className="col-4"><AssessmentSummaryLoader assessmentId={props.linkedAssessmentId ?? ""} fachbereichId={props.item.fachbereichId} reloadSummary={props.reloadSummary} renderLegend={true} /></div>

        {props.remark && props.remark.assessmentRemarks &&
          <div className={`${className}-assessment`}>
            <label className="form-label">Lernstand</label>
            <div dangerouslySetInnerHTML={{ __html:props.remark.assessmentRemarks }}></div>
          </div>
        }
      </div>;
    }

    else if (props.level === "fach") {
      return <div className="row">
        <UnboundFormControlWithLabel className="col-8" controlId="txtRemarks" controlType="html" htmlEditorInit={{ height: 150, block_formats: "Absatz=p", hideFormatSelect: true }} label={`Bemerkungen zum Fach ${props.item.fachName}`} value={props.remark?.planRemarks} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem(props.level, props.rowId, props.item, null, null, value)} />
        <div className="col-4"><AssessmentSummaryLoader assessmentId={props.linkedAssessmentId ?? ""} fachbereichId={props.item.fachbereichId} fachId={props.item.fachId} reloadSummary={props.reloadSummary} renderLegend={true} /></div>

        {props.remark && props.remark.assessmentRemarks &&
          <div className={`${className}-assessment`}>
            <label className="form-label">Lernstand</label>
            <div dangerouslySetInnerHTML={{ __html:props.remark.assessmentRemarks }}></div>
          </div>
        }
      </div>;
    }

    else if (props.level === "kompetenzbereich") {
      return <div className="row">
        <UnboundFormControlWithLabel className="col-8" controlId="txtRemarks" controlType="html" htmlEditorInit={{ height: 150, block_formats: "Absatz=p", hideFormatSelect: true }} label={`Bemerkungen zur Kompetenzbereich ${props.item.kompetenzbereichCode}`} value={props.remark?.planRemarks} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem(props.level, props.rowId, props.item, null, null, value)} />
        <div className="col-4"><AssessmentSummaryLoader assessmentId={props.linkedAssessmentId ?? ""} fachbereichId={props.item.fachbereichId} fachId={props.item.fachId} kompetenzbereichId={props.item.kompetenzbereichId} reloadSummary={props.reloadSummary} renderLegend={true} /></div>

        {props.remark && props.remark.assessmentRemarks &&
          <div className={`${className}-assessment`}>
            <label className="form-label">Lernstand</label>
            <div dangerouslySetInnerHTML={{ __html:props.remark.assessmentRemarks }}></div>
          </div>
        }
      </div>;
    }

    else if (props.level === "handlungsaspekt_OLD" && props.item.handlungsaspektCode) {
      return <div className="row">
        <UnboundFormControlWithLabel className="col-8" controlId="txtRemarks" controlType="html" htmlEditorInit={{ height: 150, block_formats: "Absatz=p", hideFormatSelect: true }} label={`Bemerkungen zum Handlungsaspekt ${props.item.handlungsaspektCode}`} value={props.remark?.planRemarks} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem(props.level, props.rowId, props.item, null, null, value)} />

        {props.remark && props.remark.assessmentRemarks &&
          <div className={`${className}-assessment`}>
            <label className="form-label">Lernstand</label>
            <p>{props.remark.planRemarks}</p>
          </div>
        }
      </div>
        ;
    }

    else if (props.level === "kompetenz_OLD") {
      return <div className="row">
        <UnboundFormControlWithLabel className="col-8" controlId="txtRemarks" controlType="html" htmlEditorInit={{ height: 150, block_formats: "Absatz=p", hideFormatSelect: true }} label={`Bemerkungen zur Kompetenz ${props.item.kompetenzCode}`} value={props.remark?.planRemarks} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem(props.level, props.rowId, props.item, null, null, value)} />

        {props.remark && props.remark.assessmentRemarks &&
          <div className={`${className}-assessment`}>
            <label className="form-label">Lernstand</label>
            <p>{props.remark.planRemarks}</p>
          </div>
        }
      </div>
        ;
    }
  }

  if (props.level === "fachbereich"
    || props.level === "fach"
    || props.level === "kompetenzbereich"
    || props.level === "handlungsaspekt"
    || props.level === "kompetenz") {

    return <tr className={className}>
      <td colSpan={3}></td>
      <td colSpan={2}>{renderRow()}</td>
    </tr>;
  }
  else {
    return <></>;
  }
};

PlanItemCustomRow.propTypes = {
  rowId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  linkedAssessmentId: PropTypes.string,
  level: PropTypes.string.isRequired,
  item: PropTypes.any,
  remark: PropTypes.any,
  reloadSummary: PropTypes.any,

  isReadOnly: PropTypes.bool.isRequired,
  onSaveItem: PropTypes.func.isRequired,
};

export default PlanItemCustomRow;