import React, { Fragment } from "react";
import { InferProps } from "prop-types";
import { ReportingDocument } from "../../../../components/reporting/ReportingDocument";
import ReportingCoverPage from "../../../../components/reporting/ReportingCoverPage";
import ReportingPage from "../../../../components/reporting/ReportingPage";
import Lehrplan21TableBasic from "../../browser/pages/Lehrplan21TableBasic";
import "./PlanReport.css";
import PlanReport from "./PlanReport";

export function PlanReportAlternate(props: InferProps<typeof PlanReport.propTypes>) {

  function onRenderCustomColumns(item: any, level: string, rowId: string): JSX.Element | null {

    // No content?
    if (!item.planRemarks && !item.planSort) {
      return null;
    }
    
    return <>
      <td>{item.planRemarks && <div dangerouslySetInnerHTML={{ __html: item.planRemarks }}></div>}</td>
      <td>{item.planSort && <div>{item.planSort}</div>}</td>
    </>
  }

  function onRenderCustomRow(item: any, level: string, rowId: string): JSX.Element {

    // Lookup remark
    var remark: any = findRemark(level, rowId);
    return <tr>
      <td></td>
      <td colSpan={2}>
        {remark?.planRemarks && <div dangerouslySetInnerHTML={{ __html: remark?.planRemarks }}></div>}
      </td>
    </tr>;
  }
  const findRemark = function (level: string, id: string): any {
    if (!props.data?.remarks || props.data?.remarks.length === 0)
      return null;

    return props.data?.remarks.find((r: any) => r.level === level && r.id === id);
  }

  const items = props.data?.items;

  return <Fragment>
    <ReportingDocument>

      <ReportingCoverPage title={props.data?.reportTitle} subtitle={props.data?.reportSubtitle} subtitle2={props.filterName} author={props.data?.reportAuthor} date={new Date(props.data?.date).toLocaleDateString("de-CH", { day: "numeric", month: "long", year: "numeric" })}>
        <div className="lp21-reporting-richtext" dangerouslySetInnerHTML={{ __html: props.data?.reportFooter }}></div>
      </ReportingCoverPage>
      <ReportingPage>
        <Lehrplan21TableBasic items={items} levelUntil="" onRenderCustomColumns={onRenderCustomColumns} onRenderCustomRow={onRenderCustomRow} skipLevels={[]} skipRenderingCustomColumnForLevels={["fachbereich", "fach", "kompetenzbereich", "handlungsaspekt", "kompetenz"]} />
      </ReportingPage>

    </ReportingDocument >

  </Fragment >;
}

export default PlanReportAlternate;