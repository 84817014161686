import React, { Fragment } from "react";
import PropTypes, { InferProps } from "prop-types";
import "./Lehrplan21NavigationPane.css";

export function Lehrplan21NavigationPane(props: InferProps<typeof Lehrplan21NavigationPane.propTypes>) {

    var prevItem: any = null;

    return <>
        {props.items && props.items.length > 0 &&
            <div id="lp21_navigation_toggle" className="lp21-navigation card card-custom shadow">
                <div id="lp21_navigation_header" className="card-header collapsed" data-bs-toggle="collapse" data-bs-target="#lp21_navigation" aria-expanded="false" aria-controls="lp21_navigation">

                    <div className="card-toolbar">
                        <i className="bi bi-chevron-double-left"></i>
                        <i className="bi bi-text-left fs-2"></i>
                    </div>
                    <h3 className="card-title"><span>Navigation</span></h3>
                </div>
                <div id="lp21_navigation" className="card-body collapse" >
                    <div className="card-scroll">
                        <div id="lp21_navigation_content">
                            {props.items && props.items.map((item: any) => {
                                var renderedItem = renderItem(props.levelUntil, item, prevItem);
                                prevItem = item;
                                return renderedItem;
                            })}
                        </div>
                    </div>
                    {props.hasMoreItems && <div id="lp21_navigation_more">
                        <div className="row">
                            <a className="offset-2 col-10" title={`Nächste ${props.pageSize} Einträge laden`} onClick={props.onLoadNext}>...</a><br />
                        </div>
                        {props.showLoadAll && <div className="row lp21-navigation-loadall">
                            <a className="col-12" title="ganzer Fachbereich laden" onClick={props.onLoadAll}><i className="bi bi-chevron-double-down pt-2 me-2 text-primary"></i><span className="pb-2"> ganzer Fachbereich</span></a>
                        </div>
                        }
                    </div>}
                </div>
            </div>}
    </>;
};


function renderItem(levelUntil: string | null | undefined, item: any, prevItem: any): JSX.Element {

    // Limit displayed levels
    var showLevels = ["fach", "kompetenzbereich"];
    if (levelUntil && levelUntil !== "") {
        var levelUntilIndex = showLevels.indexOf(levelUntil);
        showLevels = showLevels.slice(0, levelUntilIndex + 1);
    }

    var fachSameAsPrev = (item.fachbereichName === prevItem?.fachbereichName && item.fachName === prevItem?.fachName);
    var kompetenzbereichSameAsPrev = (item.kompetenzbereichName === prevItem?.kompetenzbereichName);

    return <Fragment key={`nav_${item.id}`}>
        {showLevels.includes("fach") && !fachSameAsPrev && renderRow("fach", item.fachCode ?? item.fachbereichCode, item.fachName ?? item.fachbereichName)}
        {showLevels.includes("kompetenzbereich") && !kompetenzbereichSameAsPrev && renderRow("kompetenzbereich", item.kompetenzbereichCode, item.kompetenzbereichName)}
    </Fragment>;
}

function renderRow(level: string, code: string, name: string): JSX.Element {
    return <div className={`lp21-` + level} key={code}>
        <a href={"#" + code}><small>{code}</small>{name}</a>
    </div>;
}

Lehrplan21NavigationPane.propTypes = {
    items: PropTypes.array.isRequired,
    levelUntil: PropTypes.string,
    pageSize: PropTypes.number.isRequired,
    hasMoreItems: PropTypes.bool.isRequired,
    onLoadNext: PropTypes.func.isRequired,
    showLoadAll: PropTypes.bool.isRequired,
    onLoadAll: PropTypes.func.isRequired,
};

export default Lehrplan21NavigationPane;