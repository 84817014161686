import PropTypes, { InferProps } from "prop-types";
import React from "react";
import "./SortButton.css";

export function SortButton(props: InferProps<typeof SortButton.propTypes>) {

    // Event handlers
    const onSortHandler = () => {
        if (props.onSort) {

            let sortAscending = props.initialSortAscending ?? true;
            if (props.sortKey === props.activeSortKey) {
                sortAscending = !props.activeSortAscending;
            }

            props.onSort(props.sortKey, sortAscending);
        }
    };

    let sortAscending = props.initialSortAscending ?? true;
    if (props.sortKey === props.activeSortKey) {
        sortAscending = props.activeSortAscending ?? true;
    }

    let className = "lp21-sortbutton";
    let iconName = `caret-${sortAscending ? "up" : "down"}`;
    if (props.sortKey === props.activeSortKey) {
        className += " lp21-sortbutton-active";
        iconName += "-fill";
    }

    return <a className={className} onClick={onSortHandler}>
        <i className={`bi bi-${iconName}`}></i>
    </a>;
}

SortButton.propTypes = {
    sortKey: PropTypes.string.isRequired,
    initialSortAscending: PropTypes.bool,

    activeSortKey: PropTypes.string.isRequired,
    activeSortAscending: PropTypes.bool.isRequired,

    // Events
    onSort: PropTypes.func.isRequired,
};

export default SortButton;