import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "../_start/layout/core";
import PropTypes, { InferProps } from "prop-types";
import { GlobalStateProvider } from "./GlobalStateProvider";
import GlobalStateLoader from "./GlobalStateLoader";
import "./App.css"
import { AppInner } from "./AppInner";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import 'bootstrap/dist/js/bootstrap.bundle';

function App(props: InferProps<typeof App.propTypes>) {

  return (
    <GlobalStateProvider>
      <GlobalStateLoader />
      <BrowserRouter basename={props.basename}>
        <ThemeProvider>
          <AppInner />
          <Tooltip id="tooltip-global" style={{ fontSize: "1.1em" }} />
          <Tooltip id="tooltip-global-light" variant="light" style={{ backgroundColor: "#EAF4FA", fontSize: "1.1em" }} />
        </ThemeProvider>
      </BrowserRouter>
    </GlobalStateProvider>
  );
};

App.propTypes = {
  basename: PropTypes.string.isRequired
};

export default App;