import axios from "axios";
import React, { Fragment, useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import { useGlobalState } from "../app/GlobalStateProvider";
import "./FileUpload.css";

export function FileUpload(props: InferProps<typeof FileUpload.propTypes>) {

    // Global State
    const { globalState, } = useGlobalState();

    // State
    const [file, setFile] = useState<any>();
    const [fileName, setFileName] = useState<any>();

    // Event handlers
    const onSaveFile = (e: any) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);

        onUploadFile(e.target.files[0], e.target.files[0].name);
    };

    const onUploadFile = async (file: any, fileName: any) => {
        console.log(file);
        const formData = new FormData();
        formData.append("formFile", file);
        formData.append("fileName", fileName);
        try {
            const res = await axios.post(props.postUrl, formData, {
                headers: { "Authorization": `Bearer ${globalState.authToken}` }
            });

            if (props.onUploaded) {
                props.onUploaded(res.data);
            }


            console.log(res);
        } catch (ex) {
            console.log(ex);
        }
    };

    return <div className="lp21-fileupload">
        <input type="file" id={`file-${props.id}`} onChange={onSaveFile} />
        <label htmlFor={`file-${props.id}`} className="btn btn-primary" title="Bild hochladen..."><i className={`bi bi-upload fs-fluid ${!props.isSmall ? "" : "lp21-small"}`} />{!props.isSmall ? "  Bild hochladen..." : ""}</label>
        {/*         <input type="button" value="upload" onClick={onUploadFile} />
 */}    </div>;
}

FileUpload.propTypes = {
    id: PropTypes.string.isRequired,
    postUrl: PropTypes.string.isRequired,
    onUploaded: PropTypes.func.isRequired,
    isSmall: PropTypes.bool.isRequired,
};

export default FileUpload;