import React from "react";
import PropTypes, { InferProps } from "prop-types";
import "./Styles.css";

export function ReportingDocument(props: InferProps<typeof ReportingDocument.propTypes>) {
    return <div className="lp21-reporting-document">
        <>
            {props.children}
        </>
    </div>
}

ReportingDocument.propTypes = {
    children: PropTypes.node,
};

export default ReportingDocument;