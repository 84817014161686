import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import { isGuid } from "../../helper";
import FormControlWithLabel from "../../../../components/FormControlWithLabel";

export function UserPage() {

    // Hooks
    const navigate = useNavigate();

    // Global State
    const { globalState } = useGlobalState();

    // State
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [emailChanged, setEmailChanged] = useState(false);
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [unlock, setUnlock] = useState(false);
    const [lockedUntil, setLockedUntil] = useState("");
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const [newPassword, setNewPassword] = useState("");

    // Route params
    const { id } = useParams<{ id: string }>();

    // Mount
    useEffect(() => {

        // Lookups

        // New item
        if (!isGuid(id)) {
            return;
        }

        fetchItemApi(globalState.apiBaseUrl)
            .then((result: any) => {
                setLastName(result.lastName);
                setFirstName(result.firstName);
                setEmail(result.email);
                setIsEmailConfirmed(result.isEmailConfirmed);
                setIsActive(result.isActive);
                setLockedUntil(result.lockedUntil);
            })
            .catch((ex: any) => {
                alert(`Error: ${ex})`);
            });


    }, []);

    // Event handlers
    const onSubmitHandler = function (e: any) {
        e.preventDefault();
        save()
            .then(() => {
                navigate("../users");
            })
            .catch((ex: any) => {
                if (ex && ex.response && ex.response.details) {
                    alert(`Error: ${ex})\n\n${ex.response.details}`);
                } else {
                    alert(`Error: ${ex})`);
                }
            });
    }

    const onCancelHandler = function () {
        navigate("../users");
    }

    const onSendVerificationEmail = function (event: any) {

        if (!window.confirm(`Möchten Sie wirklich ein E-Mail an '${email}' zur Verifikation der E-Mail-Adresse senden?`)) {
            return;
        }

        var buttonElement = event.currentTarget;
        buttonElement.disabled = true;
        buttonElement.className = "btn btn-success";
        buttonElement.innerHTML = "E-Mail gesendet";

        if (emailChanged) {
            // Save before verification email
            save()
                .then(() => {
                    // Send verification email
                    sendVerificationEmail();
                })
                .catch((ex: any) => {
                    if (ex && ex.response && ex.response.details) {
                        alert(`Error: ${ex})\n\n${ex.response.details}`);
                    } else {
                        alert(`Error: ${ex})`);
                    }
                });
        } else {
            // Send verification email
            sendVerificationEmail();
        }
    }

    const onSendPasswordResetEmail = function (event: any) {

        if (!window.confirm(`Möchten Sie wirklich ein E-Mail an '${email}' zum Ändern des Passworts senden?`)) {
            return;
        }

        var buttonElement = event.currentTarget;
        buttonElement.disabled = true;
        buttonElement.className = "btn btn-success";
        buttonElement.innerHTML = "E-Mail gesendet";

        if (emailChanged) {
            // Save before verification email
            save()
                .then(() => {
                    // Send password reset email
                    sendPasswordResetEmail();
                })
                .catch((ex: any) => {
                    if (ex && ex.response && ex.response.details) {
                        alert(`Error: ${ex})\n\n${ex.response.details}`);
                    } else {
                        alert(`Error: ${ex})`);
                    }
                });
        } else {
            // Send password reset email
            sendPasswordResetEmail();
        }
    }

    // Functions
    const save = async function () {
        return saveItemApi(globalState.apiBaseUrl, {
            familyId: globalState.familyId,
            lastName: lastName,
            firstName: firstName,
            email: email,
            isEmailConfirmed: isEmailConfirmed,
            isActive: isActive,
            unlock: unlock,
            newPassword: newPassword,
        });
    }

    const sendVerificationEmail = function () {
        sendVerificationEmailApi(globalState.apiBaseUrl)
            .then(() => {
            })
            .catch((ex: any) => {
                if (ex && ex.response && ex.response.details) {
                    alert(`Error: ${ex})\n\n${ex.response.details}`);
                } else {
                    alert(`Error: ${ex})`);
                }
            });
    }

    const sendPasswordResetEmail = function () {
        sendPasswordResetEmailApi(globalState.apiBaseUrl)
            .then(() => {
            })
            .catch((ex: any) => {
                if (ex && ex.response && ex.response.details) {
                    alert(`Error: ${ex})\n\n${ex.response.details}`);
                } else {
                    alert(`Error: ${ex})`);
                }
            });
    }

    // Web api calls
    const fetchItemApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/user/${id}`, globalState.authToken);
    }
    const saveItemApi = async function (apiBaseUrl: string | undefined, data: any) {
        if (!isGuid(id)) {
            // Create
            const options = { method: "POST", payload: data, };
            return request(`${apiBaseUrl}/user`, globalState.authToken, options);
        }
        else {
            // Update
            const options = { method: "PUT", payload: data, };
            return request(`${apiBaseUrl}/user/${id}`, globalState.authToken, options);
        }
    }
    const sendVerificationEmailApi = async function (apiBaseUrl: string | undefined) {
        if (!isGuid(id)) {
            // Not supported before save            
        }
        else {
            // Update
            const options = { method: "POST", payload: null, };
            return request(`${apiBaseUrl}/user/${id}/sendverificationemail`, globalState.authToken, options);
        }
    }
    const sendPasswordResetEmailApi = async function (apiBaseUrl: string | undefined) {
        if (!isGuid(id)) {
            // Not supported before save            
        }
        else {
            // Update
            const options = { method: "POST", payload: null, };
            return request(`${apiBaseUrl}/user/${id}/sendpasswordresetemail`, globalState.authToken, options);
        }
    }

    var sendVerificationButtonLabel = "E-Mail senden";
    if (emailChanged) {
        sendVerificationButtonLabel = "Speichern und E-Mail senden";
    }

    return (
        <form onSubmit={onSubmitHandler}>
            <div className="card p-10">
                <div className="row">
                    <FormControlWithLabel className="col-6" controlId="txtLastName" controlType="text" label="Nachname" value={lastName} isRequired={true} onChange={(value: any) => setLastName(value)} />
                    <FormControlWithLabel className="col-6" controlId="txtFirstName" controlType="text" label="Vorname" value={firstName} isRequired={true} onChange={(value: any) => setFirstName(value)} />
                    <FormControlWithLabel className="col-9" controlId="txtEmail" controlType="text" label="E-Mail / Benutzername" value={email} isRequired={true} onChange={(value: any) => {
                        setEmail(value);
                        setIsEmailConfirmed(false);
                        setEmailChanged(true);
                    }} />
                    <div className="col-3">
                        <label className="form-label mb-4">Verifikation der E-Mail-Adresse</label>
                        <FormControlWithLabel inputClassName={isEmailConfirmed ? "bg-success" : "bg-warning"} controlId="chkIsEmailConfirmed" controlType="checkbox" label={isEmailConfirmed ? "verifiziert" : "nicht verifziert"} useFloatingLabel={true} value={isEmailConfirmed} isRequired={false} onChange={(value: any) => setIsEmailConfirmed(value === "on")} />
                        {!isEmailConfirmed &&
                            <button type="button" className="btn btn-warning" onClick={onSendVerificationEmail}>{sendVerificationButtonLabel}</button>
                        }
                    </div>
                    <FormControlWithLabel className="col-6" controlId="chkIsActive" controlType="checkbox" label="Aktiv" useFloatingLabel={true} value={isActive} isRequired={false} onChange={(value: any) => setIsActive(value === "on")} />
                    {lockedUntil && <>
                        <FormControlWithLabel className="col-3" controlId="lblLockedUntil" controlType="label" label="Gesperrt bis" value={new Date(lockedUntil).toLocaleDateString("de-CH") + " " + new Date(lockedUntil).toLocaleTimeString("de-CH")} onChange={() => { }} />
                        <FormControlWithLabel className="col-3" controlId="chkDoUnlock" controlType="checkbox" label="Sofort entsperren" value={unlock} isRequired={false} onChange={(value: any) => setUnlock(value === "on")} />
                    </>}
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4>Passwort ändern</h4>
                        {!showPasswordReset && <>
                            <button type="button" className="btn btn-warning" onClick={onSendPasswordResetEmail}>Änderungslink per E-Mail zustellen</button>
                            <button type="button" className="btn btn-light ms-5" onClick={() => setShowPasswordReset(true)}>Passwort direkt setzen</button>
                        </>}
                        {showPasswordReset && <FormControlWithLabel className="col-12" controlId="txtNewPassword" controlType="text" label="Neues Passwort festlegen" value={newPassword} isRequired={false} onChange={(value: any) => setNewPassword(value)} />}
                    </div>
                </div>
                <div className="lp21-buttons">
                    <button type="submit" className="btn btn-primary">Speichern</button>
                    <button type="button" className="btn btn-secondary" onClick={onCancelHandler}>Abbrechen</button>
                </div>
            </div>
        </form>
    );
};

export default UserPage;