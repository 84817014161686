import React, { useEffect, useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import UnboundFormControlWithLabel from "../../../../components/UnboundControlWithLabel";
import AssessmentSchemaLevelSelect from "../../assessment/pages/AssessmentSchemaLevelSelect";

export function PlanItemCustomColumn(props: InferProps<typeof PlanItemCustomColumn.propTypes>) {

  // State
  const [isPlanned, setIsPlanned] = useState(false);

  // Mount
  useEffect(() => {
    setIsPlanned(props.item.isPlanned);
  }, []);

  // Functions
  const onIsPlannedChanged = function (value: boolean) {
    setIsPlanned(value);
    return props.onSaveItem("aufzaehlungspunkt", props.item.id, props.item, value, null, null);
  }

  var className = `lp21-customColumn-${props.level}`;

  if (props.level === "kompetenzstufe") {
    return <td className={className}>
      <div className="row">
        <div className="col-4">
          <div><AssessmentSchemaLevelSelect levels={props.assessmentSchemaLevels} value={props.item.assessmentSchemaLevelId} isDisabled={true} renderLegend={true} onChange={() => { }} /></div>
        </div>
        <div className="col-8">
          <UnboundFormControlWithLabel className="" controlId="chkIsPlanned" controlType="checkbox" label="geplant" value={props.item.isPlanned} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => onIsPlannedChanged(value === "on")} />

          {isPlanned && <div>
            <UnboundFormControlWithLabel controlId="txtSort" controlType="text" label="Quartal, Quintal, etc." useFloatingLabel={false} value={props.item.planSort} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem("aufzaehlungspunkt", props.item.id, props.item, null, value, null)} />
            <UnboundFormControlWithLabel controlId="txtRemarks" className="mt-5" controlType="html" htmlEditorInit={{ height: 200, block_formats: "Absatz=p", hideFormatSelect: true }} label="Bemerkungen, Kommentare" value={props.item.planRemarks} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => props.onSaveItem("aufzaehlungspunkt", props.item.id, props.item, null, null, value)} />
          </div>}
        </div>

        {props.remark && props.remark.assessmentRemarks &&
          <div className={`${className}-assessment`}>
            <label className="form-label">Lernstand</label>
            <p>{props.remark.assessmentRemarks}</p>
          </div>
        }
      </div>
    </td>;
  }
  else {
    return <td className={className}></td>;
  }
};

PlanItemCustomColumn.propTypes = {
  assessmentSchemaLevels: PropTypes.any,
  rowId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  item: PropTypes.any,
  remark: PropTypes.any,
  reloadSummary: PropTypes.any,

  isReadOnly: PropTypes.bool.isRequired,
  onSaveItem: PropTypes.func.isRequired,
};

export default PlanItemCustomColumn;

