import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PageTitle } from "../../_start/layout/core";
import { useGlobalState } from "../GlobalStateProvider";
import AdminPageWrapper from "../modules/admin/AdminPageWrapper";
import FamiliesPage from "../modules/admin/pages/FamiliesPage";
import FamilyAdminPage from "../modules/admin/pages/FamilyPage";
import FamilyUserPage from "../modules/admin/pages/FamilyUserPage";
import FamilyUsersPage from "../modules/admin/pages/FamilyUsersPage";
import { JobsPage } from "../modules/admin/pages/JobsPage";
import UserAdminPage from "../modules/admin/pages/UserPage";
import UsersPage from "../modules/admin/pages/UsersPage";
import AssessmentCopyPage from "../modules/assessment/pages/AssessmentCopyPage";
import AssessmentItemsPage from "../modules/assessment/pages/AssessmentItemsPage";
import AssessmentPage from "../modules/assessment/pages/AssessmentPage";
import AssessmentReportViewerPage from "../modules/assessment/pages/AssessmentReportViewerPage";
import AssessmentsPage from "../modules/assessment/pages/AssessmentsPage";
import BrowsePage from "../modules/browser/pages/BrowsePage";
import BrowsePlainPage from "../modules/browser/pages/BrowsePlainPage";
import PlanItemsPage from "../modules/plan/pages/PlanItemsPage";
import PlanPage from "../modules/plan/pages/PlanPage";
import PlanReportViewerPage from "../modules/plan/pages/PlanReportViewerPage";
import PlansPage from "../modules/plan/pages/PlansPage";
import PrivatePageWrapper from "../modules/PrivatePageWrapper";
import ChildGradeLevelPage from "../modules/settings/pages/ChildGradeLevelPage";
import ChildGradeLevelsPage from "../modules/settings/pages/ChildGradeLevelsPage";
import ChildPage from "../modules/settings/pages/ChildPage";
import ChildrenPage from "../modules/settings/pages/ChildrenPage";
import FamilyPage from "../modules/settings/pages/FamilyPage";
import UserPage from "../modules/settings/pages/UserPage";
import { DashboardPage } from "../pages/dashboards/DashboardPage";
import PlanCopyPage from "../modules/plan/pages/PlanCopyPage";

export function PrivateRoutes() {

  // Global State
  const { globalState, } = useGlobalState();

  const isReadOnly = globalState.isCoach ?? false;

  return (
    <>
      <Routes>

        {/* App */}
        <Route path="/app" element={<PrivatePageWrapper />}>

          {/* Dashboard */}
          <Route path="dashboard" element={<>
            <PageTitle>Start</PageTitle>
            <DashboardPage />
          </>} />

          {/* Browse */}
          <Route path="browse/table" element={<>
            <PageTitle>Lehrplan durchsuchen (tabellarisch)</PageTitle>
            <BrowsePlainPage />
          </>} />
          <Route path="browse" element={<>
            <PageTitle>Lehrplan durchsuchen</PageTitle>
            <BrowsePage />
          </>} />

          {/* Assessment */}
          <Route path="assessments/:id/report" element={
            <>
              <PageTitle>Lernstandserfassung (Bericht)</PageTitle>
              <AssessmentReportViewerPage />
            </>
          } />
          <Route path="assessments/:id/items" element={
            <>
              <PageTitle>Lernstandserfassung</PageTitle>
              <AssessmentItemsPage isReadOnly={isReadOnly} />
            </>
          } />
          <Route path="assessments/:id/copy" element={
            <>
              <PageTitle>Einträge kopieren</PageTitle>
              <AssessmentCopyPage isReadOnly={isReadOnly} />
            </>
          } />
          <Route path="assessments/:id" element={
            <>
              <PageTitle>Lernstandserfassung</PageTitle>
              <AssessmentPage isReadOnly={isReadOnly} />
            </>
          } />
          <Route path="assessments" element={
            <>
              <PageTitle>Alle Lernstandserfassungen</PageTitle>
              <AssessmentsPage isReadOnly={isReadOnly} />
            </>
          } />

          {/* Plan */}
          <Route path="plans/:id/report" element={
            <>
              <PageTitle>Planung (Bericht)</PageTitle>
              <PlanReportViewerPage />
            </>
          } />
          <Route path="plans/:id/items" element={
            <>
              <PageTitle>Planung</PageTitle>
              <PlanItemsPage isReadOnly={isReadOnly} />
            </>
          } />
          <Route path="plans/:id/copy" element={
            <>
              <PageTitle>Einträge kopieren</PageTitle>
              <PlanCopyPage isReadOnly={isReadOnly} />
            </>
          } />
          <Route path="plans/:id" element={
            <>
              <PageTitle>Planung</PageTitle>
              <PlanPage isReadOnly={isReadOnly} />
            </>
          } />
          <Route path="plans" element={
            <>
              <PageTitle>Alle Planungen</PageTitle>
              <PlansPage isReadOnly={isReadOnly} />
            </>
          } />

          {/* Settings  */}
          <Route path="settings/family" element={
            <>
              <PageTitle>Familie</PageTitle>
              <FamilyPage isReadOnly={isReadOnly} />
            </>
          } />
          <Route path="settings/children/:id" element={
            <>
              <PageTitle>Kind</PageTitle>
              <ChildPage isReadOnly={isReadOnly} />
            </>
          } />
          <Route path="settings/children" element={
            <>
              <PageTitle>Kinder</PageTitle>
              <ChildrenPage isReadOnly={isReadOnly} />
            </>
          } />
          <Route path="settings/childgradelevels/:id" element={
            <>
              <PageTitle>Schuljahr</PageTitle>
              <ChildGradeLevelPage isReadOnly={isReadOnly} />
            </>
          } />
          <Route path="settings/childgradelevels" element={
            <>
              <PageTitle>Schuljahre</PageTitle>
              <ChildGradeLevelsPage isReadOnly={isReadOnly} />
            </>
          } />
          <Route path="settings/user" element={
            <>
              <PageTitle>Benutzer</PageTitle>
              <UserPage isReadOnly={isReadOnly} />
            </>
          } />
          <Route path="settings" element={<Navigate to="/app/settings/family" />} />

          {/* Default */}
          <Route path="*" element={<Navigate to="/app/dashboard" />} />
        </Route>

        {/* Admin */}
        {globalState.isAdmin &&
          <Route path="/app/admin" element={<AdminPageWrapper />}>

            {/* Families  */}
            <Route path="families/:id" element={
              <>
                <PageTitle>Familie</PageTitle>
                <FamilyAdminPage />
              </>
            } />
            <Route path="families" element={
              <>
                <PageTitle>Familien</PageTitle>
                <FamiliesPage />
              </>
            } />

            {/* Family Users  */}
            <Route path="familyusers/:id" element={
              <>
                <PageTitle>Berechtigungen</PageTitle>
                <FamilyUserPage />
              </>
            } />
            <Route path="familyusers" element={
              <>
                <PageTitle>Berechtigungen</PageTitle>
                <FamilyUsersPage />
              </>
            } />

            {/* Users  */}
            <Route path="users/:id" element={
              <>
                <PageTitle>Benutzer</PageTitle>
                <UserAdminPage />
              </>
            } />
            <Route path="users" element={
              <>
                <PageTitle>Benutzer</PageTitle>
                <UsersPage />
              </>
            } />

            {/* Jobs  */}
            <Route path="jobs" element={
              <>
                <PageTitle>Aufträge</PageTitle>
                <JobsPage />
              </>
            } />

            <Route path="users" element={
              <>
                <PageTitle>Benutzer</PageTitle>
                <UsersPage />
              </>
            } />

            <Route path="*" element={
              <Navigate to="families" />
            } />

          </Route>
        }

        <Route path="/auth" element={<Navigate to="/app/dashboard" />} />
        <Route path="*" element={<Navigate to="/error/404" />} />

      </Routes>
    </>
  );
}