import React, { useEffect } from "react";
import PropTypes, { InferProps } from "prop-types";
import AssessmentSchemaLevelSelect from "../../assessment/pages/AssessmentSchemaLevelSelect";

export function PlanReportItemCustomColumn(props: InferProps<typeof PlanReportItemCustomColumn.propTypes>) {

  // Mount
  useEffect(() => {

  }, []);

  var className = `lp21-customColumn-${props.level}`;

  if (props.level === "kompetenzstufe") {
    return <td className={className}>
      <div className="row">
        <div className="col-3">
          <AssessmentSchemaLevelSelect levels={props.assessmentSchemaLevels} value={props.item.assessmentSchemaLevelId} isDisabled={true} renderLegend={true} onChange={() => { }} />
        </div>
        <div className="col-9">
          {props.item.planSort && <div>{props.item.planSort}</div>}
          {props.item.planRemarks && <div dangerouslySetInnerHTML={{ __html: props.item.planRemarks }}></div>}
        </div>
      </div>
    </td>;
  }
  else {
    return <td className={className}>?</td>;
  }
};

PlanReportItemCustomColumn.propTypes = {
  assessmentSchemaLevels: PropTypes.any,
  rowId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  item: PropTypes.any,
  remark: PropTypes.any,
};

export default PlanReportItemCustomColumn;

