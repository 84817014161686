import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../../GlobalStateProvider";
import Lehrplan21Table from "./Lehrplan21Table";
import { useInView } from "react-cool-inview";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import NoItems from "../../../../components/NoItems";
import { FilterModel, IFilterModel } from "./FilterModel";
import Lehrplan21FilterPane from "./Lehrplan21FilterPane";
import Lehrplan21NavigationPane from "./Lehrplan21NavigationPane";

export function BrowsePage() {

  // Global State
  const { globalState } = useGlobalState();

  // State
  const [filter] = useState<FilterModel>(new FilterModel({lehrplanId: globalState.defaultLehrplanId} as IFilterModel));
  const [items, setItems] = useState([]);
  const [pageSize] = useState(10);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [hasNoItems, setHasNoItems] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Infinite scroll
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve, observe }) => {
      unobserve();
      onNextPage(() => {
        observe();
      }, false);
    },
  });

  // Mount
  useEffect(() => {
    // Is a default Lehrplan known?
    if (globalState.defaultLehrplanId) {
      // Items
      fetchItems(false, filter, null, false);
    }
  }, []);

  // Event handlers
  const onFilterChanged = function () {
    fetchItems(false, filter, null, false);
  }

  const onNextPage = function (postFetchFunc: any, loadAll: boolean) {
    fetchItems(true, filter, postFetchFunc, loadAll);
  }

  // Functions
  const fetchItems = function (appendItems: boolean, filter: FilterModel | null, postFetchFunc: any, loadAll: boolean) {

    if (!filter?.lehrplanId) {
      return;
    }

    setIsLoading(true);

    if (!items || items.length === 0) {
      appendItems = false;
    }

    var offset = (appendItems === false ? 0 : items.length);
    var next = (loadAll ? 2147483647 : pageSize);

    fetchItemsApi(globalState.apiBaseUrl, offset, next, filter)
      .then((result: any) => {

        if (result.queryId < (globalState.queryId ?? 0)) {
          // Ignore results, seem to be too late
          console.log(`Skipped query id ${result.queryId} as running alredy query id ${globalState.queryId}.`);
          return;
        }

        var resultItems = (appendItems === false ? result.items : [...items, ...result.items]);
        setItems(resultItems);
        setIsLoading(false);
        setHasMoreItems((result?.items?.length ?? 0) === next);
        setHasNoItems(resultItems.length === 0);
        if (postFetchFunc) postFetchFunc();
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
        setIsLoading(false);
      });
  };

  const clearItems = function () {
    setItems([]);
  };


  // Web api calls
  const fetchItemsApi = async function (apiBaseUrl: string | undefined, offset: number, next: number, filter: FilterModel | null) {
    globalState.queryId = (globalState.queryId ?? 0) + 1;
    return request(`${apiBaseUrl}/lehrplan21/browse?queryId=${globalState.queryId}&offset=${offset}&next=${next}&${filter?.toUrlQueryParams()}`, globalState.authToken);
  }

  return (
    <>
      <div className="lp21-panes">
        <Lehrplan21FilterPane filter={filter} onFilterChanged={onFilterChanged} onClearItems={clearItems} isLoading={isLoading} filterAutoSaveSuffix="Lehrplan durchsuchen" />
        <Lehrplan21NavigationPane items={items} levelUntil={filter?.levelUntil} pageSize={pageSize} hasMoreItems={hasMoreItems} onLoadNext={() => {onNextPage(null, false)}} showLoadAll={(filter?.fachbereichId ?? "") !== ""} onLoadAll={() => {onNextPage(null, true)}} />
      </div>
      <div className="card">
        <Lehrplan21Table items={items} levelUntil={filter?.levelUntil} renderLinks={true} renderHeaderRow={false} />
        {!isLoading && hasMoreItems && <button ref={observe} className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary" onClick={() => { onNextPage(null, false) }}>Nächste laden</button>}
        {isLoading && <div className="row m-3"> <LoadingIndicator /></div>}
        {!isLoading && hasNoItems && <NoItems />}
      </div>
    </>
  );
};

export default BrowsePage;