import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../../GlobalStateProvider";
import { useNavigate, useParams } from "react-router";
import FormControlWithLabel from "../../../../components/FormControlWithLabel";
import PropTypes, { InferProps } from "prop-types";
import "./PlanCopyPage.css";
import SaveAndCancelOrCloseButtons from "../../../../components/SaveAndCancelOrCloseButtons";

export function PlanCopyPage(props: InferProps<typeof PlanCopyPage.propTypes>) {

  // Hooks
  const navigate = useNavigate();

  // Global State
  const { globalState } = useGlobalState();

  // State
  const [plans, setPlans] = useState([]);
  const [otherPlans, setOtherPlans] = useState([]);
  const [plan, setPlan] = useState<any>(null);
  const [fromPlan, setFromPlan] = useState<any>(null);
  const [copyRemarks, setCopyRemarks] = useState<any>(false);
  const [overwrite, setOverwrite] = useState<any>(false);

  // Route params
  const { id } = useParams<{ id: string }>();

  // Mount
  useEffect(() => {

    if (!id) {
      return;
    }

    fetchItemApi(id)
      .then((result: any) => {
        setPlan(result);

        // Lookups
        fetchPlansApi()
          .then((res: any) => {

            var otherPlans = res.filter((a: any) => {
              return a.id !== id;
            })

            // No other plans available?
            if (!otherPlans || otherPlans.length === 0) {
              onCancelHandler();
              return;
            }

            setPlans(res);
            setOtherPlans(otherPlans);
          })
          .catch((ex: any) => { alert(`Error: ${ex})`) });

      })
      .catch((ex: any) => {
        alert(`Error: ${ex})`);
      });

  }, []);

  // Event handlers
  const onFromPlanChanged = (value: any) => {

    var fromPlanId = value;

    // No plan selected?
    if (!fromPlanId) {
      setFromPlan(null);
      return;
    }

    fetchItemApi(fromPlanId)
      .then((result: any) => {
        setFromPlan(result);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex})`);
      });
  };

  const onSubmitHandler = function (e: any) {
    e.preventDefault();
    copyItemsApi(fromPlan?.id, copyRemarks, overwrite)
      .then(() => {
        navigate("/app/plans");
      })
      .catch((ex: any) => {
        if (ex && ex.response && ex.response.details) {
          alert(`Error: ${ex})\n\n${ex.response.details}`);
        } else {
          alert(`Error: ${ex})`);
        }
      });
  }

  const onCancelHandler = function () {
    navigate("/app/Plans");
  }

  // Functions

  // Web api calls
  const fetchItemApi = async function (planId: string) {
    return request(`${globalState.apiBaseUrl}/plan/${planId}`, globalState.authToken);
  }
  const fetchPlansApi = async function () {
    return request(`${globalState.apiBaseUrl ?? ""}/plan?familyId=${globalState.familyId}`, globalState.authToken);
  }
  const copyItemsApi = async function (fromPlanId: string, copyRemarks: boolean, overwrite: boolean) {
    const options = { method: "POST" };
    return request(`${globalState.apiBaseUrl}/Plan/${id}/copy?fromPlanid=${fromPlanId}&copyRemarks=${copyRemarks}&overwrite=${overwrite}`, globalState.authToken, options);
  }

  var tableClassName = "lp21-copy";

  if (overwrite) {
    tableClassName += " lp21-copy-overwrite";
  }

  var sameLehrplan = plan?.lehrplanId === fromPlan?.lehrplanId;

  return (
    <form onSubmit={onSubmitHandler}>

      <div className="card">
        <div className="card-body">

          <table className={`table table-row-dashed ${tableClassName}`}>
            <colgroup>
              <col width="40%" />
              <col width="20%" />
              <col width="40%" />
            </colgroup>
            <thead>
              <tr>
                <td><FormControlWithLabel useFloatingLabel={false} controlId="lstFromPlan" controlType="select" label="Kopieren von" options={otherPlans} value={fromPlan?.id} isRequired={true} onChange={onFromPlanChanged} /></td>
                <td></td>
                <td><FormControlWithLabel useFloatingLabel={false} controlId="lstFromPlan" controlType="select" label="Kopieren nach" options={plans} value={id} isRequired={true} isDisabled={true} onChange={() => { }} /></td>
              </tr>
            </thead>

            {fromPlan && fromPlan.id &&
              <tbody>
                <tr>
                  <td>{fromPlan?.lehrplanName}</td>
                  <td>
                    {sameLehrplan && <i className="bi bi-check fs-1 text-primary"></i>}
                    {!sameLehrplan && <i className="bi bi-exclamation-circle-fill fs-1 text-danger"></i>}</td>
                  <td>{plan?.lehrplanName}</td>
                </tr>
                <tr><td colSpan={3}></td></tr>
                <tr>
                  <td>{fromPlan?.remarksCount ?? "0"} Einträge</td>
                  <td>
                    {copyRemarks && <i className="bi bi-arrow-right fs-1 text-primary"></i>}
                    {!copyRemarks && <i className="bi bi-x fs-1"></i>}
                  </td>
                  <td className="lp21-overridable">{plan?.remarksCount ?? "0"} Einträge</td>
                </tr>
              </tbody>}
          </table>

          <h6 className="mb-3">Kopiereinstellungen</h6>
          <div className="row lp21-copysettings">
            <div className="col-6">
              <FormControlWithLabel controlId="chkCopyRemarks" controlType="checkbox" useFloatingLabel={true} label="Einträge kopieren" value={copyRemarks} onChange={(value: any) => setCopyRemarks(value === "on")} />
            </div>
            <FormControlWithLabel className="col-6" controlId="chkOverwrite" controlType="checkbox" useFloatingLabel={true} label="Überschreiben" value={overwrite} onChange={(value: any) => setOverwrite(value === "on")} />
          </div>
          <SaveAndCancelOrCloseButtons isReadOnly={props.isReadOnly} isDisabled={!sameLehrplan || !copyRemarks} submitLabel="Kopieren" onCancelHandler={onCancelHandler} />

        </div>
      </div>
    </form>
  );
};

PlanCopyPage.propTypes = {
  isReadOnly: PropTypes.bool.isRequired
};

export default PlanCopyPage;

