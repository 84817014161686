import PropTypes, { InferProps } from "prop-types";
import React, { useRef } from "react";
import { toAbsoluteUrl } from "../_start/helpers";
import { Editor } from '@tinymce/tinymce-react';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';


export function HtmlEditor(props: InferProps<typeof HtmlEditor.propTypes>) {

  // Elements
  const editorRef = useRef<any>(null);

  // Event handlers
  const onChangeHandler = function (value: any) {
    props.onChange(value);
  }

  // Default configuration
  const defaultInit = {
    language: 'de',
    height: 500,
    menubar: false,
    plugins: [
      'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
      'searchreplace', 'visualblocks', 'code', 'fullscreen',
      'insertdatetime', 'media', 'table', 'help', 'wordcount',
      'quickbars'
    ],
    toolbar: 'undo redo | blocks | ' +
      'bold italic underline strikethrough backcolor | alignleft aligncenter ' +
      'alignright alignjustify | charmap bullist numlist outdent indent | ' +
      'table | ' +
      'removeformat | code fullscreen | help',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

    // Enable spell check
    browser_spellcheck: true,
    contextmenu: false,
  };

  // Prepare configuration 
  const currentInit = {
    ...defaultInit,
    ...props.init
  };

  // Remove certain elements
  if (currentInit.hideFormatSelect) {
    currentInit.toolbar = currentInit.toolbar.replace("| blocks", "");
  }

  return <>
    <Editor
      tinymceScriptSrc={toAbsoluteUrl("tinymce.min.js")}
      onInit={(evt, editor) => editorRef.current = editor}
      onEditorChange={onChangeHandler}
      initialValue={props.defaultValue}
      value={!props.defaultValue ? props.value : null}  /* only set is defaultValue is not used */
      init={currentInit}
      disabled={props.disabled ?? false}
    />
  </>
}

HtmlEditor.propTypes = {
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  init: PropTypes.any.isRequired,
  disabled: PropTypes.bool,

  // Events
  onChange: PropTypes.func.isRequired,
};

export default HtmlEditor;