import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { requestPasswordReset } from "../redux/AuthCRUD";
import { useGlobalState } from "../../../GlobalStateProvider";

const initialValues = {
  email: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Ungültige E-Mail-Adresse")
    .min(3, "Mindestens 3 Zeichen")
    .max(50, "Maximal 50 Zeichen")
    .required("E-Mail ist erforderlich"),
});

export function ForgotPassword() {

  // Global State
  const { globalState } = useGlobalState();
  
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        requestPasswordReset(globalState.apiBaseUrl ?? "", values.email)
          .then(() => {
            setHasErrors(false);
            setLoading(false);
            setSubmitting(false);
          })
          .catch((ex: any) => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
            if (ex && ex.response && ex.response.data && ex.response.data.message) {
              setStatus("Die Änderungslink konnte nicht erstellt werden: " + ex.response.data.message);
            }
            else {
              setStatus("Die Änderungslink konnte nicht erstellt werden:" + ex.name);
            }
          });
      }, 1000);
    },
  });

  return (
    <div className="mw-650px">
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="pb-5 pb-lg-10">
          <h3 className="fw-bolder text-dark display-6">
            Passwort vergessen?
          </h3>
          <p className="text-muted fw-bold fs-3">
            Gebe deine E-Mail-Adresse ein, um dein Passwort zurückzusetzen.
          </p>
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        {hasErrors === false && (
          <div className="mb-lg-15 alert alert-success">
            <div className="alert-text ">
              Wir haben dir ein E-Mail zugestellt, worin du einen Änderungslink findest, um das Passwort zurückzusetzen. Bitte prüfe deinen Posteingang.
            </div>
          </div>
        )}
        {/* end::Title */}

        {hasErrors !== false &&
        <>
        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">
            E-Mail
          </label>
          <input
            type="email"
            placeholder="E-Mail"
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap pb-lg-0">
          <button
            type="submit"
            id="kt_login_password_reset_form_submit_button"
            className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-4"
          >
            Änderungslink anfordern
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-light-primary fw-bolder fs-6 px-8 py-4 my-3"
              disabled={formik.isSubmitting}
            >
              Abbrechen
            </button>
          </Link>{" "}
          {loading && (
            <span
              className="spinner-border text-primary ms-3 mt-6"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </span>
          )}
        </div>
        {/* end::Form group */}
        </>}
      </form>
    </div>
  );
}
