/* eslint-disable jsx-a11y/anchor-is-valid */
import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import { isGuid } from "../../helper";
import FormControlWithLabel from "../../../../components/FormControlWithLabel";
import PropTypes, { InferProps } from "prop-types";
import SaveAndCancelOrCloseButtons from "../../../../components/SaveAndCancelOrCloseButtons";
import { Tab, Tabs } from "react-bootstrap";

export function PlanPage(props: InferProps<typeof PlanPage.propTypes>) {

  // Hooks
  const navigate = useNavigate();

  // Global State
  const { globalState, } = useGlobalState();

  // State
  const [childGradeLevels, setChildGradeLevels] = useState([]);
  const [planTypes, setPlanTypes] = useState([]);
  const [planStatus, setPlanStatus] = useState<any>([]);
  const [assessments, setAssessments] = useState([]);
  const [childGradeLevelId, setChildGradeLevelId] = useState(null);
  const [date, setDate] = useState("");
  const [reportTitle, setReportTitle] = useState("");
  const [reportSubtitle, setReportSubtitle] = useState("");
  const [reportAuthor, setReportAuthor] = useState("");
  const [reportFooter, setReportFooter] = useState("");
  const [reportIntroduction, setReportIntroduction] = useState("");
  const [reportChapter1, setReportChapter1] = useState("");
  const [reportChapter2, setReportChapter2] = useState("");
  const [reportChapter3, setReportChapter3] = useState("");
  const [reportChapter4, setReportChapter4] = useState("");
  const [reportChapter5, setReportChapter5] = useState("");
  const [planStatusId, setPlanStatusId] = useState(null);
  const [planTypeId, setPlanTypeId] = useState(null);
  const [linkedAssessmentId, setLinkedAssessmentId] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);

  // Route params
  const { id } = useParams<{ id: string }>();

  // Mount
  useEffect(() => {

    // Read-only
    if (props.isReadOnly) {
      setIsReadOnly(props.isReadOnly);
    }

    // Lookups
    fetchChildGradeLevelsApi(globalState.apiBaseUrl).then((result: any) => { setChildGradeLevels(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
    fetchPlanTypesApi(globalState.apiBaseUrl).then((result: any) => { setPlanTypes(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
    fetchPlanStatusApi(globalState.apiBaseUrl).then((result: any) => { setPlanStatus(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
    fetchAssessmentsApi(globalState.apiBaseUrl).then((result: any) => { setAssessments(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });

    // New item
    if (!isGuid(id)) {
      return;
    }

    fetchItemApi(globalState.apiBaseUrl)
      .then((result: any) => {
        setChildGradeLevelId(result.childGradeLevelId);
        setDate(result.date);
        setReportTitle(result.reportTitle);
        setReportSubtitle(result.reportSubtitle);
        setReportAuthor(result.reportAuthor);
        setReportFooter(result.reportFooter);
        setReportIntroduction(result.reportIntroduction);
        setReportChapter1(result.reportChapter1);
        setReportChapter2(result.reportChapter2);
        setReportChapter3(result.reportChapter3);
        setReportChapter4(result.reportChapter4);
        setReportChapter5(result.reportChapter5);
        setPlanStatusId(result.planStatusId);
        setPlanTypeId(result.planTypeId);
        setLinkedAssessmentId(result.linkedAssessmentId);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex})`);
      });


  }, []);

  useEffect(() => {
    const currentPlanStatus = planStatus.find((ps: any) => ps.id === planStatusId);

    if (currentPlanStatus) {
      setIsReadOnly(currentPlanStatus.isReadOnly);
    }

  }, [planStatusId]);

  // Event handlers
  const onSubmitHandler = function (e: any) {
    e.preventDefault();
    saveItemApi(globalState.apiBaseUrl, {
      childGradeLevelId: childGradeLevelId,
      date: date,
      reportTitle: reportTitle,
      reportSubtitle: reportSubtitle,
      reportAuthor: reportAuthor,
      reportFooter: reportFooter,
      reportIntroduction: reportIntroduction,
      reportChapter1: reportChapter1,
      reportChapter2: reportChapter2,
      reportChapter3: reportChapter3,
      reportChapter4: reportChapter4,
      reportChapter5: reportChapter5,
      planStatusId: planStatusId,
      planTypeId: planTypeId,
      linkedAssessmentId: linkedAssessmentId,
    })
      .then((result) => {
        if (result) {
          navigate(`/app/plans/${result}/copy`);
        } else {
          navigate("/app/plans");
        }
      })
      .catch((ex: any) => {
        if (ex && ex.response && ex.response.details) {
          alert(`Error: ${ex})\n\n${ex.response.details}`);
        } else {
          alert(`Error: ${ex})`);
        }
      });
  }

  const onCancelHandler = function () {
    navigate("/app/plans");
  }

  // Web api calls
  const fetchChildGradeLevelsApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/childgradelevel?familyId=${globalState.familyId}`, globalState.authToken);
  }
  const fetchPlanTypesApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/masterdata/plantype`, globalState.authToken);
  }
  const fetchPlanStatusApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/masterdata/planstatus`, globalState.authToken);
  }
  const fetchAssessmentsApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/assessment?familyId=${globalState.familyId}`, globalState.authToken);
  }
  const fetchItemApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/plan/${id}`, globalState.authToken);
  }
  const saveItemApi = async function (apiBaseUrl: string | undefined, data: any) {
    if (!isGuid(id)) {
      // Create
      const options = { method: "POST", payload: data, };
      return request(`${apiBaseUrl}/plan`, globalState.authToken, options);
    }
    else {
      // Update
      const options = { method: "PUT", payload: data, };
      return request(`${apiBaseUrl}/plan/${id}`, globalState.authToken, options);
    }
  }

  return (
    <form onSubmit={onSubmitHandler}>
      <div className="card p-10">
        <div className="row">
          <FormControlWithLabel className="col-4" controlId="dtDate" controlType="date" label="Datum" value={date} isRequired={true} isDisabled={isReadOnly} onChange={(value: any) => setDate(value)} />
          <FormControlWithLabel className="col-4" controlId="lstPlanType" controlType="select" label="Art" options={planTypes} value={planTypeId} isRequired={true} isDisabled={isReadOnly} onChange={(value: any) => setPlanTypeId(value)} />
          <FormControlWithLabel className="col-4" controlId="lstChildGradeLevel" controlType="select" label="Kind / Schuljahr" options={childGradeLevels} value={childGradeLevelId} isRequired={true} isDisabled={isReadOnly} onChange={(value: any) => setChildGradeLevelId(value)} />
          <FormControlWithLabel className="col-4" controlId="lstPlanStatus" controlType="select" label="Status" options={planStatus} value={planStatusId} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setPlanStatusId(value)}>
            <h6 className="text-primary"><i className="bi bi-lightbulb fs-3 text-primary"></i> Erläuterungen zum Status</h6>
            <ul>
              <li><b>Entwurf</b> &rArr; Wir wissen, dass wir per diesem Datum eine Planung machen und einreichen müssen.</li>
              <li><b>Aktiv</b> &rArr; An dieser Planung arbeiten wir momentan.</li>
              <li><b>Eingereicht</b> &rArr; Wir haben diese Planung eingereicht (Schreibschutz wird aktiviert).</li>
            </ul>
          </FormControlWithLabel>
          <FormControlWithLabel className="col-4" controlId="lstLinkedAssessment" controlType="select" label="zugehörige Lernstandserfassung" options={assessments} value={linkedAssessmentId} isRequired={false} isDisabled={isReadOnly} onChange={(value: any) => setLinkedAssessmentId(value)} />
        </div>

        <h4>Bericht</h4>

        <Tabs defaultActiveKey="tab_txtReportFirstPage">

          <Tab eventKey="tab_txtReportFirstPage" title="Deckblatt">
            <div className="row mt-5">
              <FormControlWithLabel className="col-6" controlId="txtReportTitle" controlType="text" label="Titel" value={reportTitle} isDisabled={isReadOnly} onChange={(value: any) => setReportTitle(value)} />
              <FormControlWithLabel className="col-6" controlId="txtReportSubtitle" controlType="text" label="Untertitel" value={reportSubtitle} isDisabled={isReadOnly} onChange={(value: any) => setReportSubtitle(value)} />
              <FormControlWithLabel className="col-6" controlId="txtReportAuthorTitle" controlType="text" label="Autor" value={reportAuthor} isDisabled={isReadOnly} onChange={(value: any) => setReportAuthor(value)} />
              <FormControlWithLabel className="col-12" controlId="txtReportFooter" controlType="html" htmlEditorInit={{ height: 150, block_formats: "Titel=h2;Absatz=p", toolbar: "undo redo | bold italic | alignleft aligncenter alignright alignjustify | removeformat | code fullscreen | help" }} label="Fusszeile" value={reportFooter} isDisabled={isReadOnly} onChange={(value: any) => setReportFooter(value)} />
            </div>
          </Tab>

          <Tab eventKey="tab_txtReportIntroduction" title="Einleitung">
            <FormControlWithLabel className="col-12" controlId="txtReportIntroduction" controlType="html" htmlEditorInit={{ height: 300, block_formats: "Titel=h2;Absatz=p" }} label="" value={reportIntroduction} isDisabled={isReadOnly} onChange={(value: any) => setReportIntroduction(value)} />
          </Tab>

          <Tab eventKey="tab_txtReportChapter1" title="Lehrperson(en)">
            <FormControlWithLabel className="col-12" controlId="txtReportChapter1" controlType="html" htmlEditorInit={{ height: 300, block_formats: "Titel=h2;Absatz=p" }} label="" value={reportChapter1} isDisabled={isReadOnly} onChange={(value: any) => setReportChapter1(value)} />
          </Tab>

          <Tab eventKey="tab_txtReportChapter2" title="Unterricht">
            <FormControlWithLabel className="col-12" controlId="txtReportChapter2" controlType="html" htmlEditorInit={{ height: 300, block_formats: "Titel=h2;Absatz=p" }} label="" value={reportChapter2} isDisabled={isReadOnly} onChange={(value: any) => setReportChapter2(value)} />
          </Tab>

          <Tab eventKey="tab_txtReportChapter3" title="Lernort">
            <FormControlWithLabel className="col-12" controlId="txtReportChapter3" controlType="html" htmlEditorInit={{ height: 300, block_formats: "Titel=h2;Absatz=p" }} label="" value={reportChapter3} isDisabled={isReadOnly} onChange={(value: any) => setReportChapter3(value)} />
          </Tab>

          <Tab eventKey="tab_txtReportChapter4" title="Stundenplan">
            <FormControlWithLabel className="col-12" controlId="txtReportChapter4" controlType="html" htmlEditorInit={{ height: 300, block_formats: "Titel=h2;Absatz=p" }} label="" value={reportChapter4} isDisabled={isReadOnly} onChange={(value: any) => setReportChapter4(value)} />
          </Tab>

          <Tab eventKey="tab_txtReportChapter5" title="Jahresplanung gemäss Lehrplan 21">
            <FormControlWithLabel className="col-12" controlId="txtReportChapter5" controlType="html" htmlEditorInit={{ height: 300, block_formats: "Titel=h2;Absatz=p" }} label="" value={reportChapter5} isDisabled={isReadOnly} onChange={(value: any) => setReportChapter5(value)} />
          </Tab>

        </Tabs>

        <SaveAndCancelOrCloseButtons isReadOnly={props.isReadOnly} onCancelHandler={onCancelHandler} />
      </div>
    </form>
  );
};

PlanPage.propTypes = {
  isReadOnly: PropTypes.bool.isRequired
};

export default PlanPage;