import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../../GlobalStateProvider";
import { useNavigate, useParams } from "react-router";
import FormControlWithLabel from "../../../../components/FormControlWithLabel";
import PropTypes, { InferProps } from "prop-types";
import AssessmentSummaryLoader from "../../../../components/AssessmentSummaryLoader";
import "./AssessmentCopyPage.css";
import SaveAndCancelOrCloseButtons from "../../../../components/SaveAndCancelOrCloseButtons";

export function AssessmentCopyPage(props: InferProps<typeof AssessmentCopyPage.propTypes>) {

  // Hooks
  const navigate = useNavigate();

  // Global State
  const { globalState } = useGlobalState();

  // State
  const [assessments, setAssessments] = useState([]);
  const [otherAssessments, setOtherAssessments] = useState([]);
  const [assessment, setAssessment] = useState<any>(null);
  const [fromAssessment, setFromAssessment] = useState<any>(null);
  const [copyLevels, setCopyLevels] = useState<any>(true);
  const [copyRemarks, setCopyRemarks] = useState<any>(false);
  const [copyImages, setCopyImages] = useState<any>(false);
  const [overwrite, setOverwrite] = useState<any>(false);

  // Route params
  const { id } = useParams<{ id: string }>();

  // Mount
  useEffect(() => {

    if (!id) {
      return;
    }

    fetchItemApi(id)
      .then((result: any) => {
        setAssessment(result);

        // Lookups
        fetchAssessmentsApi()
          .then((res: any) => {

            var otherAssessments = res.filter((a: any) => {
              return a.id !== id;
            })

            // No other assessments available?
            if (!otherAssessments || otherAssessments.length === 0) {
              onCancelHandler();
              return;
            }

            setAssessments(res);
            setOtherAssessments(otherAssessments);
          })
          .catch((ex: any) => { alert(`Error: ${ex})`) });

      })
      .catch((ex: any) => {
        alert(`Error: ${ex})`);
      });

  }, []);

  // Event handlers
  const onFromAssessmentChanged = (value: any) => {

    var fromAssessmentId = value;

    // No assessment selected?
    if (!fromAssessmentId) {
      setFromAssessment(null);
      return;
    }

    fetchItemApi(fromAssessmentId)
      .then((result: any) => {
        setFromAssessment(result);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex})`);
      });
  };

  const onSubmitHandler = function (e: any) {
    e.preventDefault();
    copyItemsApi(fromAssessment?.id, copyLevels, copyRemarks, copyImages, overwrite)
      .then(() => {
        navigate("/app/assessments");
      })
      .catch((ex: any) => {
        if (ex && ex.response && ex.response.details) {
          alert(`Error: ${ex})\n\n${ex.response.details}`);
        } else {
          alert(`Error: ${ex})`);
        }
      });
  }

  const onCancelHandler = function () {
    navigate("/app/assessments");
  }

  // Functions

  // Web api calls
  const fetchItemApi = async function (assessmentId: string) {
    return request(`${globalState.apiBaseUrl}/assessment/${assessmentId}`, globalState.authToken);
  }
  const fetchAssessmentsApi = async function () {
    return request(`${globalState.apiBaseUrl ?? ""}/assessment?familyId=${globalState.familyId}`, globalState.authToken);
  }
  const copyItemsApi = async function (fromAssessmentId: string, copyLevels: boolean, copyRemarks: boolean, copyImages: boolean, overwrite: boolean) {
    const options = { method: "POST" };
    return request(`${globalState.apiBaseUrl}/assessment/${id}/copy?fromassessmentid=${fromAssessmentId}&copyLevels=${copyLevels}&copyRemarks=${copyRemarks}&copyImages=${copyImages}&overwrite=${overwrite}`, globalState.authToken, options);
  }

  var tableClassName = "lp21-copy";

  if (overwrite) {
    tableClassName += " lp21-copy-overwrite";
  }

  var sameLehrplan = assessment?.lehrplanId === fromAssessment?.lehrplanId;
  var sameAssessmentSchema = assessment?.assessmentSchemaId === fromAssessment?.assessmentSchemaId;


  return (
    <form onSubmit={onSubmitHandler}>

      <div className="card">
        <div className="card-body">

          <table className={`table table-row-dashed ${tableClassName}`}>
            <colgroup>
              <col width="40%" />
              <col width="20%" />
              <col width="40%" />
            </colgroup>
            <thead>
              <tr>
                <td><FormControlWithLabel useFloatingLabel={false} controlId="lstFromAssessment" controlType="select" label="Kopieren von" options={otherAssessments} value={fromAssessment?.id} isRequired={true} onChange={onFromAssessmentChanged} /></td>
                <td></td>
                <td><FormControlWithLabel useFloatingLabel={false} controlId="lstFromAssessment" controlType="select" label="Kopieren nach" options={assessments} value={id} isRequired={true} isDisabled={true} onChange={() => { }} /></td>
              </tr>
            </thead>

            {fromAssessment && fromAssessment.id &&
              <tbody>
                <tr>
                  <td>{fromAssessment && fromAssessment.id && <AssessmentSummaryLoader assessmentId={fromAssessment.id} reloadSummary={fromAssessment.id} />}</td>
                  <td></td>
                  <td>{id && <AssessmentSummaryLoader assessmentId={id} />}</td>
                </tr>
                <tr>
                  <td>{fromAssessment?.lehrplanName}</td>
                  <td>
                    {sameLehrplan && <i className="bi bi-check fs-1 text-primary"></i>}
                    {!sameLehrplan && <i className="bi bi-exclamation-circle-fill fs-1 text-danger"></i>}</td>
                  <td>{assessment?.lehrplanName}</td>
                </tr>
                <tr>
                  <td>{fromAssessment?.assessmentSchemaName}</td>
                  <td>
                    {sameAssessmentSchema && <i className="bi bi-check fs-1 text-primary"></i>}
                    {!sameAssessmentSchema && <i className="bi bi-shuffle fs-1 text-primary"></i>}</td>
                  <td>{assessment?.assessmentSchemaName}</td>
                </tr>
                <tr><td colSpan={3}></td></tr>
                <tr>
                  <td>{fromAssessment?.levelsCount ?? "0"} Bewertungen</td>
                  <td>
                    {copyLevels && <i className="bi bi-arrow-right fs-1 text-primary"></i>}
                    {!copyLevels && <i className="bi bi-x fs-1"></i>}
                  </td>
                  <td className="lp21-overridable">{assessment?.levelsCount ?? "0"} Bewertungen</td>
                </tr>
                <tr>
                  <td>{fromAssessment?.remarksCount ?? "0"} Texte</td>
                  <td>
                    {copyRemarks && <i className="bi bi-arrow-right fs-1 text-primary"></i>}
                    {!copyRemarks && <i className="bi bi-x fs-1"></i>}
                  </td>
                  <td className="lp21-overridable">{assessment?.remarksCount ?? "0"} Texte</td>
                </tr>
                <tr>
                  <td>{fromAssessment?.imagesCount ?? "0"} Bilder</td>
                  <td>
                    {copyImages && <i className="bi bi-arrow-right fs-1 text-primary"></i>}
                    {!copyImages && <i className="bi bi-x fs-1"></i>}
                  </td>
                  <td className="lp21-overridable">{assessment?.imagesCount ?? "0"} Bilder</td>
                </tr>
              </tbody>}
          </table>

          <h6 className="mb-3">Kopiereinstellungen</h6>
          <div className="row lp21-copysettings">
            <div className="col-6">
              <FormControlWithLabel controlId="chkCopyAssessmentSchemaLevels" controlType="checkbox" useFloatingLabel={true} label="Bewertungen kopieren" value={copyLevels} onChange={(value: any) => setCopyLevels(value === "on")} />
              <FormControlWithLabel controlId="chkCopyRemarks" controlType="checkbox" useFloatingLabel={true} label="Texte kopieren" value={copyRemarks} onChange={(value: any) => setCopyRemarks(value === "on")} />
              <FormControlWithLabel controlId="chkCopyImages" controlType="checkbox" useFloatingLabel={true} label="Bilder kopieren" value={copyImages} onChange={(value: any) => setCopyImages(value === "on")} />
            </div>
            <FormControlWithLabel className="col-6" controlId="chkOverwrite" controlType="checkbox" useFloatingLabel={true} label="Überschreiben" value={overwrite} onChange={(value: any) => setOverwrite(value === "on")} />
          </div>
          <SaveAndCancelOrCloseButtons isReadOnly={props.isReadOnly} isDisabled={!sameLehrplan || (!copyLevels && !copyRemarks && !copyImages)} submitLabel="Kopieren" onCancelHandler={onCancelHandler} />

        </div>
      </div>
    </form>
  );
};

AssessmentCopyPage.propTypes = {
  isReadOnly: PropTypes.bool.isRequired
};

export default AssessmentCopyPage;

