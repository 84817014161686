import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import AddLinkButton from "../../../../components/AddLinkButton";
import EditAndDeleteLinkButtons from "../../../../components/EditAndDeleteLinkButtons";
import Filter from "../../../../components/Filter";
import SortButton from "../../../../components/SortButton";
import { sortObjects } from "../../helper";

export function FamilyUsersPage() {

  // Hooks
  const navigate = useNavigate();

  // Global State
  const { globalState } = useGlobalState();

  // State
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [sortKey, setSortKey] = useState("lastName");
  const [sortAscending, setSortAscending] = useState(true);
  const [showDeleted, setShowDeleted] = useState(false);

  // Mount
  useEffect(() => {
    fetchItems(showDeleted);
  }, []);

  // Event handlers
  const onFilterHandler = function (value: any) {
    setFilterText(value);
    applyFilter(items, value, sortKey, sortAscending);
  }

  const onShowDeletedToggleHandler = function (includeDeleted: boolean) {
    setShowDeleted(includeDeleted);
    fetchItems(includeDeleted);
  }

  const onSortHandler = function (key: string, ascending: boolean) {
    setSortKey(key);
    setSortAscending(ascending);
    applyFilter(items, filterText, key, ascending);
  }


  const onAddClickHandler = function () {
    navigate("/app/admin/familyusers/new");
  }

  const onEditClickHandler = function (id: string) {
    navigate(`/app/admin/familyusers/${id}`);
  }

  const onDeleteClickHandler = function (id: string, name: string) {

    if (!window.confirm(`Möchten Sie den Benutzer '${name}' wirklich löschen?`)) {
      return;
    }

    deleteItemApi(globalState.apiBaseUrl, id)
      .then(() => {
        fetchItems(showDeleted);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
      })
  };

  // Functions
  const fetchItems = function (includeDeleted: boolean) {
    fetchItemsApi(globalState.apiBaseUrl, includeDeleted)
      .then((result: any) => {
        setItems(result);
        applyFilter(result, filterText, sortKey, sortAscending);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
      });
  };

  const applyFilter = function (loadedItems: any, text: any, sortKey: string, sortAscending: boolean) {

    const sorted = loadedItems.sort((a: any, b: any) => sortObjects(sortKey, sortAscending, a, b));

    if (!text || text.length === 0) {
      setFilteredItems(sorted);
      return;
    }

    const searchText = text.toLowerCase();
    const res = sorted.filter((i: any) => {
      if (i.familyName && i.familyName.toLowerCase().indexOf(searchText) !== -1) {
        return true;
      }
      else if (i.userName && i.userName.toLowerCase().indexOf(searchText) !== -1) {
        return true;
      }
      else { return false; }
    });

    setFilteredItems(res);
  };

  // Web api calls
  const fetchItemsApi = async function (apiBaseUrl: string | undefined, includeDeleted: boolean) {
    return request(`${apiBaseUrl}/familyuser?includeDeleted=${includeDeleted}`, globalState.authToken);
  }
  const deleteItemApi = async function (apiBaseUrl: string | undefined, id: string) {
    const options = { method: "DELETE" };
    return request(`${apiBaseUrl}/familyuser/${id}`, globalState.authToken, options);
  }

  return (
    <div className="card">

      <div className="d-flex flex-column rounded py-8 px-5">
        <p>Mit der Berechtiungsvergabe wird ein Benutzer einer Familie zugewiesen, sowie eine Rolle vergeben:</p>
        <ul>
          <li><b>User</b>: Standardbenutzer für Familien</li>
          <li><b>Coach</b>: Benutzer mit Lesezugriff</li>
          <li><b>Admin</b>: Zugriff auf die eigene Familie, aber auch auf die Administration der Applikation</li>
        </ul>
      </div>

      <Filter onFilter={onFilterHandler} onShowDeletedToggle={onShowDeletedToggleHandler} />

      <table className="table table-rounded table-striped border gy-7 gs-7">
        <thead>
          <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
            <th className="lp21-command"><AddLinkButton onAddClick={onAddClickHandler} /></th>
            <th>Familie <SortButton sortKey="familyName" activeSortKey={sortKey} activeSortAscending={sortAscending} onSort={onSortHandler} /></th>
            <th>Benutzername <SortButton sortKey="userName" activeSortKey={sortKey} activeSortAscending={sortAscending} onSort={onSortHandler} /></th>
            <th>Rolle <SortButton sortKey="userRoleName" activeSortKey={sortKey} activeSortAscending={sortAscending} onSort={onSortHandler} /></th>
          </tr>
        </thead>
        <tbody>
          {filteredItems && filteredItems.map((item: any) => <tr key={item.id}>
            <td className="lp21-command"><EditAndDeleteLinkButtons id={item.id} name={item.email ?? ""} isDeleted={item.deleted} onEditClick={onEditClickHandler} onDeleteClick={onDeleteClickHandler} /></td>
            <td>{item.familyName}</td>
            <td>{item.userName}</td>
            <td>{item.userRoleName}</td>
          </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FamilyUsersPage;