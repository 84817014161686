import PropTypes, { InferProps } from "prop-types";
import React, { ChangeEventHandler, Fragment } from "react";
import "./LinkButtons.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from 'date-fns/locale/de';
import HtmlEditor from "./HtmlEditor";
import * as ReactDOMServer from 'react-dom/server';

registerLocale('de', de);


export function FormControlWithLabel(props: InferProps<typeof FormControlWithLabel.propTypes>) {

  const onChangeHandler = function (e: any) {
    props.onChange(e.target.value);
  }

  const onHtmlEditorChangeHandler = function (value: any) {
    props.onChange(value);
  }

  const onCheckboxChangeHandler = function (e: any) {
    if (e.target.checked) {
      props.onChange(e.target.value);
    } else {
      props.onChange("");
    }
  }

  const onCheckboxListChangeHandler = function (e: any) {
    var checkboxName = e.target.name;
    var checkboxes = document.getElementsByName(checkboxName);
    var values: string[] = [];
    checkboxes.forEach((checkbox: any) => {
      if (checkbox.checked) {
        values.push(checkbox.value);
      }
    });

    values.join(",");

    props.onChange(values.join(","));
  }

  const onDateChangeHandler = function (dateTime: any) {
    var dateOnlyString = dateTime.getFullYear()
      + '-' + ('0' + (dateTime.getMonth() + 1)).slice(-2)
      + '-' + ('0' + (dateTime.getDate())).slice(-2)
      + "T00:00:00Z";
    props.onChange(dateOnlyString);
  }

  const renderOptions = function (options: any) {
    return options && options.map((o: any) => {
      let styleAttribute = {};
      if (o.image) {
        styleAttribute = { backgroundImage: `url('${o.image}')` };
      }
      return <option key={o.id} value={o.id} style={styleAttribute}>{o.name}</option>;
    });
  }

  const groupedOptions = {};

  const renderRadioButtons = function (o: any, isChecked: boolean, type: string, onChangeHandler: ChangeEventHandler<HTMLInputElement>) {
    const radioId = `${props.controlId}_${o.id}`;
    return <div key={o.id} className="form-check">
      {isChecked && <input className="form-check-input" type={type} name={props.controlId} id={radioId} value={o.id} defaultChecked onChange={onChangeHandler} />}
      {!isChecked && <input className="form-check-input" type={type} name={props.controlId} id={radioId} value={o.id} onChange={onChangeHandler} />}
      <label className="form-check-label" htmlFor={radioId}>
        {o.image && o.name !== "N/A" && <img src={o.image} alt={o.name} title={o.name} />}
        {!(o.image && o.name !== "N/A") && o.name}
      </label>
    </div>;
  }

  let { value } = props;
  if (!value) {
    value = "";
  }

  let dateValue = value && value !== "" ? new Date(value) : null;

  let control = null;
  switch (props.controlType) {
    case "label":
      control = <div id={props.controlId} className="lp21-label">{value}</div>;
      break;

    case "textarea":
      control = <textarea className="form-control" id={props.controlId} rows={props.rows ?? 0} value={value} required={props.isRequired ?? false} disabled={props.isDisabled ?? false} onChange={onChangeHandler} />;
      break;

    case "html":
      control = <HtmlEditor onChange={onHtmlEditorChangeHandler} value={value} init={props.htmlEditorInit} disabled={props.isDisabled} />
      break;


    case "select":
      props.options && props.options.forEach(o => {
        if (!groupedOptions[o.group]) {
          groupedOptions[o.group] = [];
        }
        groupedOptions[o.group].push({
          id: o.id,
          name: o.name,
        });
      });

      // Has multiple groups?
      if (groupedOptions && (Object.keys(groupedOptions)).length > 1) {
        control = <select className={`form-select ${props.inputClassName}`} id={props.controlId} value={value} onChange={onChangeHandler} required={props.isRequired ?? false} disabled={props.isDisabled ?? false}>
          <option key={null}></option>
          {
            Object.keys(groupedOptions).map((g, i) => {
              return (<optgroup key={i} label={g}>
                {renderOptions(groupedOptions[g])}
              </optgroup>
              );
            })
          }
        </select>;
      } else {
        control = <select className={`form-select ${props.inputClassName}`} id={props.controlId} value={value} onChange={onChangeHandler} required={props.isRequired ?? false} disabled={props.isDisabled ?? false}>
          <option key={null}></option>
          {renderOptions(props.options)}
        </select>;
      }
      break;

    case "radio":
      control = <div className="lp21-form-check-outer">
        {props.options && props.options.map(o => {
          return (renderRadioButtons(o, value === o.id, "radio", onChangeHandler));
        })}
        <div className="lp21-form-check-last" />
      </div>;
      break;

    case "checkboxlist":
      control = <div className="lp21-form-check-outer">
        {props.options && props.options.map(o => {
          return (renderRadioButtons(o, value === o.id || o.id !== "" && value.includes(o.id), "checkbox", onCheckboxListChangeHandler));
        })}
        <div className="lp21-form-check-last" />
      </div>;
      break;

    case "number":
      control = <input type={props.controlType} className="form-control" id={props.controlId} value={value} onChange={onChangeHandler} required={props.isRequired ?? false} step={props.step ?? ""} min={props.min ?? ""} max={props.max ?? ""} />;
      break;

    case "checkbox":
      const isChecked = props.value === true;
      return <div className={`${props.className ? props.className : ""} mb-10`}>
        {!props.useFloatingLabel && <label className="form-check-label" htmlFor={props.controlId}>{props.label}</label>}
        <div className="form-check form-switch form-check-custom form-check-solid">
          {isChecked && <input type="checkbox" className={`form-check-input ${props.inputClassName}`} id={props.controlId} defaultChecked disabled={props.isDisabled ?? false} onChange={onCheckboxChangeHandler} />}
          {!isChecked && <input type="checkbox" className={`form-check-input ${props.inputClassName}`} id={props.controlId} disabled={props.isDisabled ?? false} onChange={onCheckboxChangeHandler} />}
          {props.useFloatingLabel && <label className="form-check-label" htmlFor={props.controlId}>{props.label}</label>}
        </div>
      </div>;

    case "search":
      control = <input type={props.controlType} className={`form-control ${props.inputClassName}`} id={props.controlId} value={value} onChange={onChangeHandler} required={props.isRequired ?? false} />;
      break;

    case "date":
      control = <DatePicker
        selected={dateValue}
        dateFormat="dd.MM.yyyy"
        locale="de"
        className="form-control"
        onChange={(date) => onDateChangeHandler(date)}
        required={props.isRequired ?? false}
        disabled={props.isDisabled ?? false}
      />
      break;

    case "month":
      control = <DatePicker
        selected={dateValue}
        dateFormat="MMMM yyyy"
        locale="de"
        showMonthYearPicker={true}
        showYearDropdown={true}
        className="form-control"
        onChange={(date) => onDateChangeHandler(date)}
        required={props.isRequired ?? false}
        disabled={props.isDisabled ?? false}
      />
      break;

    default:
      control = <input type={props.controlType} className={`form-control ${props.inputClassName}`} id={props.controlId} value={value} onChange={onChangeHandler} required={props.isRequired ?? false} disabled={props.isDisabled ?? false} />;
      break;
  }

  const renderTooltip = function (tooltipJsx: any) {

    if (!tooltipJsx) {
      return <></>;
    }

    return <span data-tooltip-id="tooltip-global-light" data-tooltip-place="right" data-tooltip-html={ReactDOMServer.renderToString(<div className="pt-4">{tooltipJsx}</div>)}>
      <a href="#"><i className="bi bi-lightbulb fs-3 text-primary"></i></a>
    </span>
  }

  return <div className={`${props.className ? props.className : ""} ${!props.noBottomMargin ? "mb-10" : ""}`}>
    {props.useFloatingLabel && <div className="form-floating">
      {control}
      <label htmlFor={props.controlId}>{props.label} {props.isRequired && "*"}</label>
    </div>}
    {!props.useFloatingLabel &&
      <Fragment>
        <label className="form-label" htmlFor={props.controlId} hidden={!props.label || props.label === ""}>{props.label} {props.isRequired && "*"} {renderTooltip(props.children)}</label>
        {control}
      </Fragment>
    }
  </div>
}

FormControlWithLabel.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  controlId: PropTypes.string.isRequired,
  controlType: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  useFloatingLabel: PropTypes.bool,
  noBottomMargin: PropTypes.bool,

  children: PropTypes.any,

  // Textarea
  rows: PropTypes.number,

  // Html editor
  htmlEditorInit: PropTypes.any,

  // Select
  options: PropTypes.array,

  // Number
  min: PropTypes.string,
  max: PropTypes.string,
  step: PropTypes.string,

  // Events
  onChange: PropTypes.func.isRequired,
};

export default FormControlWithLabel;