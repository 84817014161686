import PropTypes, { InferProps } from "prop-types";
import React, { Fragment } from "react";

export function SaveAndCancelOrCloseButtons(props: InferProps<typeof SaveAndCancelOrCloseButtons.propTypes>) {

  return <div className="lp21-buttons">
    {!props.isReadOnly &&
      <Fragment>
        <button type="submit" disabled={props.isDisabled ?? false} className="btn btn-primary">{props.submitLabel ?? "Speichern"}</button>
        <button type="button" className="btn btn-secondary" onClick={props.onCancelHandler}>{props.cancelLabel ?? "Abbrechen"}</button>
      </Fragment>}
    {props.isReadOnly && !(props.hideCloseButton ?? false) &&
      <button type="button" className="btn btn-secondary" onClick={props.onCancelHandler}>{props.closeLabel ?? "Zurück"}</button>}
  </div>;
}

SaveAndCancelOrCloseButtons.propTypes = {
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  isReadOnly: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  onCancelHandler: PropTypes.func.isRequired
};

export default SaveAndCancelOrCloseButtons;