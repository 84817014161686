import React from "react";
import PropTypes, { InferProps } from "prop-types";
import "./Styles.css";

export function Page(props: InferProps<typeof Page.propTypes>) {
    return <div className="lp21-reporting-page">
        <>
            {props.children}
        </>
    </div>
}

Page.propTypes = {
    children: PropTypes.node,
};

export default Page;