/* eslint-disable jsx-a11y/anchor-is-valid */
import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import { isGuid } from "../../helper";
import FormControlWithLabel from "../../../../components/FormControlWithLabel";
import SaveAndCancelOrCloseButtons from "../../../../components/SaveAndCancelOrCloseButtons";
import PropTypes, { InferProps } from "prop-types";

export function FamilyPage(props: InferProps<typeof FamilyPage.propTypes>) {

  // Hooks
  const navigate = useNavigate();

  // Global State
  const { globalState } = useGlobalState();

  // State
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [createdOn, setCreatedOn] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [modifiedOn, setModifiedOn] = useState(null);
  const [modifiedBy, setModifiedBy] = useState(null);

  // Fixed params
  //const { id } = useParams<{ id: string }>();
  const id = globalState.familyId ?? "";

  // Mount
  useEffect(() => {

    // Lookups

    // New item
    if (!isGuid(id)) {
      return;
    }

    fetchItem();
  }, []);

  // Event handlers
  const onSubmitHandler = function (e: any) {
    e.preventDefault();
    saveItemApi(globalState.apiBaseUrl, {
      familyId: globalState.familyId,
      lastName: lastName,
      address: address,
      phone: phone,
    })
      .then(() => {
        navigate("/app/settings/family");
        fetchItem();
      })
      .catch((ex: any) => {
        if (ex && ex.response && ex.response.details) {
          alert(`Error: ${ex})\n\n${ex.response.details}`);
        } else {
          alert(`Error: ${ex})`);
        }
      });
  }

  const onCancelHandler = function () {
    navigate("/app/settings/family");
    fetchItem();
  }

  // Functions
  const fetchItem = function () {
    fetchItemApi(globalState.apiBaseUrl)
      .then((result: any) => {
        setLastName(result.lastName);
        setAddress(result.address);
        setPhone(result.phone);
        setCreatedOn(result.createdOn);
        setCreatedBy(result.createdBy);
        setModifiedOn(result.modifiedOn);
        setModifiedBy(result.modifiedBy);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex})`);
      });
  }

  // Web api calls
  const fetchItemApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/family/${id}`, globalState.authToken);
  }
  const saveItemApi = async function (apiBaseUrl: string | undefined, data: any) {
    if (!isGuid(id)) {
      // Create
      const options = { method: "POST", payload: data, };
      return request(`${apiBaseUrl}/family`, globalState.authToken, options);
    }
    else {
      // Update
      const options = { method: "PUT", payload: data, };
      return request(`${apiBaseUrl}/family/${id}`, globalState.authToken, options);
    }
  }

  return (
    <form onSubmit={onSubmitHandler}>
      <div className="card p-10">
        <div className="row">
          <FormControlWithLabel className="col-6" controlId="txtLastName" controlType="text" label="Nachname" value={lastName} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setLastName(value)} />
          <FormControlWithLabel className="col-6" controlId="txtPhone" controlType="text" label="Telefon" value={phone} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setPhone(value)} />
          <FormControlWithLabel className="col-12" controlId="txtAddress" controlType="textarea" label="Adresse (Strasse, PLZ Ort Kanton)" value={address} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setAddress(value)} />
        </div>
        <SaveAndCancelOrCloseButtons isReadOnly={props.isReadOnly} onCancelHandler={onCancelHandler} submitLabel="Übernehmen" cancelLabel="Zurücksetzen" hideCloseButton={true} />
      </div>
    </form>
  );
};

FamilyPage.propTypes = {
  isReadOnly: PropTypes.bool.isRequired
};

export default FamilyPage;