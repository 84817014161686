import PropTypes, { InferProps } from "prop-types";
import React from "react";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain(props: InferProps<typeof AsideMenuMain.propTypes>) {

  return (
    <>
      {" "}
      <>
        <>
          <AsideMenuItem to="/app/dashboard" title="Start" />
        </>
        <>
          <div className="menu-item mt-10">
            <h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
              Lernfortschritt
            </h4>
          </div>
          <AsideMenuItem to="/app/assessments" title="Lernstandserfassung" />
          <AsideMenuItem to="/app/plans" title="Planung" />
          <AsideMenuItem to="/app/browse" title="Lehrplan" />
                  {/* <AsideMenuItem to="/app/browse/table" title="Lehrplan (tabellarisch)" /> */}
        </>

        <>
          <div className="menu-item mt-10">
            <h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
              Grundeinstellungen
            </h4>
          </div>
          <AsideMenuItem to="/app/settings/childgradelevels" title="Schuljahre" />
          <AsideMenuItem to="/app/settings/children" title="Kinder" />
          <AsideMenuItem to="/app/settings/family" title="Familie" />
          <AsideMenuItem to="/app/settings/user" title="Benutzer" />
        </>

        {props.isAdmin === true &&
          <>
            <div className="menu-item mt-10">
              <h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
                Administration
              </h4>
            </div>
            <AsideMenuItem to="/app/admin/families" title="Familien" />
            <AsideMenuItem to="/app/admin/users" title="Benutzer" />
            <AsideMenuItem to="/app/admin/familyusers" title="Berechtigungen" />
            {/*           <AsideMenuItem to="/admin/jobs" title="Aufträge" />
 */}        </>}
        {/* 
        <>
          <div className="menu-item mt-10">
            <h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
              Resources
            </h4>
          </div>
          <AsideMenuItem to="/auth/login" title="Login" />
          <AsideMenuItem to="/error/404" title="Error" />
        </> */}
      </>
    </>
  );
}



AsideMenuMain.propTypes = {
  isAdmin: PropTypes.bool.isRequired
};

export default AsideMenuMain;