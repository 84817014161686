export interface IFilterModel {
    lehrplanId: string | null;
    fachbereichId: string | null;
    fachId: string | null;
    text: string | null;
    istGrundanspruch: string | null;
    zyklusCodes: string | null;
    levelUntil: string | null;

    assessmentId: string | null;
    assessmentSchemaLevelUntil: string | null;
    planId: string | null;
    isPlanned: boolean | null;
    hasRemarks: boolean | null;

    toUrlQueryParams: Function;
}

export class FilterModel implements IFilterModel {
    constructor(filter: IFilterModel) {
        this.lehrplanId = filter.lehrplanId;
        this.fachbereichId = filter.fachbereichId;
        this.fachId = filter.fachId;
        this.text = filter.text;
        this.istGrundanspruch = filter.istGrundanspruch;
        this.zyklusCodes = filter.zyklusCodes;
        this.levelUntil = filter.levelUntil;

        this.assessmentId = filter.assessmentId;
        this.assessmentSchemaLevelUntil = filter.assessmentSchemaLevelUntil;
        this.planId = filter.planId;
        this.isPlanned = filter.isPlanned;
        this.hasRemarks = filter.hasRemarks;
    }

    lehrplanId: string | null;
    fachbereichId: string | null;
    fachId: string | null;
    text: string | null;
    istGrundanspruch: string | null;
    zyklusCodes: string | null;
    levelUntil: string | null;

    assessmentId: string | null;
    assessmentSchemaLevelUntil: string | null;
    planId: string | null;
    isPlanned: boolean | null;
    hasRemarks: boolean | null;

    toUrlQueryParams = () => {

        var params = new URLSearchParams();

        if (this.lehrplanId) { params.append("lehrplanId", this.lehrplanId) }
        if (this.fachbereichId) { params.append("fachbereichId", this.fachbereichId) }
        if (this.fachId) { params.append("fachId", this.fachId) }
        if (this.istGrundanspruch) { params.append("istGrundanspruch", this.istGrundanspruch) }
        if (this.zyklusCodes) { params.append("zyklusCodes", this.zyklusCodes) }
        if (this.text) { params.append("text", this.text.trim()) }
        if (this.levelUntil) { params.append("levelUntil", this.levelUntil) }
        
        if (this.assessmentId) { params.append("assessmentId", this.assessmentId) }
        if (this.assessmentSchemaLevelUntil) { params.append("assessmentSchemaLevelUntil", this.assessmentSchemaLevelUntil) }
        if (this.planId) { params.append("planId", this.planId) }
        if (this.isPlanned) { params.append("isPlanned", this.isPlanned ? "true": "") }
        if (this.hasRemarks) { params.append("hasRemarks", this.hasRemarks ? "true": "") }

        return params.toString();
    }
}