/* eslint-disable jsx-a11y/anchor-is-valid */
import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import { isGuid } from "../../helper";
import FormControlWithLabel from "../../../../components/FormControlWithLabel";
import PropTypes, { InferProps } from "prop-types";
import SaveAndCancelOrCloseButtons from "../../../../components/SaveAndCancelOrCloseButtons";

export function ChildGradeLevelPage(props: InferProps<typeof ChildGradeLevelPage.propTypes>) {

    // Hooks
    const navigate = useNavigate();

    // Global State
    const { globalState, } = useGlobalState();

    // State
    const [children, setChildren] = useState([]);
    const [schuljahre, setSchuljahre] = useState([]);
    const [lehrplaene, setLehrplaene] = useState([]);
    const [childId, setChildId] = useState(null);
    const [schuljahrId, setSchuljahrId] = useState(null);
    const [lehrplanId, setLehrplanId] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    // Route params
    const { id } = useParams<{ id: string }>();

    // Mount
    useEffect(() => {

        // Lookups
        fetchChildrenApi(globalState.apiBaseUrl).then((result: any) => { setChildren(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
        fetchSchuljahreApi(globalState.apiBaseUrl).then((result: any) => { setSchuljahre(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
        fetchLehrplaeneApi(globalState.apiBaseUrl).then((result: any) => { setLehrplaene(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });

        // New item
        if (!isGuid(id)) {
            return;
        }

        fetchItemApi(globalState.apiBaseUrl)
            .then((result: any) => {
                setChildId(result.childId);
                setSchuljahrId(result.schuljahrId);
                setLehrplanId(result.lehrplanId);
                setFromDate(result.fromDate);
                setToDate(result.toDate);
            })
            .catch((ex: any) => {
                alert(`Error: ${ex})`);
            });


    }, []);

    // Event handlers
    const onSubmitHandler = function (e: any) {
        e.preventDefault();
        saveItemApi(globalState.apiBaseUrl, {
            childId: childId,
            schuljahrId: schuljahrId,
            lehrplanId: lehrplanId,
            fromDate: fromDate,
            toDate: toDate,
        })
            .then(() => {
                navigate("/app/settings/childgradelevels");
            })
            .catch((ex: any) => {
                if (ex && ex.response && ex.response.details) {
                    alert(`Error: ${ex})\n\n${ex.response.details}`);
                } else {
                    alert(`Error: ${ex})`);
                }
            });
    }

    const onCancelHandler = function () {
        navigate("/app/settings/childgradelevels");
    }

    // Web api calls
    const fetchChildrenApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/child?familyId=${globalState.familyId}`, globalState.authToken);
    }
    const fetchSchuljahreApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/lehrplan21/schuljahr`, globalState.authToken);
    }
    const fetchLehrplaeneApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/lehrplan21/lehrplan`, globalState.authToken);
    }
    const fetchItemApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/childgradelevel/${id}`, globalState.authToken);
    }
    const saveItemApi = async function (apiBaseUrl: string | undefined, data: any) {
        if (!isGuid(id)) {
            // Create
            const options = { method: "POST", payload: data, };
            return request(`${apiBaseUrl}/childgradelevel`, globalState.authToken, options);
        }
        else {
            // Update
            const options = { method: "PUT", payload: data, };
            return request(`${apiBaseUrl}/childgradelevel/${id}`, globalState.authToken, options);
        }
    }

    return (
        <form onSubmit={onSubmitHandler}>
            <div className="card p-10">
                <div className="row">
                    <FormControlWithLabel className="col-6" controlId="lstChild" controlType="select" label="Kind" options={children} value={childId} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setChildId(value)} />
                    <FormControlWithLabel className="col-6" controlId="lstSchuljahr" controlType="select" label="Schuljahr" options={schuljahre} value={schuljahrId} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setSchuljahrId(value)} />
                    <FormControlWithLabel className="col-6" controlId="lstLehrplan" controlType="select" label="Lehrplan" options={lehrplaene} value={lehrplanId} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setLehrplanId(value)} />
                </div>
                <div className="row">
                    <FormControlWithLabel className="col-6" controlId="dtFromDate" controlType="month" label="Von" value={fromDate} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setFromDate(value)} />
                    <FormControlWithLabel className="col-6" controlId="dtToDate" controlType="month" label="Bis" value={toDate} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setToDate(value)} />
                </div>
                <SaveAndCancelOrCloseButtons isReadOnly={props.isReadOnly} onCancelHandler={onCancelHandler} />
            </div>
        </form>
    );
};

ChildGradeLevelPage.propTypes = {
    isReadOnly: PropTypes.bool.isRequired
};

export default ChildGradeLevelPage;