import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../_start/layout/MasterLayout";
import { Logout } from "./modules/auth/Logout";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { ErrorsPage } from "./modules/errors/ErrorsPage";
import { useGlobalState } from "./GlobalStateProvider";
import { AuthPage } from "./modules/auth/AuthPage";

export function AppInner() {

  // Global State
  const { globalState } = useGlobalState();

  // State
  const [isAuthorized, setIsAuthorized] = useState(false);

  // Mount
  useEffect(() => {

    if (globalState.authToken && globalState.authToken !== "") {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }

  }, [globalState]);

  return <Routes>
    <Route path="/error" element={<ErrorsPage />} />
    <Route path="/logout" element={<Logout />} />
    {!isAuthorized ? (
      <>
        {/* Login */}
        <Route path="/auth/*" element={<AuthPage />} />

        {/* Fallback */}
        <Route path="*" element={<Navigate to="/auth" />} />
      </>
    ) : (
      <Route path="*" element={
        <MasterLayout isAdmin={globalState.isAdmin ?? false}>
          <PrivateRoutes />
        </MasterLayout>} />
    )}
  </Routes>;
}

export default AppInner;