import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import AddLinkButton from "../../../../components/AddLinkButton";
import EditAndDeleteLinkButtons from "../../../../components/EditAndDeleteLinkButtons";
import Filter from "../../../../components/Filter";
import SortButton from "../../../../components/SortButton";
import { sortObjects } from "../../helper";

export function UsersPage() {

  // Hooks
  const navigate = useNavigate();

  // Global State
  const { globalState } = useGlobalState();

  // State
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [sortKey, setSortKey] = useState("fullName");
  const [sortAscending, setSortAscending] = useState(true);
  const [showDeleted, setShowDeleted] = useState(false);

  // Mount
  useEffect(() => {
    fetchItems(showDeleted);
  }, []);

  // Event handlers 
  const onFilterHandler = function (value: any) {
    setFilterText(value);
    applyFilter(items, value, sortKey, sortAscending);
  }

  const onShowDeletedToggleHandler = function (includeDeleted: boolean) {
    setShowDeleted(includeDeleted);
    fetchItems(includeDeleted);
  }

  const onSortHandler = function (key: string, ascending: boolean) {
    setSortKey(key);
    setSortAscending(ascending);
    applyFilter(items, filterText, key, ascending);
  }


  const onAddClickHandler = function () {
    navigate("/app/admin/users/new");
  }

  const onEditClickHandler = function (id: string) {
    navigate(`/app/admin/users/${id}`);
  }

  const onDeleteClickHandler = function (id: string, name: string) {

    if (!window.confirm(`Möchten Sie den Benutzer '${name}' wirklich löschen?`)) {
      return;
    }

    deleteItemApi(globalState.apiBaseUrl, id)
      .then(() => {
        fetchItems(showDeleted);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
      })
  };

  // Functions
  const fetchItems = function (includeDeleted: boolean) {
    fetchItemsApi(globalState.apiBaseUrl, includeDeleted)
      .then((result: any) => {
        setItems(result);
        applyFilter(result, filterText, sortKey, sortAscending);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
      });
  };

  const applyFilter = function (loadedItems: any, text: any, sortKey: string, sortAscending: boolean) {

    const sorted = loadedItems.sort((a: any, b: any) => sortObjects(sortKey, sortAscending, a, b));

    if (!text || text.length === 0) {
      setFilteredItems(sorted);
      return;
    }

    const searchText = text.toLowerCase();
    const res = sorted.filter((i: any) => {
      if (i.fullName && i.fullName.toLowerCase().indexOf(searchText) !== -1) {
        return true;
      }
      else if (i.email && i.email.toLowerCase().indexOf(searchText) !== -1) {
        return true;
      }
      else { return false; }
    });

    setFilteredItems(res);
  };

  // Web api calls
  const fetchItemsApi = async function (apiBaseUrl: string | undefined, includeDeleted: boolean) {
    return request(`${apiBaseUrl}/user?includeDeleted=${includeDeleted}`, globalState.authToken);
  }
  const deleteItemApi = async function (apiBaseUrl: string | undefined, id: string) {
    const options = { method: "DELETE" };
    return request(`${apiBaseUrl}/user/${id}`, globalState.authToken, options);
  }

  return (

    <div className="card">
      <Filter showDeletedToggleLabel="inaktive Benutzer anzeigen" onFilter={onFilterHandler} onShowDeletedToggle={onShowDeletedToggleHandler} />
      <table className="table table-rounded table-striped border gy-7 gs-7">
        <thead>
          <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
            <th className="lp21-command"><AddLinkButton onAddClick={onAddClickHandler} /></th>
            <th>Nachname, Vorname <SortButton sortKey="fullName" activeSortKey={sortKey} activeSortAscending={sortAscending} onSort={onSortHandler} /></th>
            <th>Benutzername <SortButton sortKey="email" activeSortKey={sortKey} activeSortAscending={sortAscending} onSort={onSortHandler} /></th>
            <th>Aktiv {showDeleted && <SortButton sortKey="isActive" activeSortKey={sortKey} activeSortAscending={sortAscending} onSort={onSortHandler} />}</th>
            <th>Gesperrt {<SortButton sortKey="isLocked" activeSortKey={sortKey} activeSortAscending={sortAscending} onSort={onSortHandler} />}</th>
            <th>Erstellt am <SortButton sortKey="createdOn" initialSortAscending={false} activeSortKey={sortKey} activeSortAscending={sortAscending} onSort={onSortHandler} /></th>
            <th>Geändert am <SortButton sortKey="modifiedOn" initialSortAscending={false} activeSortKey={sortKey} activeSortAscending={sortAscending} onSort={onSortHandler} /></th>
            <th>Letztes Login am <SortButton sortKey="lastLoginDate" initialSortAscending={false} activeSortKey={sortKey} activeSortAscending={sortAscending} onSort={onSortHandler} /></th>
            <th>Logins <SortButton sortKey="loginCount" initialSortAscending={false} activeSortKey={sortKey} activeSortAscending={sortAscending} onSort={onSortHandler} /></th>
          </tr>
        </thead>
        <tbody>
          {filteredItems && filteredItems.map((item: any) => <tr key={item.id}>
            <td className="lp21-command"><EditAndDeleteLinkButtons id={item.id} name={item.email} isDeleted={item.deleted} onEditClick={onEditClickHandler} onDeleteClick={onDeleteClickHandler} /></td>
            <td>{item.fullName}</td>
            <td>{item.email}
              {item.isEmailConfirmed ?
                <span className="lp21-icon"><i className="bi bi-check-circle-fill text-success" title="E-Mail verifiziert"></i></span>
                : <span className="lp21-icon"><i className="bi bi-question-circle-fill text-warning" title="E-Mail nicht verifiziert"></i></span>
              }</td>
            <td>{item.isActive ? <i className="bi bi-check fs-2x"></i> : ""}</td>
            <td>{item.isLocked ? <i className="bi bi-check fs-2x" title={item.lockedUntil ? "Automatisch gesperrt bis " + new Date(item.lockedUntil).toLocaleDateString("de-CH") + " " + new Date(item.lockedUntil).toLocaleTimeString("de-CH") : "Gesperrt"}></i> : ""}</td>
            <td>{item.createdOn ? new Date(item.createdOn).toLocaleDateString("de-CH") + " " + new Date(item.createdOn).toLocaleTimeString("de-CH") : ""}</td>
            <td>{item.modifiedOn ? new Date(item.modifiedOn).toLocaleDateString("de-CH") + " " + new Date(item.modifiedOn).toLocaleTimeString("de-CH") : ""}</td>
            <td>{item.lastLoginDate ? new Date(item.lastLoginDate).toLocaleDateString("de-CH") + " " + new Date(item.lastLoginDate).toLocaleTimeString("de-CH") : ""}</td>
            <td>{item.loginCount}</td>
          </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UsersPage;