import React, { useEffect } from "react";
import { Navigate, Route, Routes, Link } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_start/helpers";
import { ConfirmEmail } from "./components/ConfirmEmail";
import { ResetPassword } from "./components/ResetPassword";
import { ChangeEmail } from "./components/ChangeEmail";

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-column flex-lg-row flex-column-fluid"
        id="kt_login"
      >
        {/* Aside */}
        <div className="d-flex flex-column flex-lg-row-auto bg-primary w-lg-600px d-none d-lg-flex pt-lg-0">
          {/* Top */}
          {/* <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 text-center">
            <Link to="/" className="mb-6">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/logo-default.svg")}
                className="h-75px"
              />
            </Link>

            <h3 className="fw-bolder fs-2x text-white lh-lg">
              Planen und beurteilen
              <br />
              einfach gemacht
            </h3>
          </div> */}

          {/* Bottom */}
          <div
            className="d-flex flex-row-fluid bgi-size-cover bgi-no-repeat bgi-position-y-center bgi-position-x-center min-h-350px"
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/title.jpg"
              )})`,
            }}
          ></div>
        </div>

        {/* Content */}
        <div className="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative justify-content-center w-100">
          <div className="overflow-hidden pt-20 px-10 p-lg-7 mx-auto w-100">
            <div className="text-center">
              <Link to="/" className="mb-6">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/Logo_Lp21.svg")}
                  className="h-250px"
                />
              </Link>
            </div>

            <div className="d-flex flex-column-fluid flex-center pt-20">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/change" element={<ChangeEmail />} />
                <Route path="/confirm" element={<ConfirmEmail />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset" element={<ResetPassword />} />
                <Route path="*" element={<Navigate to="/auth/login" />} />
              </Routes>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center py-7 py-lg-0">
            <a href="https://lp21-tool.ch/angebot" target="_blank" className="text-primary fw-bolder fs-4 cursor-pointer">Angebot</a>
            <a href="https://lp21-tool.ch/kurse" target="_blank" className="text-primary ms-10 fw-bolder fs-4">Kurse</a>
            <a href="https://lp21-tool.ch/ueber-uns" target="_blank" className="text-primary ms-10 fw-bolder fs-4">Über uns</a>
            <a href="https://hilfe.lp21-tool.ch" target="_blank" className="text-primary ms-10 fw-bolder fs-4">FAQ</a>
          </div>

          <div className="footer py-4 d-flex flex-lg-column mt-auto" id="kt_footer">
            <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
              <div className="text-dark order-2 order-md-1"><span className="text-muted fw-bold me-2">{new Date().getFullYear()} &copy;</span><a href="#" className="text-gray-800 text-hover-primary">LP21-Tool</a></div>
              <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                <li className="menu-item"><a href="https://lp21-tool.ch/agb" target="_blank" className="menu-link pe-2 pe-2">AGB</a></li>
                <li className="menu-item"><a href="https://lp21-tool.ch/impressum" target="_blank" className="menu-link pe-2 pe-2">Impressum</a></li>
                <li className="menu-item"><a href="https://lp21-tool.ch/datenschutz" target="_blank" className="menu-link pe-2 pe-2">Datenschutzerklärung</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
