import React, { Fragment, useEffect, useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import "./Lehrplan21Table.css";
import { Tooltip } from "react-tooltip";

export function Lehrplan21Table(props: InferProps<typeof Lehrplan21Table.propTypes>) {

  var prevItem: any = null;

  return <>
    <table className="lp21-table table table-rounded gy-2 gs-7">
      {props.renderHeaderRow &&
        <thead>
          <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
            <th colSpan={2}>{/* Zyklus */}</th>
            <th>{/* Code */}</th>
            <th>{/* Name */}</th>
          </tr>
        </thead>
      }
      <tbody>
        {props.items && props.items.map((item: any) => {
          var renderedItem = renderItem(props, item, prevItem);
          prevItem = item;
          return renderedItem;
        })}
      </tbody>
    </table>
    <Tooltip id="tooltip-zebis" content="zum Unterrichtsmaterial in zebis" />
    <Tooltip id="tooltip-mandatory" content="Verbindliche Inhalte" />
    <Tooltip id="tooltip-lehrplan21" content="zum Lehrplan 21" />
  </>;
}

function renderItem(props: InferProps<typeof Lehrplan21Table.propTypes>, item: any, prevItem: any): JSX.Element {

  var classNames = [];
  if (item.istGrundanspruch) {
    classNames.push("lp21-grundanspruch");
  }
  var className = classNames.join(" ");

  // Limit displayed levels
  var showLevels = ["fach", "kompetenzbereich", "handlungsaspekt", "kompetenz", "kompetenzstufe"];
  if (props.levelUntil && props.levelUntil !== "") {
    var levelUntilIndex = showLevels.indexOf(props.levelUntil);
    showLevels = showLevels.slice(0, levelUntilIndex + 1);
  }

  var fachSameAsPrev = (item.fachbereichName === prevItem?.fachbereichName && item.fachName === prevItem?.fachName);
  var kompetenzbereichSameAsPrev = (item.kompetenzbereichName === prevItem?.kompetenzbereichName);
  var handlungsaspektSameAsPrev = (item.handlungsaspektName === prevItem?.handlungsaspektName);

  var kompetenzSameAsPrev = (
    item.kompetenzCode === prevItem?.kompetenzCode
    && item.kompetenzName === prevItem?.kompetenzName
  );

  var kompetenzAndAufbauSameAsPrev = (
    item.kompetenzCode === prevItem?.kompetenzCode
    && item.kompetenzName === prevItem?.kompetenzName
    && item.aufbauName === prevItem?.aufbauName
  );

  var kompetenzstufeSameAsPrev = (
    item.kompetenzstufeCode === prevItem?.kompetenzstufeCode
  );

  var zyklusSameAsPrev = (
    item.zyklusCode === prevItem?.zyklusCode
    && fachSameAsPrev
    && kompetenzbereichSameAsPrev
    && handlungsaspektSameAsPrev
    && kompetenzAndAufbauSameAsPrev
  );

  return <Fragment key={item.id}>
    {showLevels.includes("fach") && !fachSameAsPrev && renderFach(props, item, className)}
    {showLevels.includes("kompetenzbereich") && !kompetenzbereichSameAsPrev && renderKompetenzbereich(props, item, className)}
    {showLevels.includes("handlungsaspekt") && !handlungsaspektSameAsPrev && renderHandlungsaspekt(props, item, className)}
    {showLevels.includes("kompetenz") && !kompetenzAndAufbauSameAsPrev && renderKompetenz(props, item, className, kompetenzSameAsPrev)}

    {showLevels.includes("kompetenzstufe") && <Fragment>
      {item.istSpaeterImZyklus && renderSpaeterImZyklus(item, zyklusSameAsPrev, className)}
      {item.istOrientierungspunktVorher && renderOrientierungspunkt(true, item, className)}
      {renderKompetenzstufe(props, item, className, zyklusSameAsPrev, kompetenzstufeSameAsPrev)}
      {item.istOrientierungspunkt && renderOrientierungspunkt(false, item, className)}
    </Fragment>
    }
  </Fragment>;
}

function renderFach(props: InferProps<typeof Lehrplan21Table.propTypes>, item: any, className: string): JSX.Element {
  const skipRenderingCustomColumn = props.skipRenderingCustomColumnForLevels && props.skipRenderingCustomColumnForLevels.includes("fach");
  return <Fragment>
    <tr key={`${item.id}_fach`} className={`lp21-fach ${className}`}>
      {renderZyklusColumn("0", false, false)}
      <td><a className="lp21-anchor" id={item.fachCode ?? item.fachbereichCode} />{item.fachCode ?? item.fachbereichCode}</td>
      <td colSpan={skipRenderingCustomColumn ? 2 : 1}>{item.fachName ?? item.fachbereichName}</td>
      {!skipRenderingCustomColumn && props.onRenderCustomColumns && props.onRenderCustomColumns(item, item.fachCode ? "fach" : "fachbereich", item.fachCode ? item.fachId : item.fachbereichId)}
    </tr>
    {props.onRenderCustomRow && props.onRenderCustomRow(item, item.fachCode ? "fach" : "fachbereich", item.fachCode ? item.fachId : item.fachbereichId)}
  </Fragment>;
}

function renderKompetenzbereich(props: InferProps<typeof Lehrplan21Table.propTypes>, item: any, className: string): JSX.Element {
  const skipRenderingCustomColumn = props.skipRenderingCustomColumnForLevels && props.skipRenderingCustomColumnForLevels.includes("kompetenzbereich");
  return <Fragment>
    <tr key={`${item.id}_kompetenzbereich`} className={`lp21-kompetenzbereich ${className}`}>
      {renderZyklusColumn("0", false, false)}
      <td><a className="lp21-anchor" id={item.kompetenzbereichCode} />{item.kompetenzbereichCode}</td>
      <td colSpan={skipRenderingCustomColumn ? 2 : 1}>{item.kompetenzbereichName}
        {props.renderLinks && <span data-tooltip-id="tooltip-zebis">
          <a href={`https://www.zebis.ch/lp21/filter/${item.kantonZebisCode}/${item.kompetenzbereichUID}`} target="_blank" className="lp21-unterrichtsmaterial"><i className="bi bi-file-richtext"></i></a>
        </span>
        }
      </td>
      {!skipRenderingCustomColumn && props.onRenderCustomColumns && props.onRenderCustomColumns(item, "kompetenzbereich", item.kompetenzbereichId)}
    </tr>
    {props.onRenderCustomRow && props.onRenderCustomRow(item, "kompetenzbereich", item.kompetenzbereichId)}
  </Fragment>;
}

function renderHandlungsaspekt(props: InferProps<typeof Lehrplan21Table.propTypes>, item: any, className: string): JSX.Element {
  const skipRenderingCustomColumn = props.skipRenderingCustomColumnForLevels && props.skipRenderingCustomColumnForLevels.includes("handlungsaspekt");
  return <Fragment>
    <tr key={`${item.id}_handlungsaspekt`} className={`lp21-handlungsaspekt ${className}`}>
      {renderZyklusColumn("0", false, false)}
      <td>{item.handlungsaspektCode}</td>
      <td colSpan={skipRenderingCustomColumn ? 2 : 1}>{item.handlungsaspektName}</td>
      {!skipRenderingCustomColumn && props.onRenderCustomColumns && props.onRenderCustomColumns(item, "handlungsaspekt", item.handlungsaspektId)}
    </tr>
    {props.onRenderCustomRow && props.onRenderCustomRow(item, "handlungsaspekt", item.handlungsaspektId)}
  </Fragment>;
}

function renderKompetenz(props: InferProps<typeof Lehrplan21Table.propTypes>, item: any, className: string, kompetenzSameAsPrev: boolean): JSX.Element {
  const skipRenderingCustomColumn = props.skipRenderingCustomColumnForLevels && props.skipRenderingCustomColumnForLevels.includes("kompetenz");
  return <Fragment>
    <tr key={item.id + "_kompetenz"} className={`lp21-kompetenz ${className}`}>
      {renderZyklusColumn("0", false, false)}
      <td>
        {!kompetenzSameAsPrev &&
          <p className="lp21-kompetenz">{item.kompetenzCode}</p>
        }
      </td>
      <td colSpan={skipRenderingCustomColumn ? 2 : 1}>
        {!kompetenzSameAsPrev &&
          <p className="lp21-kompetenz">{item.kompetenzName}
            {props.renderLinks && <span data-tooltip-id="tooltip-zebis">
              <a href={`https://www.zebis.ch/lp21/result/${item.kantonZebisCode}/${item.kompetenzUID}`} title="zum Unterrichtsmaterial in zebis" target="_blank" className="lp21-unterrichtsmaterial"><i className="bi bi-file-richtext"></i></a>
            </span>
            }
          </p>
        }
        {item.aufbauName && <p className="lp21-aufbau">{item.aufbauName}</p>}
        {/* <p>Die Schülerinnen und Schüler ...</p> */}
      </td>
      {!kompetenzSameAsPrev && !skipRenderingCustomColumn && props.onRenderCustomColumns && props.onRenderCustomColumns(item, "kompetenz", item.kompetenzId)}
    </tr>
    {props.onRenderCustomRow && props.onRenderCustomRow(item, "kompetenz", item.kompetenzId)}
  </Fragment>;
}

function renderKompetenzstufe(props: InferProps<typeof Lehrplan21Table.propTypes>, item: any, className: string, zyklusSameAsPrev: boolean, kompetenzstufeSameAsPrev: boolean) {
  const skipRenderingCustomColumn = props.skipRenderingCustomColumnForLevels && props.skipRenderingCustomColumnForLevels.includes("kompetenzstufe");

  var classNames = [];
  classNames.push(className);
  if (item.linieOben !== 0 && !item.istOrientierungspunktVorher && !item.istSpaeterImZyklus) {
    classNames.push("lp21-zyklus-linieoben-" + item.linieOben);
  }
  if (item.linieUnten !== 0 && !item.istOrientierungspunkt) {
    classNames.push("lp21-zyklus-linieunten-" + item.linieUnten);
  }
  className = classNames.join(" ");

  return <Fragment>
    <tr key={item.id + "_kompetenzstufe"} className={`lp21-kompetenzstufe ${className}`}>
      {renderZyklusColumn(item.zyklusCode, zyklusSameAsPrev || item.istSpaeterImZyklus, false)}
      <td>{!kompetenzstufeSameAsPrev && item.kompetenzstufeCode}</td>
      <td className="lp21-kompetenzstufe" colSpan={skipRenderingCustomColumn ? 2 : 1}>
        <div className="row">
          <div className="col-auto">
            <p><i className="bi bi-chevron-double-right"></i></p>
          </div>
          <div className="col">
            <p className="lp21-kompetenzstufe-html" dangerouslySetInnerHTML={{ __html: item.aufzaehlungspunktName }}></p>
            {item.aufzaehlungspunktBegriffe && <p className="lp21-begriffe"><span data-tooltip-id="tooltip-mandatory"><i className="bi bi-list"></i> {item.aufzaehlungspunktBegriffe}</span></p>}
            {props.renderLinks && <p><span data-tooltip-id="tooltip-lehrplan21"><a href={item.aufzaehlungspunktUrl} target="_blank" tabIndex={-1}><i className="bi bi-box-arrow-up-right"></i></a></span></p>}
          </div>
        </div>
      </td>
      {!skipRenderingCustomColumn && props.onRenderCustomColumns && props.onRenderCustomColumns(item, "kompetenzstufe", item.id)}
    </tr>
    {props.onRenderCustomRow && props.onRenderCustomRow(item, "kompetenzstufe", item.id)}
  </Fragment>;
}

function renderSpaeterImZyklus(item: any, zyklusSameAsPrev: boolean, className: string): JSX.Element {
  var classNames = [];
  classNames.push(className);
  if (item.linieOben !== 0 && item.istSpaeterImZyklus) {
    classNames.push("lp21-zyklus-linieoben-" + item.linieOben);
  }
  className = classNames.join(" ");

  return <tr key={item.id + "_spaeterImZyklus"} className={`lp21-spaeterImZyklus ${className}`}>

    {renderZyklusColumn(item.zyklusCode, zyklusSameAsPrev, false)}
    <td></td>
    <td colSpan={10}><i className="bi bi-arrow-down-square-fill"></i></td>
  </tr>;
}

function renderOrientierungspunkt(istVorher: boolean, item: any, className: string): JSX.Element {
  var classNames = [];
  classNames.push(className);
  if (item.linieOben !== 0 && !item.istSpaeterImZyklus && item.istOrientierungspunktVorher) {
    classNames.push("lp21-zyklus-linieoben-" + item.linieOben);
  }
  if (item.linieUnten !== 0 && item.istOrientierungspunkt) {
    classNames.push("lp21-zyklus-linieunten-" + item.linieUnten);
  }
  className = classNames.join(" ");

  return <tr key={item.id + "_orientierungspunkt"} className={`lp21-orientierungspunkt ${className}`}>

    {renderZyklusColumn(item.zyklusCode, true, true)}

    <td colSpan={10}><hr /></td>
  </tr>;
}

function renderZyklusColumn(zyklusCode: string, zyklusSameAsPrev: boolean, istOrientierungspunkt: boolean) {

  if (zyklusCode === "0") {
    // Header
    return <td colSpan={2}></td>
  } else if (zyklusCode.length === 1) {
    // Only one Zyklus
    return <td colSpan={2} className={`lp21-zyklus lp21-zyklus-left lp21-zyklus-${zyklusCode}`}>
      {!zyklusSameAsPrev && !istOrientierungspunkt && zyklusCode}
      {istOrientierungspunkt && <i className="bi bi-record-circle"></i>}
    </td>;
  } else {
    // Mixed Zyklus
    return <Fragment>
      <td className={`lp21-zyklus lp21-zyklus-left lp21-zyklus-${zyklusCode}`}></td>
      <td className={`lp21-zyklus lp21-zyklus-right lp21-zyklus-${zyklusCode}`}></td>
    </Fragment>;
  }
};

Lehrplan21Table.propTypes = {
  items: PropTypes.array.isRequired,
  levelUntil: PropTypes.string,
  onRenderCustomColumns: PropTypes.func,
  onRenderCustomRow: PropTypes.func,
  renderLinks: PropTypes.bool.isRequired,
  skipRenderingCustomColumnForLevels: PropTypes.array,
  renderHeaderRow: PropTypes.bool.isRequired,
};

export default Lehrplan21Table;
