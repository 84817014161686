import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Routes } from "react-router-dom";

export function Logout() {
  const dispatch = useDispatch();
  useEffect(() => {
    document.location.reload();
  }, [dispatch]);

  return (
    <Routes>
      <Navigate to="/auth/login" />
    </Routes>
  );
}
