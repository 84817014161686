import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import { isGuid } from "../../helper";
import FormControlWithLabel from "../../../../components/FormControlWithLabel";

export function FamilyUserPage() {

    // Hooks
    const navigate = useNavigate();

    // Global State
    const { globalState } = useGlobalState();

    // State
    const [families, setFamilies] = useState([]);
    const [users, setUsers] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [familyId, setFamilyId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [userRoleId, setUserRoleId] = useState(null);

    // Route params
    const { id } = useParams<{ id: string }>();

    // Mount
    useEffect(() => {

        // Lookups
        fetchFamiliesApi(globalState.apiBaseUrl).then((result: any) => { setFamilies(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
        fetchUsersApi(globalState.apiBaseUrl).then((result: any) => { setUsers(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
        fetchUserRolesApi(globalState.apiBaseUrl).then((result: any) => { setUserRoles(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });

        // New item
        if (!isGuid(id)) {
            return;
        }

        fetchItemApi(globalState.apiBaseUrl)
            .then((result: any) => {
                setFamilyId(result.familyId);
                setUserId(result.userId);
                setUserRoleId(result.userRoleId);
            })
            .catch((ex: any) => {
                alert(`Error: ${ex})`);
            });


    }, []);

    // Event handlers
    const onSubmitHandler = function (e: any) {
        e.preventDefault();
        saveItemApi(globalState.apiBaseUrl, {
            familyId: familyId,
            userId: userId,
            userRoleId: userRoleId,
        })
            .then(() => {
                navigate("../familyusers");
            })
            .catch((ex: any) => {
                if (ex && ex.response && ex.response.details) {
                    alert(`Error: ${ex})\n\n${ex.response.details}`);
                } else {
                    alert(`Error: ${ex})`);
                }
            });
    }

    const onCancelHandler = function () {
        navigate("../familyusers");
    }

    // Web api calls
    const fetchFamiliesApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/family`, globalState.authToken);
    }
    const fetchUsersApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/user`, globalState.authToken);
    }
    const fetchUserRolesApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/userrole`, globalState.authToken);
    }
    const fetchItemApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/familyuser/${id}`, globalState.authToken);
    }
    const saveItemApi = async function (apiBaseUrl: string | undefined, data: any) {
        if (!isGuid(id)) {
            // Create
            const options = { method: "POST", payload: data, };
            return request(`${apiBaseUrl}/familyuser`, globalState.authToken, options);
        }
        else {
            // Update
            const options = { method: "PUT", payload: data, };
            return request(`${apiBaseUrl}/familyuser/${id}`, globalState.authToken, options);
        }
    }

    return (
        <form onSubmit={onSubmitHandler}>
            <div className="card p-10">
                <div className="row">
                    <FormControlWithLabel className="col-4" controlId="lstFamily" controlType="select" label="Familie" options={families} value={familyId} isRequired={true} onChange={(value: any) => setFamilyId(value)} />
                    <FormControlWithLabel className="col-4" controlId="lstUser" controlType="select" label="Benutzer" options={users} value={userId} isRequired={true} onChange={(value: any) => setUserId(value)} />
                    <FormControlWithLabel className="col-4" controlId="lstUserRole" controlType="select" label="Rolle" options={userRoles} value={userRoleId} isRequired={true} onChange={(value: any) => setUserRoleId(value)} />
                </div>
                <div className="lp21-buttons">
                    <button type="submit" className="btn btn-primary">Speichern</button>
                    <button type="button" className="btn btn-secondary" onClick={onCancelHandler}>Abbrechen</button>
                </div>
            </div>
        </form>
    );
};

export default FamilyUserPage;