import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../../GlobalStateProvider";
import Lehrplan21Table from "../../browser/pages/Lehrplan21Table";
import { useInView } from "react-cool-inview";
import { useParams } from "react-router";
import "./PlanItemsPage.css"
import LoadingIndicator from "../../../../components/LoadingIndicator";
import NoItems from "../../../../components/NoItems";
import PlanItemCustomColumn from "./PlanItemCustomColumn";
import PropTypes, { InferProps } from "prop-types";
import { PageTitle } from "../../../../_start/layout/core";
import PlanItemCustomRow from "./PlanItemCustomRow";
import { FilterModel, IFilterModel } from "../../browser/pages/FilterModel";
import Lehrplan21FilterPane from "../../browser/pages/Lehrplan21FilterPane";
import Lehrplan21NavigationPane from "../../browser/pages/Lehrplan21NavigationPane";

export function PlanItemsPage(props: InferProps<typeof PlanItemsPage.propTypes>) {

  // Global State
  const { globalState } = useGlobalState();

  // State
  const [assessments, setAssessments] = useState<any>([]);
  const [assessmentSchemaLevels, setAssessmentSchemaLevels] = useState<any>([]);
  const [assessmentSchemaLevelsFilter, setAssessmentSchemaLevelsFilter] = useState<any>([]);
  const [plan, setPlan] = useState<any>(null);
  const [filter] = useState<FilterModel>(new FilterModel({} as IFilterModel));
  const [items, setItems] = useState([]);
  const [pageSize] = useState(10);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [hasNoItems, setHasNoItems] = useState(true);
  const [remarks, setRemarks] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [reloadSummary, setReloadSummary] = useState(new Date());
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
  const [saveItemData, setSaveItemData] = useState<any>(null);

  // Route params
  const { id } = useParams<{ id: string }>();

  // Infinite scroll
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve, observe }) => {
      unobserve();
      onNextPage(() => {
        observe();
      }, false);
    },
  });

  // Mount
  useEffect(() => {

    fetchItemApi(globalState.apiBaseUrl)
      .then((result: any) => {
        setPlan(result);

        if (!filter.assessmentId) {
          filter.assessmentId = result?.linkedAssessmentId;
        }

        // Items
        fetchItems(false, filter, null, false);

        // Lookups
        fetchAssessmentsApi(globalState.apiBaseUrl).then((res: any) => {

          setAssessments(res);

          // Show assessment schema levels from given assessment
          const currentAssessment = res.find((a: any) => a.id === filter.assessmentId);
          loadAssessmentLevels(currentAssessment, filter.assessmentId);

          // Enable infinite scroll
          setInitialLoadCompleted(true);

        }).catch((ex: any) => { alert(`Error: ${ex})`) });

      })
      .catch((ex: any) => {
        alert(`Error: ${ex})`);
      });

  }, []);

  /* Delayed saving */
  useEffect(() => {
    const debouncedSaveItem = setTimeout(() => {
      if (saveItemData) {
        saveItem(saveItemData.level, saveItemData.id, saveItemData.item, saveItemData.isPlanned, saveItemData.sort, saveItemData.remarks);
      }
    }, 200);

    return () => clearTimeout(debouncedSaveItem);
  }, [saveItemData]);

  // Event handlers
  const onFilterChanged = function (reloadAssessmentSchemaLevels: boolean) {
    fetchItems(false, filter, null, false);

    if (reloadAssessmentSchemaLevels) {
      loadAssessmentLevels(null, filter.assessmentId);
    }
  }

  const onSaveItem = function (level: string, id: string, item: any, isPlanned: any, sort: any, remarks: any) {
    saveItem(level, id, item, isPlanned, sort, remarks);

    //setSaveItemData({ level, id, item, isPlanned, sort, remarks });
  }

  const onNextPage = function (postFetchFunc: any, loadAll: boolean) {

    // Initial load not completed yet?
    if (!initialLoadCompleted) {
      return;
    }

    fetchItems(true, filter, postFetchFunc, loadAll);
  }

  function loadAssessmentLevels(currentAssessment: any, assessmentId: any) {
    if (!currentAssessment) {
      currentAssessment = assessments.find((a: any) => a.id === assessmentId);
    }
    if (currentAssessment && currentAssessment.assessmentSchemaId) {
      fetchAssessmentSchemaLevelsApi(currentAssessment.assessmentSchemaId).then((res: any) => {

        // Add empty level
        var levels = res.levels;
        levels.unshift({
          id: "00000000-0000-0000-0000-000000000000",
          name: "ohne Beurteilung",
          percentage: 0,
        });
        setAssessmentSchemaLevels(levels);

        // Different labels for filter
        var levelsFilter = levels.map((l: any) => {

          if (l.name.startsWith("ohne")) {
            return l;
          }
          else {
            return {
              id: l.id,
              name: `bis und mit ${l.name}`,
            }
          }
        });
        setAssessmentSchemaLevelsFilter(levelsFilter);
      }
      ).catch((ex: any) => { alert(`Error: ${ex})`) });
    }
  }

  function onRenderCustomColumns(item: any, level: string, rowId: string): JSX.Element {

    // Lookup remark
    var remark: any = findRemark(level, rowId);
    return <>
      {id && <PlanItemCustomColumn assessmentSchemaLevels={assessmentSchemaLevels} planId={id} rowId={rowId} level={level} isReadOnly={props.isReadOnly || plan?.planStatusIsReadOnly} onSaveItem={onSaveItem} item={item} remark={remark} reloadSummary={reloadSummary} />}
    </>
  }

  function onRenderCustomRow(item: any, level: string, rowId: string): JSX.Element {

    // Lookup remark
    var remark: any = findRemark(level, rowId);

    return <>
      {id && <PlanItemCustomRow planId={id} linkedAssessmentId={filter.assessmentId} rowId={rowId} level={level} isReadOnly={props.isReadOnly || plan?.planStatusIsReadOnly} onSaveItem={onSaveItem} item={item} remark={remark} reloadSummary={reloadSummary} />}
    </>
  }

  // Functions
  const fetchItems = function (appendItems: boolean, filter: FilterModel | null, postFetchFunc: any, loadAll: boolean) {

    setIsLoading(true);

    if (!items || items.length === 0) {
      appendItems = false;
    }

    var offset = (appendItems === false ? 0 : items.length);
    var next = (loadAll ? 2147483647 : pageSize);

    fetchItemsApi(globalState.apiBaseUrl, offset, next, filter)
      .then((result: any) => {

        if (result.queryId < (globalState.queryId ?? 0)) {
          // Ignore results, seem to be too late
          console.log(`Skipped query id ${result.queryId} as running alredy query id ${globalState.queryId}.`);
          return;
        }

        var resultItems = (appendItems === false ? result.items : [...items, ...result.items]);
        setItems(resultItems);
        setRemarks(result.remarks);
        setIsLoading(false);
        setHasMoreItems((result.items?.length ?? 0) === next);
        setHasNoItems(resultItems.length === 0);
        if (postFetchFunc) postFetchFunc();
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
        setIsLoading(false);
      });
  };

  const clearItems = function () {
    setItems([]);
    setRemarks([]);
  };

  const findRemark = function (level: string, id: string): any {
    if (!remarks || remarks.length === 0)
      return null;

    return remarks.find((r: any) => r.level === level && r.id === id);
  }


  const saveItem = function (level: string, id: string, item: any, isPlanned: any, sort: any, remarks: any) {

    if (level === "aufzaehlungspunkt") {
      if (isPlanned !== null) {
        item.isPlanned = isPlanned;
      } else {
        isPlanned = item.isPlanned;
      }

      if (sort !== null) {
        item.planSort = sort;
      } else {
        sort = item.planSort;
      }

      if (remarks !== null) {
        item.planRemarks = remarks;
      } else {
        remarks = item.planRemarks;
      }
    }
    else {
      isPlanned = isPlanned ?? false;
      sort = sort ?? "";
      remarks = remarks ?? "";
    }

    saveItemApi(globalState.apiBaseUrl, level, id, {
      planId: item.planId,
      isPlanned: isPlanned,
      sort: sort,
      remarks: remarks,
    })
      .then(() => {
        // Save confirmation
      })
      .catch((ex: any) => {
        if (ex && ex.response && ex.response.details) {
          alert(`Error: ${ex})\n\n${ex.response.details}`);
        } else {
          alert(`Error: ${ex})`);
        }
      });
  }

  // Web api calls
  const fetchItemApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/plan/${id}`, globalState.authToken);
  }
  const fetchAssessmentSchemaLevelsApi = async function (assessmentSchemaId: string) {
    return request(`${globalState.apiBaseUrl ?? ""}/masterdata/assessmentschema/${assessmentSchemaId}`, globalState.authToken);
  }
  const fetchAssessmentsApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/assessment?familyId=${globalState.familyId}`, globalState.authToken);
  }
  const fetchItemsApi = async function (apiBaseUrl: string | undefined, offset: number, next: number, filter: FilterModel | null) {
    globalState.queryId = (globalState.queryId ?? 0) + 1;
    return request(`${apiBaseUrl}/plan/${id}/browse?queryId=${globalState.queryId}&offset=${offset}&next=${next}&${filter?.toUrlQueryParams()}`, globalState.authToken);
  }
  const saveItemApi = async function (apiBaseUrl: string | undefined, level: any, innerId: any, data: any) {
    // Create or Update
    const options = { method: "PUT", payload: data, };
    return request(`${apiBaseUrl}/plan/${id}/item?level=${level}&id=${innerId}`, globalState.authToken, options);
  }

  return (<>
    <div className="lp21-panes">
      <Lehrplan21FilterPane filter={filter} onFilterChanged={onFilterChanged} onClearItems={clearItems} isLoading={isLoading} filterAutoSaveSuffix="Lehrplan durchsuchen" fixedLehrplanId={plan?.lehrplanId} fixedPlanId={plan?.id} assessmentSchemaLevels={assessmentSchemaLevelsFilter} />
      <Lehrplan21NavigationPane items={items} levelUntil={filter?.levelUntil} pageSize={pageSize} hasMoreItems={hasMoreItems} onLoadNext={() => { onNextPage(null, false) }} showLoadAll={(filter?.fachbereichId ?? "") !== ""} onLoadAll={() => { onNextPage(null, true) }} />
    </div>
    <div className="card lp21-planitemspage">
      <PageTitle>{`Planung ${plan?.name ?? ""}`}</PageTitle>
      <Lehrplan21Table items={items} levelUntil={filter?.levelUntil} onRenderCustomColumns={onRenderCustomColumns} onRenderCustomRow={onRenderCustomRow} renderLinks={true} renderHeaderRow={false} skipRenderingCustomColumnForLevels={["fachbereich", "fach", "kompetenzbereich", "handlungsaspekt", "kompetenz"]} />
      {!isLoading && hasMoreItems && <button ref={observe} className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary" onClick={() => { onNextPage(null, false) }}>Nächste laden</button>}
      {isLoading && <div className="row m-3"> <LoadingIndicator /></div>}
      {!isLoading && hasNoItems && <NoItems />}
    </div>
  </>
  );
};

PlanItemsPage.propTypes = {
  isReadOnly: PropTypes.bool.isRequired
};

export default PlanItemsPage;