import { requestAnonymous } from "../../client";

// Server should return AuthModel
export async function login(apiBaseUrl: string, email: string, password: string) {
  return loginApi(apiBaseUrl, {
    email: email,
    password: password,
  });
}

// Server should return AuthModel
export function register(apiBaseUrl: string,
  email: string,
  firstname: string,
  lastname: string,
  newPassword: string,
  familyAddress: string,
  familyPhone: string,
  targetGroup: string,
  teachers: string,
  children: string,
  remarks: string
) {

  var finalRemarks = "Zielgruppe: " + targetGroup;
  if (teachers && teachers!== "")
  {
    finalRemarks += "\n" + "Anzahl Lehrpersonen: " + teachers;
  }
  if (children && children!== "")
  {
    finalRemarks += "\n" + "Anzahl Kinder: " + children;
  }
  if (remarks && remarks!== "")
  {
    finalRemarks += "\n\n" + remarks;
  }

  return registerApi(apiBaseUrl, {
    email,
    firstname,
    lastname,
    newPassword,
    familyAddress,
    familyPhone,
    remarks: finalRemarks,
  });
}

// Server should return AuthModel
export async function requestPasswordReset(apiBaseUrl: string,
  email: string
  ) {

  return requestPasswordResetApi(apiBaseUrl, {
    email,
  });
}

// Web api calls
const loginApi = async function (apiBaseUrl: string | undefined, data: any) {
  const options = { method: "POST", payload: data, };
  return requestAnonymous(`${apiBaseUrl}/auth/login`, options);
}

const registerApi = async function (apiBaseUrl: string | undefined, data: any) {
  const options = { method: "POST", payload: data, };
  return requestAnonymous(`${apiBaseUrl}/auth/register`, options);
}

const requestPasswordResetApi = async function (apiBaseUrl: string | undefined, data: any) {
  const options = { method: "POST", payload: data, };
  return requestAnonymous(`${apiBaseUrl}/auth/requestpasswordreset`, options);
}