import { PageLink } from "../../../_start/layout/core";

export const generalSubmenu: Array<PageLink> = [
  {
    title: "Familien",
    path: "/admin/familyies",
    isActive: true,
  },
  {
    title: "Aufträge",
    path: "/admin/jobs",
    isActive: false,
  },
];
