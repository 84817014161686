/* eslint-disable jsx-a11y/anchor-is-valid */
import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import { isGuid } from "../../helper";
import FormControlWithLabel from "../../../../components/FormControlWithLabel";
import PropTypes, { InferProps } from "prop-types";
import SaveAndCancelOrCloseButtons from "../../../../components/SaveAndCancelOrCloseButtons";
import { Tab, Tabs } from "react-bootstrap";

export function AssessmentPage(props: InferProps<typeof AssessmentPage.propTypes>) {

  // Hooks
  const navigate = useNavigate();

  // Global State
  const { globalState, } = useGlobalState();

  // State
  const [childGradeLevels, setChildGradeLevels] = useState([]);
  const [assessmentSchemas, setAssessmentSchemas] = useState([]);
  const [assessmentStatus, setAssessmentStatus] = useState<any>([]);
  const [plans, setPlans] = useState([]);
  const [childGradeLevelId, setChildGradeLevelId] = useState(null);
  const [date, setDate] = useState("");
  const [reportTitle, setReportTitle] = useState("");
  const [reportSubtitle, setReportSubtitle] = useState("");
  const [reportAuthor, setReportAuthor] = useState("");
  const [reportFooter, setReportFooter] = useState("");
  const [reportIntroduction, setReportIntroduction] = useState("");
  const [reportChapter1, setReportChapter1] = useState("");
  const [reportChapter2, setReportChapter2] = useState("");
  const [reportChapter4, setReportChapter4] = useState("");
  const [assessmentStatusId, setAssessmentStatusId] = useState(null);
  const [assessmentSchemaId, setAssessmentSchemaId] = useState(null);
  const [levelsCount, setLevelsCount] = useState(0);
  const [linkedPlanId, setLinkedPlanId] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);

  // Route params
  const { id } = useParams<{ id: string }>();

  // Mount
  useEffect(() => {

    // Read-only
    if (props.isReadOnly) {
      setIsReadOnly(props.isReadOnly);
    }

    // Lookups
    fetchChildGradeLevelsApi(globalState.apiBaseUrl).then((result: any) => { setChildGradeLevels(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
    fetchAssessmentSchemasApi(globalState.apiBaseUrl).then((result: any) => { setAssessmentSchemas(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
    fetchAssessmentStatusApi(globalState.apiBaseUrl).then((result: any) => { setAssessmentStatus(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
    fetchPlansApi(globalState.apiBaseUrl).then((result: any) => { setPlans(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });

    // New item
    if (!isGuid(id)) {
      return;
    }

    fetchItemApi(globalState.apiBaseUrl)
      .then((result: any) => {
        setChildGradeLevelId(result.childGradeLevelId);
        setDate(result.date);
        setReportTitle(result.reportTitle);
        setReportSubtitle(result.reportSubtitle);
        setReportAuthor(result.reportAuthor);
        setReportFooter(result.reportFooter);
        setReportIntroduction(result.reportIntroduction);
        setReportChapter1(result.reportChapter1);
        setReportChapter2(result.reportChapter2);
        setReportChapter4(result.reportChapter4);
        setAssessmentStatusId(result.assessmentStatusId);
        setAssessmentSchemaId(result.assessmentSchemaId);
        setLinkedPlanId(result.linkedPlanId);
        setLevelsCount(result.levelsCount);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex})`);
      });


  }, []);

  useEffect(() => {
    const currentAssessmentStatus = assessmentStatus.find((ps: any) => ps.id === assessmentStatusId);

    if (currentAssessmentStatus) {
      setIsReadOnly(currentAssessmentStatus.isReadOnly);
    }

  }, [assessmentStatusId]);

  // Event handlers
  const onSubmitHandler = function (e: any) {
    e.preventDefault();
    saveItemApi(globalState.apiBaseUrl, {
      childGradeLevelId: childGradeLevelId,
      date: date,
      reportTitle: reportTitle,
      reportSubtitle: reportSubtitle,
      reportAuthor: reportAuthor,
      reportFooter: reportFooter,
      reportIntroduction: reportIntroduction,
      reportChapter1: reportChapter1,
      reportChapter2: reportChapter2,
      reportChapter4: reportChapter4,
      assessmentStatusId: assessmentStatusId,
      assessmentSchemaId: assessmentSchemaId,
      linkedPlanId: linkedPlanId,
    })
      .then((result: any) => {
        if (result) {
          navigate(`/app/assessments/${result}/copy`);
        } else {
          navigate("/app/assessments");
        }
      })
      .catch((ex: any) => {
        if (ex && ex.response && ex.response.details) {
          alert(`Error: ${ex})\n\n${ex.response.details}`);
        } else {
          alert(`Error: ${ex})`);
        }
      });
  }

  const onCancelHandler = function () {
    navigate("/app/assessments");
  }

  // Web api calls
  const fetchChildGradeLevelsApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/childgradelevel?familyId=${globalState.familyId}`, globalState.authToken);
  }
  const fetchAssessmentSchemasApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/masterdata/assessmentschema`, globalState.authToken);
  }
  const fetchAssessmentStatusApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/masterdata/assessmentstatus`, globalState.authToken);
  }
  const fetchPlansApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/plan?familyId=${globalState.familyId}`, globalState.authToken);
  }
  const fetchItemApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/assessment/${id}`, globalState.authToken);
  }
  const saveItemApi = async function (apiBaseUrl: string | undefined, data: any) {
    if (!isGuid(id)) {
      // Create
      const options = { method: "POST", payload: data, };
      return request(`${apiBaseUrl}/assessment`, globalState.authToken, options);
    }
    else {
      // Update
      const options = { method: "PUT", payload: data, };
      return request(`${apiBaseUrl}/assessment/${id}`, globalState.authToken, options);
    }
  }

  return (
    <form onSubmit={onSubmitHandler}>
      <div className="card p-10">
        <div className="row">
          <FormControlWithLabel className="col-4" controlId="dtDate" controlType="date" label="Von" value={date} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setDate(value)} />
          <FormControlWithLabel className="col-4" controlId="lstAssessmenSchema" controlType="select" label="Bewertungsart" options={assessmentSchemas} value={assessmentSchemaId} isRequired={true} isDisabled={props.isReadOnly || levelsCount > 0} onChange={(value: any) => setAssessmentSchemaId(value)}>
            <p>Sobald mindestens eine Bewertung vorgenommen wurde, kann die Bewertungsart nicht mehr geändert werden.</p>
          </FormControlWithLabel>
          <FormControlWithLabel className="col-4" controlId="lstChildGradeLevel" controlType="select" label="Kind / Schuljahr" options={childGradeLevels} value={childGradeLevelId} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setChildGradeLevelId(value)} />
          <FormControlWithLabel className="col-4" controlId="lstAssessmentStatus" controlType="select" label="Status" options={assessmentStatus} value={assessmentStatusId} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setAssessmentStatusId(value)}>
            <h6 className="text-primary"><i className="bi bi-lightbulb fs-3 text-primary"></i> Erläuterungen zum Status</h6>
            <ul>
              <li><b>Geplant</b> &rArr; Wir wissen, dass wir per diesem Datum eine Lernstandserfassung vornehmen und einreichen müssen.</li>
              <li><b>Aktiv</b> &rArr; Diese Lernstandserfassung bearbeiten wir momentan.</li>
              <li><b>Eingereicht</b> &rArr; Wir haben diese Lernstandserfassung eingereicht (Schreibschutz wird aktiviert).</li>
            </ul>
          </FormControlWithLabel>
          <FormControlWithLabel className="col-4" controlId="lstLinkedPlan" controlType="select" label="zugehörige Planung" options={plans} value={linkedPlanId} isRequired={false} isDisabled={props.isReadOnly} onChange={(value: any) => setLinkedPlanId(value)} />
        </div>

        <h4>Bericht</h4>

        <Tabs defaultActiveKey="tab_txtReportFirstPage">
          
          <Tab eventKey="tab_txtReportFirstPage" title="Deckblatt">
            <div className="row mt-5">
              <FormControlWithLabel className="col-6" controlId="txtReportTitle" controlType="text" label="Titel" value={reportTitle} onChange={(value: any) => setReportTitle(value)} />
              <FormControlWithLabel className="col-6" controlId="txtReportSubtitle" controlType="text" label="Untertitel" value={reportSubtitle} onChange={(value: any) => setReportSubtitle(value)} />
              <FormControlWithLabel className="col-6" controlId="txtReportAuthorTitle" controlType="text" label="Autor" value={reportAuthor} onChange={(value: any) => setReportAuthor(value)} />
              <FormControlWithLabel className="col-12" controlId="txtReportFooter" controlType="html" htmlEditorInit={{ height: 150, block_formats: "Titel=h2;Absatz=p", toolbar: "undo redo | bold italic | alignleft aligncenter alignright alignjustify | removeformat | code fullscreen | help" }} label="Fusszeile" value={reportFooter} onChange={(value: any) => setReportFooter(value)} />
            </div>
          </Tab>

          <Tab eventKey="tab_txtReportIntroduction" title="Einleitung">
            <FormControlWithLabel className="col-12" controlId="txtReportIntroduction" controlType="html" htmlEditorInit={{ height: 300, block_formats: "Titel=h2;Absatz=p" }} label="" value={reportIntroduction} onChange={(value: any) => setReportIntroduction(value)} />
          </Tab>

          <Tab eventKey="tab_txtReportChapter1" title="Rückblick">
            <FormControlWithLabel className="col-12" controlId="txtReportChapter1" controlType="html" htmlEditorInit={{ height: 300, block_formats: "Titel=h2;Absatz=p" }} label="" value={reportChapter1} onChange={(value: any) => setReportChapter1(value)} />
          </Tab>

          <Tab eventKey="tab_txtReportChapter2" title="Lernstandserfassung">
            <FormControlWithLabel className="col-12" controlId="txtReportChapter2" controlType="html" htmlEditorInit={{ height: 500, block_formats: "Titel=h2;Absatz=p" }} label="" value={reportChapter2} onChange={(value: any) => setReportChapter2(value)} />
          </Tab >

          <Tab eventKey="tab_txtReportChapter4" title="Weitere Unterlagen">
            <FormControlWithLabel className="col-12" controlId="txtReportChapter4" controlType="html" htmlEditorInit={{ height: 300, block_formats: "Titel=h2;Absatz=p" }} label="" value={reportChapter4} onChange={(value: any) => setReportChapter4(value)} />
          </Tab >

        </Tabs>

        <SaveAndCancelOrCloseButtons isReadOnly={props.isReadOnly} onCancelHandler={onCancelHandler} />
      </div >
    </form >
  );
};

AssessmentPage.propTypes = {
  isReadOnly: PropTypes.bool.isRequired
};

export default AssessmentPage;