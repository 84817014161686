export function isGuid(stringToTest: string | undefined) {

    if (!stringToTest || stringToTest.length === 0)
        return false;

    if (stringToTest[0] === "{") {
        stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
}

export function determineApiBaseUrl() {
    if (window.location.origin === "http://localhost:3000") {
        return "https://localhost:5002/api"
    }
    else {
        return `${window.location.origin}/api`
    }
}


export function sortObjects(sortKey: string, sortAscending: boolean, a: any, b: any) {

    const aValue = a[sortKey];
    const bValue = b[sortKey];

    let comparisonResult = 0;

    if (typeof (aValue) === "undefined" || typeof (bValue) === "undefined") {
        if (typeof (aValue) === "undefined" && typeof (bValue) === "undefined") {
            comparisonResult = 0;
        }
        else if (typeof (aValue) === "undefined") {
            comparisonResult = -1;
        }
        else {
            comparisonResult = 1;
        }
    }
    else if (typeof (aValue) === "string" || typeof (bValue) === "string") {
        comparisonResult = aValue.localeCompare(bValue);
    }
    else if (typeof (aValue) === "number" || typeof (bValue) === "number") {
        comparisonResult = aValue - bValue;
    }
    else if (typeof (aValue) === "boolean" || typeof (bValue) === "boolean") {
        if (aValue === bValue) {
            comparisonResult = 0;
        }
        else if (aValue < bValue) {
            comparisonResult = 1;
        }
        else {
            comparisonResult = -1;
        }
    }
    else {
        throw "Not supported: " + typeof (aValue);
    }

    if (sortAscending) {
        return comparisonResult;
    } else {
        return comparisonResult * -1;
    }
}