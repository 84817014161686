import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import { isGuid } from "../../helper";
import FormControlWithLabel from "../../../../components/FormControlWithLabel";
import PropTypes, { InferProps } from "prop-types";
import "./UserPage.css"

export function UserPage(props: InferProps<typeof UserPage.propTypes>) {

    // Hooks
    const navigate = useNavigate();

    // Global State
    const { globalState } = useGlobalState();

    // State
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

    const [isChangingEmail, setIsChangingEmail] = useState(false);
    const [sentChangeEmail, setSentChangeEmail] = useState(false);
    const [sentVerificationEmail, setSentVerificationEmail] = useState(false);
    const [sentPasswordResetEmail, setSentPasswordResetEmail] = useState(false);

    // Route params
    //const { id } = useParams<{ id: string }>();
    const id = globalState.userId ?? "";

    // Mount
    useEffect(() => {

        // Lookups

        // New item
        if (!isGuid(id)) {
            return;
        }

        fetchItem();

    }, []);

    // Event handlers
    const onCancelHandler = function () {
        navigate("/app/settings/user");
        setIsChangingEmail(false);
        resetSuccessMessages();
    }

    const onSendChangeEmail = function (event: any) {

        event.preventDefault();

        // if (!window.confirm(`Möchten Sie wirklich ein E-Mail an '${email}' zur Verifikation der E-Mail-Adresse senden?`)) {
        //     return;
        // }

        resetSuccessMessages();

        var buttonElement = event.currentTarget[1] as HTMLButtonElement;
        buttonElement.disabled = true;
        //buttonElement.className = "btn btn-light";
        //buttonElement.innerHTML = "E-Mail gesendet";

        // Send change email
        sendChangeEmail();
    }

    const onSendVerificationEmail = function (event: any) {

        // if (!window.confirm(`Möchten Sie wirklich ein E-Mail an '${email}' zur Verifikation der E-Mail-Adresse senden?`)) {
        //     return;
        // }

        resetSuccessMessages();

        var buttonElement = event.currentTarget;
        buttonElement.disabled = true;
        buttonElement.className = "btn btn-link text-disabled";
        // buttonElement.innerHTML = "E-Mail gesendet";

        // Send verification email
        sendVerificationEmail();
    }

    const onSendPasswordResetEmail = function (event: any) {

        // if (!window.confirm(`Möchten Sie wirklich ein E-Mail an '${email}' zum Ändern des Passworts senden?`)) {
        //     return;
        // }

        resetSuccessMessages();

        var buttonElement = event.currentTarget;
        buttonElement.disabled = true;
        buttonElement.className = "btn btn-link text-disabled";

        // Send password reset email
        sendPasswordResetEmail();
    }

    // Functions
    const resetSuccessMessages = function () {
        setSentChangeEmail(false);
        setSentVerificationEmail(false);
        setSentPasswordResetEmail(false);
    }

    const fetchItem = function () {

        fetchItemApi(globalState.apiBaseUrl)
            .then((result: any) => {
                setLastName(result.lastName);
                setFirstName(result.firstName);
                setEmail(result.email);
                setNewEmail(result.email);
                setIsEmailConfirmed(result.isEmailConfirmed);
            })
            .catch((ex: any) => {
                alert(`Error: ${ex})`);
            });
    }

    const sendChangeEmail = function () {
        sendChangeEmailApi(globalState.apiBaseUrl, {
            familyId: globalState.familyId,
            email: newEmail,
        })
            .then(() => {
                setSentChangeEmail(true);
            })
            .catch((ex: any) => {
                if (ex && ex.response && ex.response.details) {
                    alert(`Error: ${ex})\n\n${ex.response.details}`);
                } else {
                    alert(`Error: ${ex})`);
                }
            });
    }

    const sendVerificationEmail = function () {
        sendVerificationEmailApi(globalState.apiBaseUrl)
            .then(() => {
                setSentVerificationEmail(true);
            })
            .catch((ex: any) => {
                if (ex && ex.response && ex.response.details) {
                    alert(`Error: ${ex})\n\n${ex.response.details}`);
                } else {
                    alert(`Error: ${ex})`);
                }
            });
    }

    const sendPasswordResetEmail = function () {
        sendPasswordResetEmailApi(globalState.apiBaseUrl)
            .then(() => {
                setSentPasswordResetEmail(true);
            })
            .catch((ex: any) => {
                if (ex && ex.response && ex.response.details) {
                    alert(`Error: ${ex})\n\n${ex.response.details}`);
                } else {
                    alert(`Error: ${ex})`);
                }
            });
    }

    // Web api calls
    const fetchItemApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/user/${id}`, globalState.authToken);
    }
    const sendChangeEmailApi = async function (apiBaseUrl: string | undefined, data: any) {
        // Update
        const options = { method: "POST", payload: data, };
        return request(`${apiBaseUrl}/user/${id}/sendchangeemail`, globalState.authToken, options);
    }
    const sendVerificationEmailApi = async function (apiBaseUrl: string | undefined) {
        if (!isGuid(id)) {
            // Not supported before save            
        }
        else {
            // Update
            const options = { method: "POST", payload: null, };
            return request(`${apiBaseUrl}/user/${id}/sendverificationemail`, globalState.authToken, options);
        }
    }
    const sendPasswordResetEmailApi = async function (apiBaseUrl: string | undefined) {
        if (!isGuid(id)) {
            // Not supported before save            
        }
        else {
            // Update
            const options = { method: "POST", payload: null, };
            return request(`${apiBaseUrl}/user/${id}/sendpasswordresetemail`, globalState.authToken, options);
        }
    }

    return (
        <div className="lp21-userpage">
            <div className="card p-10">


                <h4>Meine Zugangsdaten</h4>
                <div className="row">
                    {!isChangingEmail && <>
                        <FormControlWithLabel className="col-auto" controlId="txtEmail" controlType="label" label="Benutzername / E-Mail" value={email} onChange={() => { }} />
                        <div className="col-auto lp21-userpage-icon">{isEmailConfirmed ?
                            <span className="lp21-icon"><i className="bi bi-check-circle-fill text-success" title="E-Mail verifiziert"></i></span>
                            : <span className="lp21-icon"><i className="bi bi-question-circle-fill text-warning" title="E-Mail nicht verifiziert"></i><button className="btn btn-link text-warning" onClick={onSendVerificationEmail}>Jetzt verifizieren</button></span>
                        }</div>
                        <div className="col-sm lp21-userpage-button"><button className="btn btn-link" onClick={() => { resetSuccessMessages(); setIsChangingEmail(true); }}>Ändern</button></div>
                    </>}

                    {isChangingEmail && <form onSubmit={onSendChangeEmail}>
                        <div className="col-12 mb-20">
                            <FormControlWithLabel className="col-auto" controlId="txtEmail" controlType="text" label="Benutzername / E-Mail" value={newEmail} onChange={(value: any) => { setNewEmail(value) }} />
                            <div className="lp21-buttons">
                                <button type="submit" disabled={(email === newEmail) || sentChangeEmail} className="btn btn-primary">Änderungslink anfordern</button>
                                <button type="button" className="btn btn-secondary" onClick={onCancelHandler}>Abbrechen</button>
                            </div>
                        </div>
                    </form>}

                    {sentChangeEmail && <div className="row">
                        <div className="alert alert-success" role="alert">
                            Wir haben dir ein E-Mail zugestellt, worin du einen Link findest, um die Änderung der E-Mail-Adresse zu bestätigen. Bitte prüfe deinen Posteingang.
                        </div>
                    </div>
                    }

                    {sentVerificationEmail && <div className="row">
                        <div className="alert alert-success" role="alert">
                            Wir haben dir ein E-Mail zugestellt, worin du einen Link findest, um deine<br />E-Mail-Adresse zu bestätigen. Bitte prüfe deinen Posteingang.
                        </div>
                    </div>
                    }
                </div>
                <div className="row">
                    <FormControlWithLabel className="col-auto" controlId="txtPassword" controlType="label" label="Passwort" value="***" onChange={() => { }} />
                    {!isChangingEmail && <div className="col-sm lp21-userpage-button">
                        {!isEmailConfirmed && <i className="bbi bi-exclamation-triangle-fill me-3 text-warning" title="Bitte verifiziere zuerst die E-Mail-Adresse, danach kannst du den Änderungslink für das Ändern des Passwortes anfordern."></i>}
                        <button className="btn btn-link" onClick={onSendPasswordResetEmail} disabled={!isEmailConfirmed}>Änderungslink anfordern</button></div>}
                    {sentPasswordResetEmail && <div className="mb-lg-15 alert alert-success">
                        <div className="alert-text ">
                            Wir haben dir ein E-Mail zugestellt, worin du einen Änderungslink findest, um das Passwort zurückzusetzen. Bitte prüfe deinen Posteingang.
                        </div>
                    </div>
                    }
                </div>
            </div>
            <div className="card p-10">
                <h4>Meine persönlichen Angaben</h4>
                <div className="row">
                    <FormControlWithLabel className="col-6" controlId="txtFirstName" controlType="label" label="Vorname" value={firstName} onChange={() => { }} />
                    <FormControlWithLabel className="col-6" controlId="txtLastName" controlType="label" label="Nachname" value={lastName} onChange={() => { }} />
                </div>
            </div>
        </div>
    );
};

UserPage.propTypes = {
    isReadOnly: PropTypes.bool.isRequired
};

export default UserPage;