import React, { useEffect } from "react";
import PropTypes, { InferProps } from "prop-types";

export function AssessmentItemPlanning(props: InferProps<typeof AssessmentItemPlanning.propTypes>) {

  // Mount
  useEffect(() => {

  }, []);

  return <>
    {(props.planSort || props.planRemarks) &&
      <div className={`${props.className}-planning col-8 offset-4 lp21-customColumn-planning`}>
        <div className="alert alert-primary">
          <label className="form-label">Planung
            {props.planSort && <span> - {props.planSort}</span>}
          </label>
          {props.planRemarks && <div dangerouslySetInnerHTML={{ __html: props.planRemarks }}></div>}
        </div>
      </div>
    }
  </>;
};

AssessmentItemPlanning.propTypes = {
  className: PropTypes.any,
  planSort: PropTypes.any,
  planRemarks: PropTypes.any,
};

export default AssessmentItemPlanning;

