import React, { useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import { useGlobalState } from "../app/GlobalStateProvider";
import FileUpload from "./FileUpload";
import "./AssessmentImageManager.css";
import { request } from "../app/modules/client";

export function AssessmentImageManager(props: InferProps<typeof AssessmentImageManager.propTypes>) {

    // Global State
    const { globalState, } = useGlobalState();

    // State
    const [deletedIds, setDeletedIds] = useState<any>([]);
    const [addedIds, setAddedIds] = useState<any>([]);
    const [imageVersion, setImageVersion] = useState(0);

    // Functions
    const determineImages = function () {
        var images: any[] = [];

        if (props.images && props.images.length > 0) {
            images = [...props.images];
        }

        if (images == null) {
            images = [];
        }

        // Add ids
        if (addedIds && addedIds.length > 0) {
            addedIds.forEach((addedId: any) => {
                if (images.some(i => i.assessmentImageId === addedId)) {
                    // Skip (already added)
                } else {
                    images.push({ assessmentImageId: addedId });
                }
            });
        }

        if (deletedIds && deletedIds.length > 0) {
            images = [...images].filter((i: any) => deletedIds.includes(i.assessmentImageId) === false);
        }

        return images;

    }

    // Event handlers
    const onAddedHandler = function (id: any) {
        var newValue = [...addedIds];
        newValue.push(id);
        setAddedIds(newValue);
    };

    const onRotateClickHandler = function (id: any, right: boolean) {

        rotateItemApi(globalState.apiBaseUrl, id, right)
            .then(() => {
                setImageVersion(imageVersion + 1);
            })
            .catch((ex: any) => {
                alert(`Error: ${ex}`);
            })
    };

    const onDeleteClickHandler = function (id: any) {

        if (!window.confirm(`Möchten Sie das Bild wirklich löschen?`)) {
            return;
        }

        deleteItemApi(globalState.apiBaseUrl, id)
            .then(() => {
                var newValue = [...deletedIds];
                newValue.push(id);
                setDeletedIds(newValue);
            })
            .catch((ex: any) => {
                alert(`Error: ${ex}`);
            })
    };

    // Web api calls
    const rotateItemApi = async function (apiBaseUrl: string | undefined, id: string, right: boolean) {
        const options = { method: "PUT", payload: right, };
        return request(`${props.imageBaseUrl}/${id}`, globalState.authToken, options);
    }
    const deleteItemApi = async function (apiBaseUrl: string | undefined, id: string) {
        const options = { method: "DELETE" };
        return request(`${props.imageBaseUrl}/${id}`, globalState.authToken, options);
    }

    const images = determineImages();

    return <div className={`lp21-assessmentimagemanager ${!props.isSmall ? "" : "lp21-small"}`}>
        {images && images.length > 0 &&
            <div className="row">
                {images.map((image: any) => {
                    return <div key={image.assessmentImageId} className={!props.isSmall ? "col-12" : "col-4"}>
                        <div className="card card-custom overlay overflow-hidden">
                            <div className="card-body p-0">
                                <div className="overlay-wrapper ratio ratio-4x3">
                                    <img src={`${props.imageBaseUrl}/${image.assessmentImageId}?v=${imageVersion}`} />
                                </div>
                                <div className="overlay-layer bg-dark bg-opacity-10 justify-content-end align-items-start">
                                    {/* <a href="#" title="Zoom" className="btn btn-white"><i className="bi bi-zoom-in fs-2"></i></a> */}
                                    {!props.isReadOnly && <a onClick={(e: any) => onRotateClickHandler(image.assessmentImageId, false)} title="nach links drehen" className="btn btn-white"><i className="bi bi-arrow-counterclockwise fs-2 text-primary"></i></a>}
                                    {!props.isReadOnly && <a onClick={(e: any) => onRotateClickHandler(image.assessmentImageId, true)} title="nach rechts drehen" className="btn btn-white"><i className="bi bi-arrow-clockwise fs-2 text-primary"></i></a>}
                                    {!props.isReadOnly && <a onClick={(e: any) => onDeleteClickHandler(image.assessmentImageId)} title="Bild löschen" className="btn btn-white"><i className="bi bi-x-lg fs-2 text-danger"></i></a>}
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        }
        {!props.isReadOnly && <div className="row"><div className="col-12 py-5"><FileUpload postUrl={props.imagePostUrl} id={props.rowId} onUploaded={onAddedHandler} isSmall={props.isSmall ?? false} /></div></div>}

    </div >;
}

AssessmentImageManager.propTypes = {
    rowId: PropTypes.string.isRequired,
    imagePostUrl: PropTypes.string.isRequired,
    imageBaseUrl: PropTypes.string.isRequired,
    images: PropTypes.array,

    isReadOnly: PropTypes.bool.isRequired,
    isSmall: PropTypes.bool,
};

export default AssessmentImageManager;