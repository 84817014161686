import PropTypes, { InferProps } from "prop-types";
import React from "react";
import UnboundFormControlWithLabel from "./UnboundControlWithLabel";
import "./Filter.css";

export function Filter(props: InferProps<typeof Filter.propTypes>) {

    // Event handlers
    const onFilterHandler = (value: string) => {
        if (props.onFilter) {
            props.onFilter(value);
        }
    };

    const onShowDeletedToggleHandler = (checked: boolean) => {
        if (props.onShowDeletedToggle) {
            props.onShowDeletedToggle(checked);
        }
    };

    return <div className="lp21-filter">
        <UnboundFormControlWithLabel controlId="txtSearch" controlType="search" label="Suche" useFloatingLabel={true} onChange={onFilterHandler} />
        {!props.showDeletedToggleHidden && <UnboundFormControlWithLabel controlId="chkShowDeletedToggle" controlType="checkbox" useFloatingLabel={true} label={props.showDeletedToggleLabel ?? "gelöschte anzeigen"} onChange={(value: any) => onShowDeletedToggleHandler(value === "on")} />}
    </div>;
}

Filter.propTypes = {
    showDeletedToggleHidden: PropTypes.bool,
    showDeletedToggleLabel: PropTypes.string,

    // Events
    onFilter: PropTypes.func,
    onShowDeletedToggle: PropTypes.func,
};

export default Filter;