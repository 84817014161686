import React from "react";
import "./LoadingIndicator.css";
import PropTypes, { InferProps } from "prop-types";

export function LoadingIndicator(props: InferProps<typeof LoadingIndicator.propTypes>) {

    const label = props.label ?? "Laden...";

    return <div className="lp21-loadingindicator d-flex align-items-center rounded py-5 px-5 bg-light-primary">
        <div className="spinner-border" role="status">
            <span className="visually-hidden">{label}</span>
        </div>
        <div className="text-gray-700 fw-bold fs-6">{label}</div>
    </div>;
}

LoadingIndicator.propTypes = {
    label: PropTypes.string,
};

export default LoadingIndicator;

