/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { register } from "../redux/AuthCRUD";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  familyAddress: "",
  familyPhone: "",
  remarks: "",
  changepassword: "",
  targetGroup: "Familie",
  teachers: "",
  children: "",
  acceptPrice: false,
  acceptTerms: false,
};

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, "Mindestens 3 Zeichen")
    .max(50, "Höchstens 50 Zeichen")
    .required("Vorname ist erforderlich"),
  email: Yup.string()
    .email("Ungültige E-Mail-Adresse")
    .min(3, "Mindestens 3 Zeichen")
    .max(50, "Höchstens 50 Zeichen")
    .required("E-Mail ist erforderlich"),
  lastname: Yup.string()
    .min(3, "Mindestens 3 Zeichen")
    .max(50, "Höchstens 50 Zeichen")
    .required("Nachname ist erforderlich"),
  familyAddress: Yup.string()
    .required("Adresse ist erforderlich"),
  familyPhone: Yup.string()
    .required("Telefon ist erforderlich"),
  password: Yup.string()
    .min(6, "Mindestens 6 Zeichen")
    .max(50, "Höchstens 50 Zeichen")
    .required("Passwort ist erforderlich"),
  changepassword: Yup.string()
    .required("Passwortbestätigung ist erforderlich")
    // .when("password", {
    //   is: (val: string) => (val && val.length > 0 ? true : false),
    //   then: Yup.string().
    .oneOf(
        [Yup.ref("password")],
        "Passwort und die Passwortbestätigung stimmen nicht überein"
    ),
    //}),
  targetGroup: Yup.string()
    .required("Zielgruppe ist erforderlich"),
  acceptPrice: Yup.bool().required("Du musst den Konditionen zustimmen"),
  acceptTerms: Yup.bool().required("Du musst den AGBs zustimmen"),
});

export function Registration() {

  // Hooks
  const navigate = useNavigate();

  // Global State
  const { globalState, setGlobalState } = useGlobalState();

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        register(globalState.apiBaseUrl ?? "",
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          values.familyAddress,
          values.familyPhone,
          values.targetGroup,
          values.teachers,
          values.children,
          values.remarks,
        )
          .then(() => {
            setLoading(false);
            navigate("/auth/login");
          })
          .catch((ex: any) => {
            setLoading(false);
            setSubmitting(false);
            if (ex && ex.response && ex.response.data && ex.response.data.message) {
              setStatus("Die Registration konnte nicht durchgeführt werden: " + ex.response.data.message);
            }
            else {
              setStatus("Die Registration konnte nicht durchgeführt werden:" + ex.name);
            }
          });
      }, 1000);
    },
  });

  var licenseCost = "";
  var isFamilyLicense = true;
  switch (formik.values.targetGroup) {
    case "Lerngruppe":
    case "Lehrperson":
    case "Schule":
      licenseCost = "Jahreslizenz 100.- pro Lehrperson plus 25.- pro Kind";
      isFamilyLicense = false;
      break;

    default:
      licenseCost = "Jahreslizenz 120.- pro Familie";
      isFamilyLicense = true;
      break;
  }

  return (
    <div className="mw-650px">
      <form
        className="row form w-100"
        noValidate
        id="kt_login_signup_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="pb-5">
          <h3 className="fw-bolder text-dark display-6">Registrieren</h3>
          <p className="text-muted fw-bold fs-3">
            Gebe deine Daten ein, um dein persönliches Konto zu erstellen.</p>
          <p className="text-muted fs-5">
            Mit der Registration meldest du dich kostenlos für eine 14-tägige Testlizenz an. In den kommenden Tagen stellen wir dir per E-Mail eine Rechnung für eine Jahreslizenz ab Fr. 120.-/Jahr zu.
            Mit der Zahlung der Rechnung wird deine Testlizenz automatisch in eine Jahreslizenz umgewandelt.
          </p>
        </div>

        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="col-6 fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">
            Vorname
          </label>
          <input
            placeholder="Vorname"
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("firstname")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.firstname && formik.errors.firstname,
              },
              {
                "is-valid": formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.firstname}</div>
            </div>
          )}
        </div>

        <div className="col-6 fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">
            Nachname
          </label>
          <input
            placeholder="Nachname"
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("lastname")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.lastname && formik.errors.lastname,
              },
              {
                "is-valid": formik.touched.lastname && !formik.errors.lastname,
              }
            )}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastname}</div>
            </div>
          )}
        </div>


        <div className="col-12 fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">
            Adresse (Strasse, PLZ Ort Kanton)
          </label>
          <textarea
            placeholder="Strasse, PLZ Ort Kanton"
            autoComplete="off"
            {...formik.getFieldProps("familyAddress")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.familyAddress && formik.errors.familyAddress,
              },
              {
                "is-valid": formik.touched.familyAddress && !formik.errors.familyAddress,
              }
            )}
          />
          {formik.touched.familyAddress && formik.errors.familyAddress && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.familyAddress}</div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        <div className="col-12 fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">
            Telefon
          </label>
          <input
            placeholder="Telefon für allfällige Rückfragen"
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("familyPhone")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.familyPhone && formik.errors.familyPhone,
              },
              {
                "is-valid": formik.touched.familyPhone && !formik.errors.familyPhone,
              }
            )}
          />
          {formik.touched.familyPhone && formik.errors.familyPhone && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.familyPhone}</div>
            </div>
          )}
        </div>

        {/* begin::Form group Email */}
        <div className="col-12 fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">
            E-Mail
          </label>
          <input
            placeholder="E-Mail"
            type="email"
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className="col-6 fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">
            Passwort
          </label>
          <input
            type="password"
            placeholder="Passwort"
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className="col-6 fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">
            Passwort bestätigen
          </label>
          <input
            type="password"
            placeholder="Passwort bestätigen"
            autoComplete="off"
            {...formik.getFieldProps("changepassword")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                "is-valid":
                  formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.changepassword}</div>
            </div>
          )}
        </div>
        {/* end::Form group */}


        <div className="col-12 fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">Zielgruppe</label>
          <p>Wir möchten das LP21-Tool einsetzen für unsere:</p>
          <select size={4}
            placeholder="Zielgruppe"
            autoComplete="off"
            {...formik.getFieldProps("targetGroup")}
            className="form-control form-control-lg form-control-solid"
          >
            <option value="Familie">Familie</option>
            <option value="Lerngruppe">Lerngruppe</option>
            <option value="Lehrperson">Lehrperson</option>
            <option value="Schule">Schule</option>
          </select>
        </div>


        {!isFamilyLicense &&
          <>
            <div className="col-6 fv-row mb-5">
              <label className="form-label fs-6 fw-bolder text-dark pt-5">
                Lehrpersonen
              </label>
              <input type="number"
                placeholder="Anzahl Lehrpersonen"
                autoComplete="off"
                {...formik.getFieldProps("teachers")}
                className="form-control form-control-lg form-control-solid"
              />
            </div>


            <div className="col-6 fv-row mb-5">
              <label className="form-label fs-6 fw-bolder text-dark pt-5">
                Kinder
              </label>
              <input type="number"
                placeholder="Anzahl Kinder"
                autoComplete="off"
                {...formik.getFieldProps("children")}
                className="form-control form-control-lg form-control-solid"
              />
            </div>
          </>
        }


        <div className="col-12 fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">
            Bemerkungen
          </label>
          <textarea
            placeholder="Bemerkungen"
            autoComplete="off"
            {...formik.getFieldProps("remarks")}
            className="form-control form-control-lg form-control-solid"
          />
        </div>

        {/* begin::Form group */}
        <div className="fv-row ">
          <div className="form-check form-check-custom form-check-solid mb-5">
            <input
              className="form-check-input"
              type="checkbox"
              id="kt_login_price_agree"
              {...formik.getFieldProps("acceptPrice")}
            />
            <label
              className="form-check-label fw-bold text-gray-600"
              htmlFor="kt_login_price_agree"
            >
              Ich habe verstanden, dass ich das LP21-Tool in den nächsten 14 Tagen kostenlos testen kann.
              Wird die per E-Mail zugestellte Rechnung bezahlt, gehe ich einen Lizenzvertrag ein ({licenseCost}).
              Möchte ich das Tool nur testen, ist die Rechnung nichtig und der Zugang wird nach 14 Tagen deaktiviert.
            </label>
            {formik.touched.acceptPrice && formik.errors.acceptPrice && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.acceptPrice}</div>
              </div>
            )}
          </div>
        </div>
        <div className="fv-row mb-10">
          <div className="form-check form-check-custom form-check-solid mb-5">
            <input
              className="form-check-input"
              type="checkbox"
              id="kt_login_toc_agree"
              {...formik.getFieldProps("acceptTerms")}
            />
            <label
              className="form-check-label fw-bold text-gray-600"
              htmlFor="kt_login_toc_agree"
            >
              Hiermit bestätige ich, dass ich mit den {" "}
              <Link to="/auth/terms" className="ms-1">
                Allgemeinen Geschäftsbedingungen (AGB)
              </Link> einverstanden bin.
            </label>
            {formik.touched.acceptTerms && formik.errors.acceptTerms && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.acceptTerms}</div>
              </div>
            )}
          </div>
        </div>
        {/* end::Form group */}



        {/* begin::Form group */}
        <div className="d-flex flex-wrap pb-lg-0 pb-5">
          <button
            type="submit"
            id="kt_login_signup_form_submit_button"
            className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-4"
            disabled={
              formik.isSubmitting || !formik.isValid || !formik.values.acceptPrice || !formik.values.acceptTerms
            }
          >
            {!loading && <span className="indicator-label">Registrieren</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Bitte warten...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-light-primary fw-bolder fs-6 px-8 py-4 my-3"
            >
              Abbrechen
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </div>
  );
}
