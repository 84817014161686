import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types";
import { useGlobalState } from "../../../GlobalStateProvider";
import { request } from "../../client";
import { PageTitle } from "../../../../_start/layout/core";
import PlanReport from "./PlanReport";
import PlanReportAlternate from "./PlanReportAlternate";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import FormControlWithLabel from "../../../../components/FormControlWithLabel";

export function PlanReportViewerPage(props: InferProps<typeof PlanReportViewerPage.propTypes>) {

  // Global State
  const { globalState } = useGlobalState();

  // State
  const reportTypes = [
    { id: "standard", name: "Planung" },
    { id: "compact", name: "Kompakte Planung" },
  ];
  const defaultReportType = reportTypes[0];
  const [planReport, setPlanReport] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reportType, setReportType] = useState(defaultReportType);
  const [savedFilters, setSavedFilters] = useState([]);
  const [savedFilter, setSavedFilter] = useState<any>(null);

  // Route params
  const { id } = useParams<{ id: string }>();

  const location = useLocation();

  // Mount
  useEffect(() => {

    // Load saved filters
    fetchSavedFilters();

    fetchReport(null);
  }, []);

  document.body.classList.add("lp21-report");
  document.body.classList.add("print-content-only");
  useEffect(() => {
    return () => {
      // After component unmount we should remove this class
      document.body.classList.remove("print-content-only");
      document.body.classList.remove("lp21-report");
    };
  }, [location]);

  // Event handlers
  function onChangeReportType(reportTypeId: any) {
    const newReportType = reportTypes.find((rt: any) => { return rt.id === reportTypeId }) ?? defaultReportType;
    setReportType(newReportType);
  }
  function onSelectSavedFilter(filterId: any) {
    const selectedFilter: any = savedFilters.find((f: any) => f.id === filterId);
    setSavedFilter(selectedFilter);
    fetchReport(filterId)
  }

  // Functions
  const fetchReport = function (filterId: any) {
    setIsLoading(true);
    fetchReportApi(filterId)
      .then((result: any) => {
        setPlanReport(result);
        setIsLoading(false);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex})`);
      });
  }

  const fetchSavedFilters = function () {
    fetchSavedFiltersApi(globalState.apiBaseUrl).then((result: any) => { setSavedFilters(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
  }

  // Web api calls
  const fetchReportApi = async function (filterId: any) {
    return request(`${globalState.apiBaseUrl}/plan/${id}/report?filterId=${filterId ?? ""}`, globalState.authToken);
  }
  const fetchSavedFiltersApi = async function (apiBaseUrl: string | undefined) {
    return request(`${apiBaseUrl}/filter?familyId=${globalState.familyId}`, globalState.authToken);
  }

  return (
    <div className="card">
      <PageTitle>{`${reportType?.name}: Planung ${planReport?.name ?? ""}`}</PageTitle>

      <div className="card-body p-0">

        {isLoading && <div className="row m-3"> <LoadingIndicator label="Der Bericht wird aufbereitet..." /></div>}

        {!isLoading &&
          <div className="d-print-none row justify-content-center py-8 px-8 py-md-13 px-md-0">
            <div className="col-md-10">
              <div className="d-flex fs-7 flex-wrap">
                <button type="button" className="btn btn-primary fw-bolder fs-6 py-4 px-6 me-3 me-sm-6" onClick={() => window.print()}>
                  Drucken
                </button>
                <FormControlWithLabel noBottomMargin={true} className="col-4" controlId="lstReportType" controlType="select" label="Bericht auswählen..." useFloatingLabel={true} options={reportTypes} value={reportType?.id} isRequired={true} onChange={(value: any) => { onChangeReportType(value) }} />
                <FormControlWithLabel noBottomMargin={true} useFloatingLabel={true} className="ms-3 ms-sm-6 col-4" controlId="lstSavedFilters" controlType="select" label="Gespeicherter Filter" options={savedFilters} value={savedFilter?.id} onChange={(value: any) => onSelectSavedFilter(value)} />
              </div>
            </div>
          </div>
        }

        {!isLoading && planReport && <>
          {reportType?.id === "standard" && <PlanReport data={planReport} filterName={savedFilter?.name} />}
          {reportType?.id === "compact" && <PlanReportAlternate data={planReport} filterName={savedFilter?.name} />}
        </>}
      </div>
    </div>
  );
}

PlanReportViewerPage.propTypes = {
  isReadOnly: PropTypes.bool,
};

export default PlanReportViewerPage;