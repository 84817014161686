import React, { useEffect, useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import "./AssessmentSchemaLevelSelect.css";

export function AssessmentSchemaLevelSelect(props: InferProps<typeof AssessmentSchemaLevelSelect.propTypes>) {

  // State
  const [hoveringId, setHoveringId] = useState(null);
  const [value, setValue] = useState(props.value);

  // Mount
  useEffect(() => {

  }, [hoveringId]);

  // Event handlers
  var onMouseEnterHandler = function (id: any) {
    setHoveringId(id);
  };

  var onChangeHandler = function (id: any) {

    // No change allowed?
    if (props.isDisabled) {
      return;
    }

    setValue(id);
    props.onChange(id);
  };

  var hasBeenSelected = false;
  var hasBeenHovered = false;

  var currentLevel: any = null;

  // No selection?
  if (value === null || value === "" || value === "00000000-0000-0000-0000-000000000000") {
    hasBeenSelected = true;
  }

  return <div className="lp21-assessmentschemalevel">
    <div className="lp21-level">
      {props.levels && props.levels.map((level: any) => {

        var cssHeight = `${level.percentage * 100}%`;

        var outerClassName = "lp21-outer";
        var innerClassName = "lp21-inner";

        if (level.id === value) {
          innerClassName += " selected";
          hasBeenSelected = true;
          currentLevel = level;
        } else if (hasBeenSelected === false) {
          innerClassName += " selected-implicitly";
        }

        if (hoveringId !== null && !props.isDisabled) {
          if (level.id === hoveringId) {
            innerClassName += " hovered";
            hasBeenHovered = true;
          } else if (hasBeenHovered === false) {
            innerClassName += " hovered-implicitly";
          }
        }

        if (level.percentage === 0) {
          outerClassName += " lp21-delete";
        }

        if (props.isDisabled) {
          outerClassName += " disabled";
        }

        return <span key={level.id} className="lp21-outer react-tooltip-wrapper" data-tooltip-id="tooltip-global-light" data-tooltip-variant="light" data-tooltip-content={level.name}>
          <a key={level.id} className={outerClassName} onMouseEnter={() => onMouseEnterHandler(level.id)} onMouseLeave={() => onMouseEnterHandler(null)} onClick={() => onChangeHandler(level.id)}><span className={innerClassName} style={{ maxHeight: cssHeight }}></span></a>
        </span>
      })}
    </div>
    {props.renderLegend && <div className="lp21-legend">{currentLevel?.name}</div>}
  </div>;
};

AssessmentSchemaLevelSelect.propTypes = {
  levels: PropTypes.array.isRequired,
  value: PropTypes.any,

  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  renderLegend: PropTypes.bool,
};

export default AssessmentSchemaLevelSelect;

