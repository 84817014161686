import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import AddLinkButton from "../../../../components/AddLinkButton";
import EditAndDeleteLinkButtons from "../../../../components/EditAndDeleteLinkButtons";
import PropTypes, { InferProps } from "prop-types";

export function PlansPage(props: InferProps<typeof PlansPage.propTypes>) {

  // Hooks
  const navigate = useNavigate();

  // Global State
  const { globalState, } = useGlobalState();

  // State
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);

  // Mount
  useEffect(() => {
    fetchItems(showDeleted);
  }, []);

  // Event handlers
  const onAddClickHandler = function () {
    navigate("/app/plans/new");
  }

  const onEditClickHandler = function (id: string) {
    navigate(`/app/plans/${id}`);
  }

  const onEditDetailClickHandler = function (id: string) {
    navigate(`/app/plans/${id}/items`);
  }

  const onPrintClickHandler = function (id: string) {
    navigate(`/app/plans/${id}/report`);
  }

  const onDeleteClickHandler = function (id: string, name: string) {

    if (!window.confirm(`Möchten Sie den Plan '${name}' wirklich löschen?`)) {
      return;
    }

    deleteItemApi(globalState.apiBaseUrl, id)
      .then(() => {
        fetchItems(showDeleted);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
      })
  };

  // Functions
  const fetchItems = function (includeDeleted: boolean) {
    fetchItemsApi(globalState.apiBaseUrl, includeDeleted)
      .then((result: any) => {
        setItems(result);
        applyFilter(result, filterText);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
      });
  };

  const applyFilter = function (loadedItems: any, text: any) {
    if (!text || text.length === 0) {
      setFilteredItems(loadedItems);
      return;
    }

    const searchText = text.toLowerCase();

    const res = loadedItems.filter((i: any) => {
      if (i.lastname && i.lastname.toLowerCase().indexOf(searchText) !== -1) {
        return true;
      } else { return false; }
    });

    setFilteredItems(res);
  };

  // Web api calls
  const fetchItemsApi = async function (apiBaseUrl: string | undefined, includeDeleted: boolean) {
    return request(`${apiBaseUrl}/plan?familyId=${globalState.familyId}&includeDeleted=${includeDeleted}`, globalState.authToken);
  }
  const deleteItemApi = async function (apiBaseUrl: string | undefined, id: string) {
    const options = { method: "DELETE" };
    return request(`${apiBaseUrl}/plan/${id}`, globalState.authToken, options);
  }

  return (
    <div className="card">
      <table className="table table-rounded table-striped border gy-7 gs-7">
        <thead>
          <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
            <th className="lp21-command"><AddLinkButton isReadOnly={props.isReadOnly} onAddClick={onAddClickHandler} /></th>
            <th>Datum</th>
            <th>Art</th>
            <th>Kind</th>
            <th>Stufe</th>
            <th>Status</th>
            <th>Einträge</th>
          </tr>
        </thead>
        <tbody>
          {items && items.map((item: { id: string, date: Date, planTypeName: string, childName: string, schuljahrName: string, planStatusName: string, remarksCount: number, deleted: boolean }) => <tr key={item.id}>
            <td className="lp21-command"><EditAndDeleteLinkButtons id={item.id} name={new Date(item.date).toLocaleDateString("de-CH")} isDeleted={item.deleted} isReadOnly={props.isReadOnly} onEditClick={onEditClickHandler} onDeleteClick={onDeleteClickHandler}
              showEditDetail={true} editDetailLabel="Einträge editieren" onEditDetailClick={onEditDetailClickHandler}
              showPrint={true} printLabel="Drucken" onPrintClick={onPrintClickHandler}>
              <>
                <div className="menu-item px-3">
                  <Link className={`menu-link px-3 ${items.length <= 1 ? "disabled-link" : ""}`} to={`/app/plans/${item.id}/copy`} title="Mit dieser Funktion lassen sich die Texte, Bemerkungen und Kommentare von einer anderen Planung übernehmen.">
                    <i className="bi bi-box-arrow-in-right text-primary fs-4"></i>&nbsp;Einträge kopieren...
                  </Link>
                </div>
              </>
            </EditAndDeleteLinkButtons></td>
            <td>{new Date(item.date).toLocaleDateString("de-CH")}</td>
            <td>{item.planTypeName}</td>
            <td>{item.childName}</td>
            <td>{item.schuljahrName}</td>
            <td>{item.planStatusName}</td>
            <td>{item.remarksCount}</td>
          </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

PlansPage.propTypes = {
  isReadOnly: PropTypes.bool.isRequired
};

export default PlansPage;