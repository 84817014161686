import React, { Fragment } from "react";
import { InferProps } from "prop-types";
import { ReportingDocument } from "../../../../components/reporting/ReportingDocument";
import ReportingCoverPage from "../../../../components/reporting/ReportingCoverPage";
import ReportingPage from "../../../../components/reporting/ReportingPage";
import Lehrplan21TableBasic from "../../browser/pages/Lehrplan21TableBasic";
import AssessmentReport from "./AssessmentReport";

export function AssessmentReportAlternate(props: InferProps<typeof AssessmentReport.propTypes>) {

  function onRenderCustomColumns(item: any, level: string, rowId: string): JSX.Element | null {

    var remark: any = null;
    var images: any = null;

    if (level === "handlungsaspekt") {
      //remark = findRemark(level, rowId)?.assessmentRemarks;
    }
    else if (level === "kompetenz") {
      //remark = findRemark(level, rowId)?.assessmentRemarks;
      //images = findImages(level, rowId);
    }
    else {
      remark = item.assessmentRemarks;
    }

    // No content?
    if (!remark && !images) {
      return null;
    }

    return <>
      <td colSpan={2}>
        {remark && <div className="lp21-remark" dangerouslySetInnerHTML={{ __html: remark }}></div>}
        {images && <table className="lp21-reporting-table lp21-summary-images">
          <tbody>
            {images && renderImageRows(images)}
          </tbody>
        </table>}
      </td>
    </>
  }

  function onRenderCustomRow(item: any, level: string, rowId: string): JSX.Element {

    var remark: any = null;
    var images: any = null;

    if (level === "fachbereich") {
      //remark = findRemark(level, rowId);
      //images = findImages(level, rowId);
    }
    else if (level === "fach") {
      //remark = findRemark(level, rowId);
      //images = findImages(level, rowId);
    }
    else if (level === "kompetenzbereich") {
      //remark = findRemark(level, rowId);
      //images = findImages(level, rowId);
    }
    else if (level === "handlungsaspekt") {
      remark = findRemark(level, rowId);
      //images = findImages(level, rowId);
    }
    else if (level === "kompetenz") {
      remark = findRemark(level, rowId);
      images = findImages(level, rowId);
    } else {
      //remark = findRemark(level, rowId);
      //images = findImages(level, rowId);
    }

    return <>
      {remark?.assessmentRemarks && <tr>
        <td></td>
        <td colSpan={2}>
          {<div className="lp21-remark" dangerouslySetInnerHTML={{ __html: remark?.assessmentRemarks }}></div>}
        </td>
      </tr>}

      {images && <tr>
        <td></td>
        <td colSpan={2}>
          <table className="lp21-reporting-table lp21-summary-images">
            <tbody>
              {images && renderImageRows(images)}
            </tbody>
          </table>
        </td>
      </tr>}

    </>;
  }

  const findRemark = function (level: string, id: string): any {
    if (!props.data?.remarks || props.data?.remarks.length === 0)
      return null;

    return props.data?.remarks.find((r: any) => r.level === level && r.id === id);
  }

  const findImages = function (level: string, id: string): any {
    if (!props.data?.images || props.data?.images.length === 0)
      return null;

    return props.data?.images.filter((i: any) => i.level === level && i.referenceId === id)?.map((i: any) => i.id);
  }

  const items = props.data?.items;

  return <Fragment>
    <ReportingDocument>

      <ReportingCoverPage title={props.data?.reportTitle} subtitle={props.data?.reportSubtitle} subtitle2={props.filterName} author={props.data?.reportAuthor} date={new Date(props.data?.date).toLocaleDateString("de-CH", { day: "numeric", month: "long", year: "numeric" })}>
        <div dangerouslySetInnerHTML={{ __html: props.data?.reportFooter }}></div>
      </ReportingCoverPage>
      <ReportingPage>
        <h1>Dokumentation</h1>
        <div>
          <Lehrplan21TableBasic items={items} levelUntil="" onRenderCustomColumns={onRenderCustomColumns} onRenderCustomRow={onRenderCustomRow} skipRenderingCustomColumnForLevels={["fachbereich", "fach", "kompetenzbereich", "handlungsaspekt", "kompetenz"]} hideAufbauHeading={true} />
        </div>
      </ReportingPage>

    </ReportingDocument>

  </Fragment>;


  function renderImageRows(images: any) {
    const tableRows = [];
    for (let i = 0; i < images.length; i = i + 2) {

      const isLastImage = i === (images.length - 1);

      tableRows.push(
        <tr key={images[i] + "-" + images[i + 1] + "-" + i}>
          <td className={!isLastImage ? "lp21-col50" : "lp21-col100"} colSpan={isLastImage ? 2 : 1}>
            <div className="ratio ratio-4x3">
              <img src={`${props.imageBaseUrl}/${images[i]}`} />
            </div>
          </td>
          {images[i + 1] && <td className="lp21-col50">
            <div className="ratio ratio-4x3">
              <img src={`${props.imageBaseUrl}/${images[i + 1]}`} />
            </div>
          </td>
          }
        </tr>
      );
    }
    return tableRows;

  };


}

export default AssessmentReportAlternate;