import React from "react";
import { KTSVG } from "../../../_start/helpers";
import { MenuItem } from "../../../_start/layout/components/header/MenuItem";

export const DashboardPage: React.FC = () => {
  return (
    <>
      <div className="row g-0 g-xl-5 g-xxl-8">

        <div className="col-xl-12">
          <div className={`card card-stretch mb-5  mb-xxl-8`}>
            <div className={`card-body`}>
              <div className="d-flex align-items-center">
                <div className="symbol symbol-50px me-5">
                  <span className="symbol-label bg-light-primary">
                    <KTSVG
                      path="/media/icons/duotone/Interface/Scatter-Up.svg"
                      className="svg-icon-2x svg-icon-primary"
                    />
                  </span>
                </div>
                <div>
                  <a href="#" className="fs-4 text-gray-800 text-hover-primary fw-bolder">Erste Schritte</a>
                  <div className="fs-7 text-muted fw-bold mt-1">Bist du neu hier? Mit folgenden 3 Schritten kannst du loslegen.</div>
                </div>
              </div>
              <div className="row">
                <div className="fw-bolder text-muted col">
                  <div className="ratio ratio-4x3 mb-10">
                    <video controls preload="metadata">
                      <source src="https://help.lp21-tool.ch/videos/Grundeinstellungen.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <h4>Schritt 1: Grundeinstellungen</h4>
                  <div><MenuItem to="/app/settings/family" title="Familie" /> <small className="fw-normal">Überprüfe die Adresse, Telefonnummer?</small></div>
                  <div><MenuItem to="/app/settings/children" title="Kinder" /> <small className="fw-normal">Erfasse deiner Kinder</small></div>
                  <div><MenuItem to="/app/settings/childgradelevels" title="Schuljahre" /> <small className="fw-normal">Erfasse die Schuljahre deiner Kinder</small></div>
                </div>
                <div className="fw-bolder text-muted col">
                  <div className="ratio ratio-4x3 mb-10">
                    <video controls preload="metadata">
                      <source src="https://help.lp21-tool.ch/videos/Lernstandserfassung-Dokumentation.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <h4>Schritt 2: Lernstandserfassung / Dokumentation</h4>
                  <div><MenuItem to="/app/assessments" title="Eröffnen" /> <small className="fw-normal">Pro Kind eine Lernstandserfassung eröffnen</small></div>
                  <div><MenuItem to="/app/assessments" title="Bewerten" /> <small className="fw-normal">Bewertung gemäss Lehrplan 21 vornehmen</small></div>
                  
                  <h6 className="mt-5">Für einen PDF-Bericht</h6>
                  <div><MenuItem to="/app/assessments" title="Bericht vorbereiten" /> <small className="fw-normal">Einleitende Kapitel schreiben</small></div>
                  <div><MenuItem to="/app/assessments" title="Bericht drucken" /> <small className="fw-normal">In ein PDF drucken</small></div>
                </div>
                <div className="fw-bolder text-muted col">
                  <div className="ratio ratio-4x3 mb-10">
                    <video controls preload="metadata">
                      <source src="https://help.lp21-tool.ch/videos/Jahresplanung.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <h4>Schritt 3: Jahresplanung</h4>                  
                  <div><MenuItem to="/app/plans" title="Eröffnen" /> <small className="fw-normal">Pro Kind eine Planung eröffnen</small></div>
                  <div><MenuItem to="/app/plans" title="Planen" /> <small className="fw-normal">Kompetenzstufen basierend mit Berücksichtigung des Lernstands planen</small></div>
                  <div><MenuItem to="/app/plans" title="Bericht vorbereiten" /> <small className="fw-normal">Einleitende Kapitel schreiben</small></div>
                  <div><MenuItem to="/app/plans" title="Bericht drucken" /> <small className="fw-normal">In ein PDF drucken</small></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4">
          <div className={`card card-stretch mb-5  mb-xxl-8`}>
            <div className={`card-body`}>
              <div className="d-flex align-items-center">
                <div className="symbol symbol-50px me-5">
                  <span className="symbol-label bg-light-primary">
                    <KTSVG
                      path="/media/icons/duotone/Interface/Scatter-Up.svg"
                      className="svg-icon-2x svg-icon-primary"
                    />
                  </span>
                </div>
                <div>
                  <a href="#" className="fs-4 text-gray-800 text-hover-primary fw-bolder">Lernfortschritt</a>
                  <div className="fs-7 text-muted fw-bold mt-1">Lernstand erfassen und Planen</div>
                </div>
              </div>
              <div className="fw-bolder text-muted pt-7">
                <div><MenuItem to="/app/assessments" title="Lernstandserfassung" /> <small className="fw-normal">Lernstandsbericht erstellen</small></div>
                <div><MenuItem to="/app/plans" title="Planung" /> <small className="fw-normal">Jahres- oder Semesterplanung erstellen</small></div>
                <div><MenuItem to="/app/browse" title="Lehrplan" /> <small className="fw-normal">Lehrplan 21 durchstöbern</small></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4">
          <div className={`card card-stretch mb-5  mb-xxl-8`}>
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-50px me-5">
                  <span className="symbol-label bg-light-primary">
                    <KTSVG
                      className="svg-icon-2x svg-icon-primary"
                      path="/media/icons/duotone/Code/Settings4.svg"
                    />
                  </span>
                </div>
                <div>
                  <a href="#" className="fs-4 text-gray-800 text-hover-primary fw-bolder">Grundeinstellungen</a>
                  <div className="fs-7 text-muted fw-bold mt-1">Grundlegendes zu unserer Familie</div>
                </div>
              </div>
              <div className="fw-bolder text-muted pt-7">
                <div><MenuItem to="/app/settings/childgradelevels" title="Schuljahre" /> <small className="fw-normal">Schuljahre unserer Kinder</small></div>
                <div><MenuItem to="/app/settings/children" title="Kinder" /> <small className="fw-normal">Basisangaben zu unseren Kindern</small></div>
                <div><MenuItem to="/app/settings/family" title="Familie" /> <small className="fw-normal">Basisangaben zu unserer Familie</small></div>
                <div><MenuItem to="/app/settings/user" title="Benutzer" /> <small className="fw-normal">Zugangsdaten ändern</small></div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};
