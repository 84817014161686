import React, { Fragment } from "react";
import PropTypes, { InferProps } from "prop-types";
import "./LinkButtons.css";

export function EditAndDeleteLinkButtons(props: InferProps<typeof EditAndDeleteLinkButtons.propTypes>) {

    const isReadOnly = props.isReadOnly ?? false;

    return <Fragment>
        {!isReadOnly &&
            <Fragment>
                {!props.isDeleted &&
                    <Fragment>
                        <button className="lp21-button" title="Editieren" onClick={() => props.onEditClick(props.id)}><i className="bi bi-pencil text-primary fs-1"></i></button>
                        {props.showEditDetail &&
                            <button className="lp21-button lp21-editdetail" title={props.editDetailLabel ?? ""} onClick={() => props.onEditDetailClick && props.onEditDetailClick(props.id)}><i className="bi bi-list-task text-primary fs-1"></i></button>
                        }
                        {props.showPrint &&
                            <button className="lp21-button lp21-print" title={props.printLabel ?? ""} onClick={() => props.onPrintClick && props.onPrintClick(props.id)}><i className="bi bi-printer text-primary fs-1"></i></button>
                        }

                        {props.children &&
                            <Fragment>
                                <button type="button" className="lp21-button lp21-more"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-start" title="Weitere Funktionen">
                                    <i className="bi bi-three-dots text-primary fs-1"></i>
                                </button>
                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                                    data-kt-menu="true">
                                    <>
                                        {props.children}
                                    </>
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3" onClick={() => props.onDeleteClick(props.id, props.name)}>
                                            <i className="bi bi-x-lg text-primary fs-4"></i>&nbsp;Löschen...
                                        </a>
                                    </div>
                                </div>

                            </Fragment>
                        }

                        {!props.children && <button className="lp21-button lp21-delete" title="Löschen" onClick={() => props.onDeleteClick(props.id, props.name)}><i className="bi bi-x-lg text-primary fs-1"></i></button>
                        }
                    </Fragment>
                }
                {props.isDeleted &&
                    <button className="lp21-button" title="Wiederherstellen" onClick={() => props.onEditClick(props.id)}><i className="bi bi-arrow-90deg-left text-primary fs-1"></i></button>
                }
            </Fragment>
        }
        {
            isReadOnly &&
            <Fragment>
                <button className="lp21-button" title="Anzeigen" onClick={() => props.onEditClick(props.id)}><i className="bi bi-search text-primary fs-1"></i></button>
                {props.showEditDetail &&
                    <button className="lp21-button lp21-editdetail" title={props.editDetailLabel ?? ""} onClick={() => props.onEditDetailClick && props.onEditDetailClick(props.id)}><i className="bi bi-list-task text-primary fs-1"></i></button>
                }
            </Fragment>
        }
    </Fragment>
}

EditAndDeleteLinkButtons.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isDeleted: PropTypes.bool.isRequired,
    isReadOnly: PropTypes.bool,

    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,

    showEditDetail: PropTypes.bool,
    onEditDetailClick: PropTypes.func,
    editDetailLabel: PropTypes.string,

    showPrint: PropTypes.bool,
    onPrintClick: PropTypes.func,
    printLabel: PropTypes.string,

    children: PropTypes.node,
};

export default EditAndDeleteLinkButtons;