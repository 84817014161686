/* eslint-disable jsx-a11y/anchor-is-valid */
import { requestAnonymous } from "../../client";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { determineApiBaseUrl } from "../../helper";

export function ChangeEmail() {

  // Global State
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  // Route params
  let [searchParams, setSearchParams] = useSearchParams()
  const email = searchParams.get("email")
  const newEmail = searchParams.get("newemail")
  const token = searchParams.get("token")

  // Mount
  useEffect(() => {

    setLoading(true);

    const apiBaseUrl = determineApiBaseUrl();
    changeEmailApi(apiBaseUrl)
      .then((result: any) => {
        setSuccess(result.success);
        setInfoMessage(result.infoMessage);
        setLoading(false);
      })
      .catch((ex: any) => {
        setSuccess(false);
        setInfoMessage(ex.toString());
        setLoading(false);
      });
  }, []);

  // Web api calls
  const changeEmailApi = async function (apiBaseUrl: string | undefined) {
    const options = { method: "GET", };
    return requestAnonymous(`${apiBaseUrl}/auth/changeemail?token=${token ?? ""}&email=${email ?? ""}&newemail=${newEmail ?? ""}`, options);
  }

  return (
    <div className="mw-550px">

      {/* begin::Title */}
      <div className="pb-lg-15">
        <h3 className="fw-bolder text-dark display-6 text-center">Ändern der E-Mail-Adresse</h3>

        {loading &&
          <LoadingIndicator label="E-Mail wird geändert..." />
        }

        {!loading && success &&
          <>
            <div className="mb-lg-15 alert alert-success">
              <div className="alert-text ">
                {infoMessage} Weiter <Link to="/auth/login">zum Login</Link>.
              </div>
            </div>
          </>
        }

        {!loading && !success &&
          <>
            <p>Bei der Änderung der E-Mail-Adresse ist ein Fehler aufgetreten:</p>
            <div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger mb-5">
              <div className="fw-bold text-gray-700 fs-6">{infoMessage}</div>
            </div>
            <p>Bitte kontaktiere den Support: <a href="https://lp21-tool.ch/kontakt/">https://lp21-tool.ch/kontakt/</a></p>
          </>
        }


      </div>
      {/* begin::Title */}
    </div>
  );
}