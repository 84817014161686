import React, { createContext, useState, useContext, Dispatch, SetStateAction } from "react";
import { GlobalStateInterface } from "./GlobalStateInterface";

const GlobalStateContext = createContext({
    globalState: {} as Partial<GlobalStateInterface>,
    setGlobalState: {} as Dispatch<SetStateAction<Partial<GlobalStateInterface>>>,
});

interface GlobalStateProviderInterface {
    children: React.ReactNode;
    value?: Partial<GlobalStateInterface>;
}

const GlobalStateProvider = ({
    children,
    value = {} as GlobalStateInterface,
}: GlobalStateProviderInterface) => {
    const [globalState, setGlobalState] = useState(value);
    return (
        <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
            {children}
        </GlobalStateContext.Provider>
    );
};

const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error("useGlobalState must be used within a GlobalStateContext");
    }
    return context;
};

const Debug = () => {
    const { globalState } = useGlobalState();
    return <pre>{JSON.stringify(globalState || {}, null, "\t")}</pre>;
};

export { GlobalStateProvider, useGlobalState, Debug };