import React from "react";
import "./LoadingIndicator.css";

export function NoItems() {

    return <div className="lp21-loadingindicator d-flex align-items-center rounded py-5 px-5 bg-light-primary">
        <div className="text-gray-700 fw-bold fs-6">Mit den aktuellen Filtereinstellungen wurden keine Einträge gefunden.</div>
    </div>;
}

export default NoItems;

