import React, { useEffect } from "react";
import PropTypes, { InferProps } from "prop-types";

export function PlanReportItemCustomRow(props: InferProps<typeof PlanReportItemCustomRow.propTypes>) {

  // Mount
  useEffect(() => {

  }, []);

  var className = `lp21-customRow-${props.level}`;

  if (props.remark?.planRemarks &&
    (props.level === "fachbereich"
      || props.level === "fach"
      || props.level === "kompetenzbereich"
      || props.level === "handlungsaspekt"
      || props.level === "kompetenz")) {
    return <tr className={className}>
      <td colSpan={3}></td>
      <td colSpan={2}>{props.remark?.planRemarks && <div dangerouslySetInnerHTML={{ __html: props.remark?.planRemarks }}></div>}</td>
          
    </tr>;
  }

  else {
    return <></>;
  }
};

PlanReportItemCustomRow.propTypes = {
  rowId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  item: PropTypes.any,
  remark: PropTypes.any,
};

export default PlanReportItemCustomRow;