import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../../GlobalStateProvider";
import PropTypes, { InferProps } from "prop-types";
import FormControlWithLabel from "../../../../components/FormControlWithLabel";
import { FilterModel } from "./FilterModel";
import "./Lehrplan21FilterPane.css";
import { ScrollTopComponent } from "../../../../_start/assets/ts/components";

export function Lehrplan21FilterPane(props: InferProps<typeof Lehrplan21FilterPane.propTypes>) {

    // Global State
    const { globalState } = useGlobalState();

    // State
    const [lehrplaene, setLehrplaene] = useState([]);
    const [fachbereiche, setFachbereiche] = useState([]);
    const [faecher, setFaecher] = useState([]);
    const [zyklus, setZyklus] = useState([]);
    const [assessments, setAssessments] = useState([]);
    const [plans, setPlans] = useState([]);

    const [filterText, setFilterText] = useState("");
    const [savedFilter, setSavedFilter] = useState<any>(null);
    const [savedFilters, setSavedFilters] = useState([]);
    const [savedFilterName, setSavedFilterName] = useState("");

    const levels = [
        { id: "fach", name: "Fach" },
        { id: "kompetenzbereich", name: "Kompetenzbereich" },
        { id: "handlungsaspekt", name: "Handlungsaspekt" },
        { id: "kompetenz", name: "Kompetenz" },
        { id: "kompetenzstufe", name: "Kompetenzstufe" },
    ];

    // Mount
    useEffect(() => {
        var lehrplanId = props.filter.lehrplanId;
        if (!lehrplanId) {
            lehrplanId = props.fixedLehrplanId;
            props.filter.lehrplanId = lehrplanId;
        }

        // Filter text (resolve typing delay)
        setFilterText(props.filter?.text ?? "");

        // Load saved filters
        fetchSavedFilters();

        // Lookups
        fetchLookups(lehrplanId, props.filter.fachbereichId);
    }, []);

    useEffect(() => {
        var lehrplanId = props.filter.lehrplanId;
        if (!lehrplanId) {
            lehrplanId = props.fixedLehrplanId;
            props.filter.lehrplanId = props.fixedLehrplanId;
        }

        // Lookups
        fetchLookups(lehrplanId, props.filter.fachbereichId);

    }, [props.fixedLehrplanId]);

    // Event handlers
    const onFilterHandler = function (
        lehrplanId: any,
        fachbereichId: any,
        fachId: any,
        text: any,
        istGrundanspruch: any,
        zyklusCodes: any,
        levelUntil: any,

        assessmentId: any,
        assessmentSchemaLevelUntil: any,
        planId: any,
        isPlanned: any,
        hasRemarks: any) {


        var hasChanges = false;
        var reloadAssessmentSchemaLevels = false;

        if (lehrplanId !== null) {
            hasChanges = true;
            props.filter.lehrplanId = lehrplanId;
            props.filter.fachbereichId = null;
            props.filter.fachId = null;
            fetchLookups(lehrplanId, null);
        }

        if (fachbereichId !== null) {
            hasChanges = true;
            props.filter.fachbereichId = fachbereichId;
            props.filter.fachId = null;
            fetchLookups(props.filter.lehrplanId, fachbereichId);
        }

        if (fachId !== null) {
            hasChanges = true;
            props.filter.fachId = fachId;
        }

        if (text !== null) {
            hasChanges = true;
            if (props.filter.text !== text) {
                props.filter.text = text;
                setFilterText(text);
            }
        }

        if (istGrundanspruch !== null) {
            hasChanges = true;
            props.filter.istGrundanspruch = istGrundanspruch;
        }

        if (zyklusCodes !== null) {

            if (zyklusCodes === ",") {
                zyklusCodes = "";
            }

            hasChanges = true;
            props.filter.zyklusCodes = zyklusCodes;
        }

        if (levelUntil !== null) {
            hasChanges = true;
            props.filter.levelUntil = levelUntil;
        }

        if (assessmentId) {
            hasChanges = true;
            reloadAssessmentSchemaLevels = true
            props.filter.assessmentId = assessmentId;
        }

        if (assessmentSchemaLevelUntil !== null) {
            hasChanges = true;
            props.filter.assessmentSchemaLevelUntil = assessmentSchemaLevelUntil;
        }

        if (planId) {
            hasChanges = true;
            props.filter.planId = planId;
        }

        if (isPlanned !== null) {
            hasChanges = true;
            props.filter.isPlanned = isPlanned;
        }

        if (hasRemarks !== null) {
            hasChanges = true;
            props.filter.hasRemarks = hasRemarks;
        }

        if (hasChanges) {
            // Auto save filter
            saveFilter(null, `Zuletzt verwendet: ${props.filterAutoSaveSuffix}`, {
                ...props.filter
            }, /* isAutoSave */ true);

            // Let reload items
            props.onFilterChanged(reloadAssessmentSchemaLevels);

            // Scroll to top
            scrollTop();
        }
    };

    const onSelectSavedFilter = function (filterId: any) {

        const selectedFilter: any = savedFilters.find((f: any) => f.id === filterId);

        if (selectedFilter) {
            setSavedFilter(selectedFilter);
            const filterDef: FilterModel = JSON.parse(selectedFilter.json);

            onFilterHandler(
                filterDef.lehrplanId,
                filterDef.fachbereichId,
                filterDef.fachId,
                filterDef.text,
                filterDef.istGrundanspruch,
                filterDef.zyklusCodes,
                filterDef.levelUntil,
                filterDef.assessmentId,
                filterDef.assessmentSchemaLevelUntil,
                filterDef.planId,
                filterDef.isPlanned,
                filterDef.hasRemarks
            );

            if (!selectedFilter.isAutoSave) {
                setSavedFilterName(selectedFilter.name ?? "");
            } else {
                setSavedFilterName("");
            }
        } else {
            setSavedFilter(null);
            setSavedFilterName("");
        }
    }

    const onSaveFilterNameChanged = function (value: any) {
        setSavedFilterName(value);
    }

    const onSaveFilter = function () {

        var filterId = null;
        // Is update?
        if (savedFilterName === savedFilter?.name && !savedFilter?.isGlobal) {
            filterId = savedFilter.id;
        }
        saveFilter(filterId, savedFilterName, props.filter, false);
    }

    const onDeleteFilter = function () {
        deleteFilter(savedFilter.id);
    }


    // Functions
    const fetchLookups = function (lehrplanId: any, fachbereichId: any) {
        fetchLehrplaeneApi(globalState.apiBaseUrl).then((result: any) => { setLehrplaene(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
        fetchZyklusApi(globalState.apiBaseUrl).then((result: any) => {

            var items = result.map((item: any) => {
                return {
                    id: item.codes,
                    name: item.name,
                };
            });

            var options: any = [...items];

            setZyklus(options);

        }).catch((ex: any) => { alert(`Error: ${ex})`) });

        if (lehrplanId) {
            fetchFachbereicheApi(globalState.apiBaseUrl, lehrplanId).then((result: any) => { setFachbereiche(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
        } else {
            setFachbereiche([]);
            setFaecher([]);
            props.onClearItems();
        }

        if (fachbereichId) {
            fetchFaecherApi(globalState.apiBaseUrl, fachbereichId).then((result: any) => { setFaecher(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
        } else {
            setFaecher([]);
            props.onClearItems();
        }

        // Lookups for assessment filter
        if (props.fixedAssessmentId) {
            fetchPlansApi(globalState.apiBaseUrl).then((res: any) => { setPlans(res) }).catch((ex: any) => { alert(`Error: ${ex})`) });
        }

        // Lookups for plan filter
        if (props.fixedPlanId) {
            fetchAssessmentsApi(globalState.apiBaseUrl).then((res: any) => { setAssessments(res) }).catch((ex: any) => { alert(`Error: ${ex})`) });
        }
    }

    const fetchSavedFilters = function () {
        fetchSavedFiltersApi(globalState.apiBaseUrl).then((result: any) => { setSavedFilters(result) }).catch((ex: any) => { alert(`Error: ${ex})`) });
    }

    const saveFilter = function (filterIdToUpdate: any, name: string, filterToSave: any, isAutoSave: boolean) {

        // Ensure attributes
        if (!filterToSave.id) {
            filterToSave.id = "";
        }

        const savingFilter = {
            id: filterIdToUpdate ?? filterToSave.id,
            name,
            json: JSON.stringify(filterToSave),
            isAutoSave: isAutoSave
        };

        saveFilterApi(savingFilter)
            .then((result: any) => {
                if (!isAutoSave) {
                    savingFilter.id = result;
                    setSavedFilter(savingFilter);
                }
                fetchSavedFilters();
            })
            .catch((ex: any) => {
                alert(`Error: ${ex})`)
            });;
    }

    const deleteFilter = function (id: string) {
        deleteFilterApi(id)
            .then(() => {
                setSavedFilter(null);
                setSavedFilterName("");
                fetchSavedFilters();
            })
            .catch((ex: any) => {
                alert(`Error: ${ex})`)
            });;
    }

    const scrollTop = () => {
        ScrollTopComponent.goTop();
    };

    // Web api calls
    const fetchLehrplaeneApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/lehrplan21/lehrplan`, globalState.authToken);
    }
    const fetchZyklusApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/lehrplan21/zyklus`, globalState.authToken);
    }
    const fetchFachbereicheApi = async function (apiBaseUrl: string | undefined, lehrplanId: any) {
        return request(`${apiBaseUrl}/lehrplan21/fachbereich?lehrplanId=${lehrplanId}`, globalState.authToken);
    }
    const fetchFaecherApi = async function (apiBaseUrl: string | undefined, fachbereichId: any) {
        return request(`${apiBaseUrl}/lehrplan21/fach?fachbereichId=${fachbereichId}`, globalState.authToken);
    }
    const fetchAssessmentsApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/assessment?familyId=${globalState.familyId}`, globalState.authToken);
    }
    const fetchPlansApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/plan?familyId=${globalState.familyId}`, globalState.authToken);
    }
    const fetchSavedFiltersApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/filter?familyId=${globalState.familyId}`, globalState.authToken);
    }
    const saveFilterApi = async function (data: any) {
        // Create or Update
        const options = { method: "PUT", payload: data, };
        return request(`${globalState.apiBaseUrl}/filter`, globalState.authToken, options);
    }
    const deleteFilterApi = async function (filterId: any) {
        // Delete
        const options = { method: "DELETE", };
        return request(`${globalState.apiBaseUrl}/filter/${filterId}`, globalState.authToken, options);
    }

    return <div id="lp21_filter_toggle" className="card card-custom shadow">
        <div id="lp21_filter_header" className="card-header" data-bs-toggle="collapse" data-bs-target="#lp21_filter" aria-expanded="true" aria-controls="lp21_filter">
            <h3 className="card-title"><span>Filter</span>
                {props.isLoading && <span className="spinner-border spinner-border-sm" role="status"></span>}
            </h3>
            <div className="card-toolbar">
                <i className="bi bi-funnel fs-2"></i>
                <i className="bi bi-chevron-double-right"></i>
            </div>
        </div>
        <div id="lp21_filter" className="card-body collapse show" >
            <div className="card-scroll">
                <div id="lp21_filter_content">

                    {/* Text search */}
                    <div className="row">
                        <h4>Textsuche</h4>
                        <FormControlWithLabel useFloatingLabel={false} className="col-12" inputClassName="form-control-sm" controlId="txtFilter" controlType="search" value={filterText} isRequired={false} onChange={(value: any) => onFilterHandler(null, null, null, value, null, null, null, null, null, null, null, null)} />
                    </div>

                    {/* Search in the Lehrplan 21 */}
                    <div className="row">
                        <h4>Suche im Lehrplan</h4>
                        <FormControlWithLabel useFloatingLabel={true} className="col-12" inputClassName="form-select-sm" controlId="lstLehrplan" controlType="select" label="Lehrplan" options={lehrplaene} value={props.fixedLehrplanId ?? props.filter.lehrplanId} isRequired={true} isDisabled={(props.fixedLehrplanId ?? "") !== ""} onChange={(value: any) => onFilterHandler(value, null, null, null, null, null, null, null, null, null, null, null)} />
                        {fachbereiche && fachbereiche.length > 0 && <FormControlWithLabel useFloatingLabel={true} className="col-12" inputClassName="form-select-sm" controlId="lstFachbereich" controlType="select" label="Fachbereich" options={fachbereiche} value={props.filter.fachbereichId} isRequired={false} onChange={(value: any) => onFilterHandler(null, value, null, null, null, null, null, null, null, null, null, null)} />}
                        {faecher && faecher.length > 0 && <FormControlWithLabel useFloatingLabel={true} className="col-12" inputClassName="form-select-sm" controlId="lstFach" controlType="select" label="Fach" options={faecher} value={props.filter.fachId} isRequired={false} onChange={(value: any) => onFilterHandler(null, null, value, null, null, null, null, null, null, null, null, null)} />}
                        <FormControlWithLabel useFloatingLabel={false} className="col-12" controlId="lstZyklus" controlType="checkboxlist" label="" options={zyklus} value={props.filter.zyklusCodes} onChange={(value: any) => onFilterHandler(null, null, null, null, null, value, null, null, null, null, null, null)} />
                        {<FormControlWithLabel useFloatingLabel={true} className="col-12" controlId="chkGrundanspruch" controlType="checkbox" label="nur Grundanspruch anzeigen" value={props.filter.istGrundanspruch} onChange={(value: any) => onFilterHandler(null, null, null, null, value === "on", null, null, null, null, null, null, null)} />}
                        {/* <FormControlWithLabel className="col-12" controlId="lstGrundanspruch" controlType="radio" label="Kompetenzstufen" options={grundanspruch} value={props.filter.istGrundanspruch} onChange={(value: any) => onFilterHandler(null, null, null, null, value, null, null, null, null, null, null, null)} /> */}
                        <FormControlWithLabel useFloatingLabel={false} className="col-12" inputClassName="form-select-sm" controlId="lstLevelUntil" controlType="select" label="Anzeigen bis" options={levels} value={props.filter.levelUntil} onChange={(value: any) => onFilterHandler(null, null, null, null, null, null, value, null, null, null, null, null)} />
                    </div>

                    {/* Assessment filter */}
                    {props.fixedAssessmentId && <div className="row">
                        <h4>Suche nach Lernstand, Planung</h4>
                        <FormControlWithLabel useFloatingLabel={true} className="col-12" inputClassName="form-select-sm" controlId="lstAssessmentSchemaLevelUntil" controlType="select" label="Lernstand" options={props.assessmentSchemaLevels} value={props.filter.assessmentSchemaLevelUntil} onChange={(value: any) => onFilterHandler(null, null, null, null, null, null, null, null, value, null, null, null)} />
                        <FormControlWithLabel useFloatingLabel={true} className="col-12" controlId="chkHasRemarks" controlType="checkbox" label="nur mit Bemerkungen anzeigen" value={props.filter.hasRemarks} onChange={(value: any) => onFilterHandler(null, null, null, null, null, null, null, null, null, null, null, value === "on")} />
                        {plans && plans.length > 0 && <FormControlWithLabel useFloatingLabel={true} className="col-12" inputClassName="form-select-sm" controlId="lstPlan" controlType="select" label="zugehöriger Plan" options={plans} value={props.fixedPlanId ?? props.filter.planId} isRequired={false} isDisabled={props.fixedPlanId ?? "" !== ""} onChange={(value: any) => onFilterHandler(null, null, null, null, null, null, null, null, null, value, null, null)} />}
                        {(props.fixedPlanId || props.filter.planId) && <FormControlWithLabel useFloatingLabel={true} className="col-12" controlId="chkIsPlanned" controlType="checkbox" label="nur geplante anzeigen" value={props.filter.isPlanned} onChange={(value: any) => onFilterHandler(null, null, null, null, null, null, null, null, null, null, value === "on", null)} />}
                    </div>
                    }

                    {/* Plan filter */}
                    {props.fixedPlanId && <div className="row">
                        <h4>Suche nach Lernstand, Planung</h4>
                        {assessments && assessments.length > 0 && <FormControlWithLabel useFloatingLabel={true} className="col-12" inputClassName="form-select-sm" controlId="lstAssessment" controlType="select" label="zugehörige Lernstandserfassung" options={assessments} value={props.fixedAssessmentId ?? props.filter.assessmentId} isRequired={false} isDisabled={props.fixedAssessmentId ?? "" !== ""} onChange={(value: any) => onFilterHandler(null, null, null, null, null, null, null, value, null, null, null, null)} />}
                        {props.filter.assessmentId && <FormControlWithLabel useFloatingLabel={true} className="col-12" inputClassName="form-select-sm" controlId="lstAssessmentSchemaLevelUntil" controlType="select" label="Lernstand" options={props.assessmentSchemaLevels} value={props.filter.assessmentSchemaLevelUntil} onChange={(value: any) => onFilterHandler(null, null, null, null, null, null, null, null, value, null, null, null)} />}
                        {(props.fixedPlanId || props.filter.planId) && <FormControlWithLabel useFloatingLabel={true} className="col-12" controlId="chkIsPlanned" controlType="checkbox" label="nur geplante anzeigen" value={props.filter.isPlanned} onChange={(value: any) => onFilterHandler(null, null, null, null, null, null, null, null, null, null, value === "on", null)} />}
                    </div>
                    }

                    {/* Saved filters */}
                    <div className="row lp21-savedfilters">
                        <h4 className="col-12">Gespeicherte Filter</h4>

                        <FormControlWithLabel useFloatingLabel={false} className="col-10" inputClassName="form-select-sm" controlId="lstSavedFilters" controlType="select" label="" options={savedFilters} value={savedFilter?.id ?? ""} onChange={(value: any) => onSelectSavedFilter(value)} />
                        <div className="col-2">
                            <button className="btn btn-link" title="Filter löschen" disabled={!savedFilter || savedFilter.isAutoSave || savedFilter.isGlobal} onClick={() => onDeleteFilter()}><i className="fa fa-trash-can fs-2 mb-2"></i></button>
                        </div>

                        <FormControlWithLabel useFloatingLabel={false} className="col-10" inputClassName="form-control-sm" controlId="txtSaveAs" controlType="text" label={(savedFilterName === savedFilter?.name) ? "Speichern" : "Speichern unter"} value={savedFilterName} isRequired={true} onChange={(value: any) => { onSaveFilterNameChanged(value) }} />
                        <div className="col-2">
                            <button className="btn btn-link" title="Filter speichern" disabled={!savedFilterName || savedFilterName === ""} onClick={() => onSaveFilter()}><i className="fa fa-floppy-disk  fs-2 mt-9"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >
};

Lehrplan21FilterPane.propTypes = {
    fixedLehrplanId: PropTypes.any,
    fixedAssessmentId: PropTypes.any,
    fixedPlanId: PropTypes.any,
    assessmentSchemaLevels: PropTypes.any,

    filter: PropTypes.instanceOf(FilterModel).isRequired,
    onFilterChanged: PropTypes.func.isRequired,
    onClearItems: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    filterAutoSaveSuffix: PropTypes.string.isRequired,
};

export default Lehrplan21FilterPane;