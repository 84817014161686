/* eslint-disable jsx-a11y/anchor-is-valid */
import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import AddLinkButton from "../../../../components/AddLinkButton";
import EditAndDeleteLinkButtons from "../../../../components/EditAndDeleteLinkButtons";
import PropTypes, { InferProps } from "prop-types";

export function ChildGradeLevelsPage(props: InferProps<typeof ChildGradeLevelsPage.propTypes>) {

  // Hooks
  const navigate = useNavigate();

  // Global State
  const { globalState, } = useGlobalState();

  // State
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);

  // Mount
  useEffect(() => {
    fetchItems(showDeleted);
  }, []);

  // Event handlers
  const onAddClickHandler = function () {
    navigate("/app/settings/childgradelevels/new");
  }

  const onEditClickHandler = function (id: string) {
    navigate(`/app/settings/childgradelevels/${id}`);
  }

  const onDeleteClickHandler = function (id: string, name: string) {

    if (!window.confirm(`Möchten Sie den Eintrag '${name}' wirklich löschen?`)) {
      return;
    }

    deleteItemApi(globalState.apiBaseUrl, id)
      .then(() => {
        fetchItems(showDeleted);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
      })
  };

  // Functions
  const fetchItems = function (includeDeleted: boolean) {
    fetchItemsApi(globalState.apiBaseUrl, globalState.familyId, includeDeleted)
      .then((result: any) => {
        setItems(result);
        applyFilter(result, filterText);
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
      });
  };

  const applyFilter = function (loadedItems: any, text: any) {
    if (!text || text.length === 0) {
      setFilteredItems(loadedItems);
      return;
    }

    const searchText = text.toLowerCase();

    const res = loadedItems.filter((i: any) => {
      if (i.childName && i.childName.toLowerCase().indexOf(searchText) !== -1) {
        return true;
      } else { return false; }
    });

    setFilteredItems(res);
  };

  // Web api calls
  const fetchItemsApi = async function (apiBaseUrl: string | undefined, familyId: string | undefined, includeDeleted: boolean) {
    return request(`${apiBaseUrl}/childgradelevel?familyid=${familyId}&includeDeleted=${includeDeleted}`, globalState.authToken);
  }
  const deleteItemApi = async function (apiBaseUrl: string | undefined, id: string) {
    const options = { method: "DELETE" };
    return request(`${apiBaseUrl}/childgradelevel/${id}`, globalState.authToken, options);
  }

  return (
    <div className="card">
      <table className="table table-rounded table-striped border gy-7 gs-7">
        <thead>
          <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
            <th className="lp21-command"><AddLinkButton isReadOnly={props.isReadOnly} onAddClick={onAddClickHandler} /></th>
            <th>Kind</th>
            <th>Stufe</th>
            <th>Lehrplan</th>
            <th>Von</th>
            <th>Bis</th>
          </tr>
        </thead>
        <tbody>
          {items && items.map((item: { id: string, childName: string, schuljahrName: string, lehrplanName: string, fromDate: Date, toDate: Date, deleted: boolean }) => <tr key={item.id}>
            <td className="lp21-command"><EditAndDeleteLinkButtons id={item.id} name={item.childName} isDeleted={item.deleted} isReadOnly={props.isReadOnly} onEditClick={onEditClickHandler} onDeleteClick={onDeleteClickHandler} /></td>
            <td>{item.childName}</td>
            <td>{item.schuljahrName}</td>
            <td>{item.lehrplanName}</td>
            <td>{new Date(item.fromDate).toLocaleDateString("de-CH", { year: 'numeric', month: 'long' })}</td>
            <td>{new Date(item.toDate).toLocaleDateString("de-CH", { year: 'numeric', month: 'long' })}</td>
          </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

ChildGradeLevelsPage.propTypes = {
    isReadOnly: PropTypes.bool.isRequired
};

export default ChildGradeLevelsPage;