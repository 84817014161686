
import { useGlobalState } from "./GlobalStateProvider";
import { GlobalStateInterface } from "./GlobalStateInterface";
import React, { Fragment, useEffect } from "react";
import { determineApiBaseUrl } from "./modules/helper";

export function GlobalStateLoader() {


    // Global state  
    const { setGlobalState } = useGlobalState();

    // Mount
    useEffect(() => {

        const config: Partial<GlobalStateInterface> = {
            apiBaseUrl: determineApiBaseUrl(),
            familyId: "00000000-0000-0000-0000-000000000000",
            userId: "00000000-0000-0000-0000-000000000000",
            defaultLehrplanId: "00000000-0000-0000-0000-000000000000",
            isAdmin: false,
            queryId: 1,
        };

        // Apply config
        setGlobalState((prev) => ({ ...prev, ...config }));

    }, []);

    return <Fragment />;
}

export default GlobalStateLoader;