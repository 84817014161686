/* eslint-disable jsx-a11y/anchor-is-valid */
import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import { isGuid } from "../../helper";
import FormControlWithLabel from "../../../../components/FormControlWithLabel";
import PropTypes, { InferProps } from "prop-types";
import SaveAndCancelOrCloseButtons from "../../../../components/SaveAndCancelOrCloseButtons";

export function ChildPage(props: InferProps<typeof ChildPage.propTypes>) {

    // Hooks
    const navigate = useNavigate();

    // Global State
    const { globalState, } = useGlobalState();

    // State
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [birthday, setBirthday] = useState(null);
    const [schoolEntryOn, setSchoolEntryOn] = useState(null);
    const [isExternal, setIsExternal] = useState(false);

    // Route params
    const { id } = useParams<{ id: string }>();

    // Mount
    useEffect(() => {

        // Lookups

        // New item
        if (!isGuid(id)) {
            return;
        }

        fetchItemApi(globalState.apiBaseUrl)
            .then((result: any) => {
                setFirstName(result.firstName);
                setLastName(result.lastName);
                setBirthday(result.birthday);
                setSchoolEntryOn(result.schoolEntryOn);
                setIsExternal(result.isExternal);
            })
            .catch((ex: any) => {
                alert(`Error: ${ex})`);
            });


    }, []);

    // Event handlers
    const onSubmitHandler = function (e: any) {
        e.preventDefault();
        saveItemApi(globalState.apiBaseUrl, {
            familyId: globalState.familyId,
            firstName: firstName,
            lastName: lastName,
            birthday: birthday,
            schoolEntryOn: schoolEntryOn,
            isExternal: isExternal,
        })
            .then(() => {
                navigate("/app/settings/children");
            })
            .catch((ex: any) => {
                if (ex && ex.response && ex.response.details) {
                    alert(`Error: ${ex})\n\n${ex.response.details}`);
                } else {
                    alert(`Error: ${ex})`);
                }
            });
    }

    const onCancelHandler = function () {
        navigate("/app/settings/children");
    }

    // Web api calls
    const fetchItemApi = async function (apiBaseUrl: string | undefined) {
        return request(`${apiBaseUrl}/child/${id}`, globalState.authToken);
    }
    const saveItemApi = async function (apiBaseUrl: string | undefined, data: any) {
        if (!isGuid(id)) {
            // Create
            const options = { method: "POST", payload: data, };
            return request(`${apiBaseUrl}/child`, globalState.authToken, options);
        }
        else {
            // Update
            const options = { method: "PUT", payload: data, };
            return request(`${apiBaseUrl}/child/${id}`, globalState.authToken, options);
        }
    }

    return (
        <form onSubmit={onSubmitHandler}>
            <div className="card p-10">
                <div className="row">
                    <FormControlWithLabel className="col-6" controlId="txtFirstName" controlType="text" label="Vorname" value={firstName} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setFirstName(value)} />
                    <FormControlWithLabel className="col-6" controlId="txtLastName" controlType="text" label="Nachname" value={lastName} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setLastName(value)} />
                </div>
                <div className="row">
                    <FormControlWithLabel className="col-6" controlId="dtBirthday" controlType="date" label="Geburtstag" value={birthday} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setBirthday(value)} />
                    <FormControlWithLabel className="col-6" controlId="dtSchoolEntryOn" controlType="month" label="Schuleintritt am" value={schoolEntryOn} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setSchoolEntryOn(value)} />
                    <FormControlWithLabel className="col-6" controlId="chkIsExternal" controlType="checkbox" useFloatingLabel={true} label="Betreuung als externe Lehrperson" value={isExternal} isRequired={true} isDisabled={props.isReadOnly} onChange={(value: any) => setIsExternal(value == "on")} />
                </div>
                <SaveAndCancelOrCloseButtons isReadOnly={props.isReadOnly} onCancelHandler={onCancelHandler} />
            </div>
        </form>
    );
};

ChildPage.propTypes = {
    isReadOnly: PropTypes.bool.isRequired
};

export default ChildPage;