import React, { Fragment } from "react";
import PropTypes, { InferProps } from "prop-types";
import { ReportingDocument } from "../../../../components/reporting/ReportingDocument";
import ReportingCoverPage from "../../../../components/reporting/ReportingCoverPage";
import ReportingPage from "../../../../components/reporting/ReportingPage";
import AssessmentSummary from "../../../../components/AssessmentSummary";

export function AssessmentReport(props: InferProps<typeof AssessmentReport.propTypes>) {

  return <Fragment>
    <ReportingDocument>

      <ReportingCoverPage title={props.data?.reportTitle} subtitle={props.data?.reportSubtitle} subtitle2={props.filterName} author={props.data?.reportAuthor} date={new Date(props.data?.date).toLocaleDateString("de-CH", { day: "numeric", month: "long", year: "numeric" })}>
        <div dangerouslySetInnerHTML={{ __html: props.data?.reportFooter }}></div>
      </ReportingCoverPage>
      <ReportingPage>
        <h1>Einleitung</h1>
        <div dangerouslySetInnerHTML={{ __html: props.data?.reportIntroduction }}></div>
      </ReportingPage>
      <ReportingPage>

        <h1><span>1</span>Rückblick</h1>
        <div dangerouslySetInnerHTML={{ __html: props.data?.reportChapter1 }}></div>

        <h1><span>2</span>Lernstandserfassung</h1>
        <div dangerouslySetInnerHTML={{ __html: props.data?.reportChapter2 }}></div>

      </ReportingPage>
      <ReportingPage>
        <h1>3 Kompetenzenüberblick</h1>
        <div>
          {props.data?.summary?.items && props.data.summary.items.map((item: any) => {
            return renderFach(item);
          })}
        </div>
      </ReportingPage>
      <ReportingPage>
        <h1><span>4</span>Weitere Unterlagen</h1>
        <div dangerouslySetInnerHTML={{ __html: props.data?.reportChapter4 }}></div>
      </ReportingPage>

    </ReportingDocument>

  </Fragment>;


  function renderImageRows(images: any) {

    const tableRows = [];
    for (let i = 0; i < images.length; i = i + 2) {

      const isLastImage = i === (images.length - 1);

      tableRows.push(
        <tr key={images[i] + "-" + images[i + 1] + "-" + i}>
          <td className={!isLastImage ? "lp21-col50" : "lp21-col100"} colSpan={isLastImage ? 2 : 1}>
            <div className="ratio ratio-4x3">
              <img src={`${props.imageBaseUrl}/${images[i]}`} />
            </div>
          </td>
          {images[i + 1] && <td className="lp21-col50">
            <div className="ratio ratio-4x3">
              <img src={`${props.imageBaseUrl}/${images[i + 1]}`} />
            </div>
          </td>
          }
        </tr>
      );
    }
    return tableRows;

  };

  function renderFach(item: any) {

    const series = [
      item.percentage_Zyklus1,
      item.percentage_Zyklus2,
      item.percentage_Zyklus3
    ];
    const labels = ['1. Zyklus', '2. Zyklus', '3. Zyklus']

    return <div key={item.id} className="lp21-summary">
      <h2>{item.code} {item.name}</h2>
      <table className="lp21-reporting-table lp21-summary-fach">
        <tbody>
          <tr>
            <td className="lp21-col50 text-center">
              <AssessmentSummary labels={labels} series={series} height={225} renderAsImage={true} renderLegend={true} />
            </td>
            <td className="lp21-col50 lp21-summary-remark">
              <div dangerouslySetInnerHTML={{ __html: item.remarks }}></div>
            </td>
          </tr>
        </tbody>
      </table>

      {item.images &&
        <table className="lp21-summary-images">
          <tbody>
            {item.images && renderImageRows(item.images)}
          </tbody>
        </table>
      }

      <div className="lp21-summary-kompetenzbereich">
        {item.items && item.items.map((innerItem: any) => {
          return renderKompetenzbereich(item.hasRemarksOrImagesOnItems, innerItem);
        })}
      </div>
    </div>;
  }

  function renderKompetenzbereich(hasRemarksOrImagesOnItems: boolean, item: any) {

    const series = [
      item.percentage_Zyklus1,
      item.percentage_Zyklus2,
      item.percentage_Zyklus3
    ];
    const labels = ['1. Zyklus', '2. Zyklus', '3. Zyklus']

    return <Fragment key={item.id}>
      {!hasRemarksOrImagesOnItems && <div className="lp21-keeptogether lp21-summary-kompetenzbereich-short">
        <h3>{item.code} {item.name}</h3>
        <table className="lp21-reporting-table">
          <tbody>
            <tr>
              <td className="text-center">
                <AssessmentSummary labels={labels} series={series} height={175} renderAsImage={true} renderLegend={true} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>}

      {hasRemarksOrImagesOnItems && <Fragment><div className="lp21-keeptogether lp21-summary-kompetenzbereich-long">
        <h3>{item.code} {item.name}</h3>
        <table className="lp21-reporting-table">
          <tbody>
            <tr>
              <td className="lp21-col50 text-center">
                <AssessmentSummary labels={labels} series={series} height={225} renderAsImage={true} renderLegend={true} />
              </td>
              <td className="lp21-summary-remark">
                <div dangerouslySetInnerHTML={{ __html: item.remarks }}></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

        {item.images &&
          <table className="lp21-reporting-table lp21-summary-images">
            <tbody>
              {item.images && renderImageRows(item.images)}
            </tbody>
          </table>
        }
      </Fragment>
      }

    </Fragment>;
  }
}

AssessmentReport.propTypes = {
  data: PropTypes.any.isRequired,
  filterName: PropTypes.string,
  imageBaseUrl: PropTypes.string.isRequired,
}

export default AssessmentReport;


