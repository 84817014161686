/* eslint-disable jsx-a11y/anchor-is-valid */
import { requestAnonymous } from "../../client";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useGlobalState } from "../../../GlobalStateProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

const initialValues = {
  password: "",
  changepassword: "",
};

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Mindestens 6 Zeichen")
    .max(50, "Höchstens 50 Zeichen")
    .required("Passwort ist erforderlich"),
  changepassword: Yup.string()
    .required("Passwortbestätigung ist erforderlich")
    .oneOf(
        [Yup.ref("password")],
        "Passwort und die Passwortbestätigung stimmen nicht überein"
    ),
});

export function ResetPassword() {

  // Global State
  const { globalState } = useGlobalState();

  // Route params
  let [searchParams, setSearchParams] = useSearchParams()
  const email = searchParams.get("email")
  const token = searchParams.get("token")

  // State
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        resetPasswordApi(globalState.apiBaseUrl ?? "", {
          email,
          token,
          newPassword: values.password },
          )
          .then(() => {
            setHasErrors(false);
            setLoading(false);
            setSubmitting(false);
          })
          .catch((ex: any) => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
            if (ex && ex.response && ex.response.data && ex.response.data.message) {
              setStatus("Das Passwort konnte nicht geändert werden: " + ex.response.data.message);
            }
            else {
              setStatus("Das Passwort konnte nicht geändert werden:" + ex.name);
            }
          });
      }, 1000);
    },
  });

  // Mount
  useEffect(() => {

  }, []);

  // Web api calls
  const resetPasswordApi = async function (apiBaseUrl: string | undefined, data: any) {
    const options = { method: "POST", payload: data, };
    return requestAnonymous(`${apiBaseUrl}/auth/resetpassword`, options);
  }

  return (
    <div className="mw-650px">

      {/* begin::Title */}
      <div className="pb-lg-15">
        <form
          className="row form w-100"
          noValidate
          id="kt_login_signup_form"
          onSubmit={formik.handleSubmit}
        >
          <div className="pb-5">
            <h3 className="fw-bolder text-dark display-6">Passwort zurücksetzen</h3>
            <p className="text-muted fw-bold fs-3">
              Gebe dein neues Passwort ein.</p>
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}<br/>Änderungslink erneut anfordern: <Link to="/auth/forgot-password">hier</Link></div>
            </div>
          )}
  
          {hasErrors === false && (
            <div className="mb-lg-15 alert alert-success">
              <div className="alert-text ">
                Das Passwort wurde erfolgreich geändert. Weiter <Link to="/auth/login">zum Login</Link>.
              </div>
            </div>
          )}
          {/* end::Title */}

          {hasErrors !== false &&
        <>
          {/* begin::Form group Email */}
          <div className="col-12 fv-row mb-5">
            <label className="form-label fs-6 fw-bolder text-dark pt-5">
              E-Mail
            </label>
            <p>{email ?? ""}</p>
            
          </div>
          {/* end::Form group */}

          {/* begin::Form group Password */}
          <div className="col-6 fv-row mb-5">
            <label className="form-label fs-6 fw-bolder text-dark pt-5">
              Neues Passwort
            </label>
            <input
              type="password"
              placeholder="Passwort"
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid": formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid": formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <div className="col-6 fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark pt-5">
            Neues Passwort bestätigen
            </label>
            <input
              type="password"
              placeholder="Passwort bestätigen"
              autoComplete="off"
              {...formik.getFieldProps("changepassword")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.changepassword && formik.errors.changepassword,
                },
                {
                  "is-valid":
                    formik.touched.changepassword && !formik.errors.changepassword,
                }
              )}
            />
            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.changepassword}</div>
              </div>
            )}
          </div>
          {/* end::Form group */}


        {/* begin::Form group */}
        <div className="d-flex flex-wrap pb-lg-0 pb-5">
          <button
            type="submit"
            id="kt_login_signup_form_submit_button"
            className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-4"
            disabled={
              formik.isSubmitting || !formik.isValid
            }
          >
            {!loading && <span className="indicator-label">Passwort ändern</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Bitte warten...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-light-primary fw-bolder fs-6 px-8 py-4 my-3"
            >
              Abbrechen
            </button>
          </Link>
        </div>
        {/* end::Form group */}
        </>}

        </form>

      </div>
      {/* begin::Title */}
    </div>
  );
}