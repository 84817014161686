export class ServerError extends Error {
    response: any;

    constructor(message: string) {
        super(message);

        if (typeof Error.captureStackTrace === "function") {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }

        this.name = "ServerError";
        this.response = {};

        return this
    }
}

export const parseError = (error: any) => error || "Something went wrong";

export const request = async (url: string, authToken: string | undefined, options: any = {}): Promise<any> => {
    return requestInner(url, authToken, options);
}

export const requestAnonymous = async (url: string, options: any = {}): Promise<any> => {
    return requestInner(url, "", options);
}

export const requestInner = async (url: string, authToken: string | undefined, options: any = {}): Promise<any> => {

    var authHeaders = {};
    if (authToken && authToken !== "") {
        authHeaders = {
            "Authorization": `Bearer ${authToken}`
        };
    }

    const config = {
        method: "GET",
        ...options
    };

    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...authHeaders,
        ...config.headers
    };

    const params: any = {
        headers,
        method: config.method,
        //credentials: "include",
    };

    if (params.method !== "GET") {
        params.body = JSON.stringify(config.payload);
    }

    return fetch(url, params)
        .then(async (response) => {
            if (response.status > 299) {
                const error = new ServerError(response.statusText);
                const contentType = response.headers.get("content-type");

                if (contentType && contentType.includes("application/json")) {
                    error.response = {
                        status: response.status,
                        data: await response.json(),
                    };
                }
                else if (contentType && contentType.includes("application/problem+json")) {
                    error.response = {
                        status: response.status,
                        data: await response.json(),
                    };

                    if (error.response.data && error.response.data.title) {
                        error.response.details = error.response.data.title;
                    } else {
                        error.response.details = "";
                    }

                    if (error.response.data && error.response.data.errors) {
                        for (const errorPropertyName in error.response.data.errors) {
                            const errorProperty = error.response.data.errors[errorPropertyName];
                            error.response.details += "\n" + errorProperty;
                        };
                    }
                } else {
                    error.response = {
                        status: response.status,
                        data: await response.text(),
                    };
                }

                throw error;
            } else {

                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    return response.json();
                }

                return response.text();
            }
        });
};