import PropTypes, { InferProps } from "prop-types";
import React, { Fragment } from "react";
import "./LinkButtons.css";

export function AddLinkButton(props: InferProps<typeof AddLinkButton.propTypes>) {

  const isReadOnly = props.isReadOnly ?? false;

  return <Fragment>
    {!isReadOnly &&
      <button className="lp21-button" title="Hinzufügen" onClick={props.onAddClick}><i className="bi bi-plus-lg text-primary fs-1"></i></button>
    }
  </Fragment>;
}

AddLinkButton.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default AddLinkButton;