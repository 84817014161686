import React, { Fragment } from "react";
import PropTypes, { InferProps } from "prop-types";
import { ReportingDocument } from "../../../../components/reporting/ReportingDocument";
import ReportingCoverPage from "../../../../components/reporting/ReportingCoverPage";
import ReportingPage from "../../../../components/reporting/ReportingPage";
import Lehrplan21Table from "../../browser/pages/Lehrplan21Table";
import PlanReportItemCustomColumn from "./PlanReportItemCustomColumn";
import "./PlanReport.css";
import PlanReportItemCustomRow from "./PlanReportItemCustomRow";

export function PlanReport(props: InferProps<typeof PlanReport.propTypes>) {

  function onRenderCustomColumns(item: any, level: string, rowId: string): JSX.Element {

    // Lookup remark
    var remark: any = findRemark(level, rowId);
    return <PlanReportItemCustomColumn assessmentSchemaLevels={props.data?.assessmentSchemaLevels} planId={props.data?.id} rowId={rowId} level={level} item={item} remark={remark} />
  }

  function onRenderCustomRow(item: any, level: string, rowId: string): JSX.Element {

    // Lookup remark
    var remark: any = findRemark(level, rowId);
    return <PlanReportItemCustomRow planId={props.data?.id} rowId={rowId} level={level} item={item} remark={remark} />
  }

  const findRemark = function (level: string, id: string): any {
    if (!props.data?.remarks || props.data?.remarks.length === 0)
      return null;

    return props.data?.remarks.find((r: any) => r.level === level && r.id === id);
  }

  return <Fragment>
    <ReportingDocument>

      <ReportingCoverPage title={props.data?.reportTitle} subtitle={props.data?.reportSubtitle} subtitle2={props.filterName} author={props.data?.reportAuthor} date={new Date(props.data?.date).toLocaleDateString("de-CH", { day: "numeric", month: "long", year: "numeric" })}>
        <div className="lp21-reporting-richtext" dangerouslySetInnerHTML={{ __html: props.data?.reportFooter }}></div>
      </ReportingCoverPage>
      <ReportingPage>
        <h1>Einleitung</h1>
        <div className="lp21-reporting-richtext" dangerouslySetInnerHTML={{ __html: props.data?.reportIntroduction }}></div>
      </ReportingPage>
      <ReportingPage>

        <div className="lp21-keeptogether">
          <h1><span>1</span>Lehrperson</h1>
          <div className="lp21-reporting-richtext" dangerouslySetInnerHTML={{ __html: props.data?.reportChapter1 }}></div>
        </div>

        <div className="lp21-keeptogether">
          <h1><span>2</span>Unterricht</h1>
          <div className="lp21-reporting-richtext" dangerouslySetInnerHTML={{ __html: props.data?.reportChapter2 }}></div>
        </div>

        <div className="lp21-keeptogether">
          <h1><span>3</span>Lernort</h1>
          <div className="lp21-reporting-richtext" dangerouslySetInnerHTML={{ __html: props.data?.reportChapter3 }}></div>
        </div>

        <div className="lp21-keeptogether">
          <h1><span>4</span>Stundenplan</h1>
          <div className="lp21-reporting-richtext" dangerouslySetInnerHTML={{ __html: props.data?.reportChapter4 }}></div>
        </div>

      </ReportingPage>
      <ReportingPage>
        <h1>5 Jahresplanung gemäss Lehrplan 21</h1>
        <div className="lp21-reporting-richtext" dangerouslySetInnerHTML={{ __html: props.data?.reportChapter5 }}></div>
        <Lehrplan21Table items={props.data?.items} levelUntil="" onRenderCustomColumns={onRenderCustomColumns} onRenderCustomRow={onRenderCustomRow} renderLinks={false} renderHeaderRow={true} skipRenderingCustomColumnForLevels={["fachbereich", "fach", "kompetenzbereich", "handlungsaspekt", "kompetenz"]} />
      </ReportingPage>

    </ReportingDocument >

  </Fragment >;
}

PlanReport.propTypes = {
  data: PropTypes.any.isRequired,
  filterName: PropTypes.string,
}

export default PlanReport;